import { UploadFile } from 'fave-ui';
import { useQuery, useMutation } from 'react-query';
import { DatasetPayloadType, TableConfigType } from '../../types/configTypes';
import http, { createFetchAPI } from '../http';

const endpoint = '/v1/mc_contract_submissions';

type IPPApprovalStatusType =
  | 'Pending'
  | 'In Progress'
  | 'Approved'
  | 'Rejected'
  | 'N/A';

export type MasterDashboardCompanyType = {
  id: number;
  name: string;
  merchant_type: 'fave' | 'fastpay';
  ipp_approval_status: IPPApprovalStatusType;
};

type SubmissionsType = {
  favepay_submission: boolean;
  deal_submission: boolean;
  ecard_submission: boolean;
  favepay_later_submission: boolean;
  payment_mode_submission: boolean;
  ipp_submission: boolean;
};

type GeneralMasterDashboardType = SubmissionsType & {
  id: number;
  submission_time: string;
  company: MasterDashboardCompanyType;
};

export type MCContractSubmissionsType = GeneralMasterDashboardType & {
  decline_reason: string;
};

export type FetchMasterDashboardListingResponseType = {
  mc_contract_submissions: MCContractSubmissionsType[];
  count: number;
};

type FetchMasterDashboardCompanyListingResponseType = SubmissionsType & {
  id: number;
  company?: MasterDashboardCompanyType & {
    person_in_charge: string;
    phone: string;
    contract_signee: {
      id: number;
      business_registration_name: string;
      business_registration_number: string;
      business_registration_address: string;
    };
  };
  contract_url: string;
};

type FetchMasterDashboardListingPayloadType = TableConfigType &
  DatasetPayloadType;

type FetchMasterDashboardCompanyListingPayloadType = {
  id: number;
} & DatasetPayloadType;

const fetchMasterDashboardListing = createFetchAPI<
  FetchMasterDashboardListingPayloadType,
  FetchMasterDashboardListingResponseType
>(endpoint);

export const useFetchMasterDashboardListing = (
  props: FetchMasterDashboardListingPayloadType,
) =>
  useQuery<FetchMasterDashboardListingResponseType>(
    ['masterDashboardListing', { ...props }],
    fetchMasterDashboardListing,
  );

type MutateMasterDashboardItemProps = {
  id: number;
  action?: 'accept' | 'decline';
  params?: {};
};

const mutateMasterDashboardItem = (props: MutateMasterDashboardItemProps) =>
  http.put(
    `${endpoint}/${props.id}/${props.action ? props.action : ''}`,
    props.params,
  );

export const useMutateMasterDashboardItem = () =>
  useMutation(mutateMasterDashboardItem);

const fetchMasterDashboardCompanyListing = createFetchAPI<
  { id: number },
  FetchMasterDashboardCompanyListingResponseType
>();

export const useFetchMasterDashboardCompanyListing = ({
  id,
  dataset,
}: FetchMasterDashboardCompanyListingPayloadType) =>
  useQuery<FetchMasterDashboardCompanyListingResponseType>(
    ['masterDashboardCompanyListing', { url: `${endpoint}/${id}`, dataset }],
    fetchMasterDashboardCompanyListing,
  );

type FetchApplicationFeeResponseType = {
  annual_license_fee: number;
  annual_license_fee_remarks: string;
  one_time_setup_fee: number;
  one_time_setup_fee_remarks: string;
  annual_maintenance_fee: number;
  annual_maintenance_fee_remarks: string;
  any_other_fee: number;
  any_other_fee_remarks: string;
  annual_license_fee_activation_date: string;
  one_time_setup_fee_activation_date: string;
  annual_maintenance_fee_activation_date: string;
  any_other_fee_activation_date: string;
};

const fetchApplicationFeeData = createFetchAPI<
  { id: number },
  FetchApplicationFeeResponseType
>();

export const useFetchApplicationFeeData = ({ id }: { id: number }) =>
  useQuery<FetchApplicationFeeResponseType>(
    ['applicationFeeData', { url: `/v1/companies/${id}/application_fees` }],
    fetchApplicationFeeData,
  );

export type MutateIPPApprovalStatusFormType = {
  ipp_approval_status: 'pending' | 'in_progress' | 'approved' | 'rejected';
  documents: (UploadFile & { file_url: string; id: number })[];
  ipp_merchant_id: string;
};

type MutateIPPApprovalStatusPayload = { id: number; formData: FormData };

const mutateIPPApprovalStatus = ({
  id,
  formData,
}: MutateIPPApprovalStatusPayload) =>
  http.put(`${endpoint}/${id}/update_ipp_approval_status`, formData);

export const useMutateIPPApprovalStatus = () =>
  useMutation(mutateIPPApprovalStatus);
