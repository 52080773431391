import { MutableRefObject, useEffect, useState } from 'react';
import useDebouncedCallback from './useDebouncedCallback';

// If there is a ref prop warning in the console,
// can refer to:
// https://github.com/facebook/react/issues/13179

// This hook is meant to be used for antd table only
// as it does not support dynamic height natively
const useDynamicTableHeight = (
  ref: MutableRefObject<HTMLDivElement | null>,
) => {
  const [tableHeight, setTableHeight] = useState(0);
  const HEADER_HEIGHT = 40;

  const handleContainerResize = useDebouncedCallback(([clientHeight]) =>
    setTableHeight(clientHeight - HEADER_HEIGHT),
  );

  useEffect(() => {
    const observer = new ResizeObserver((entries: ResizeObserverEntry[]) =>
      handleContainerResize(entries[0].target.clientHeight),
    );

    if (ref.current) {
      observer.observe(ref.current);

      setTableHeight(ref.current.clientHeight - HEADER_HEIGHT);
    }

    return () => {
      if (ref.current) observer.unobserve(ref.current);
    };
  }, [ref, setTableHeight, handleContainerResize]);

  return {
    tableHeight,
  };
};

export default useDynamicTableHeight;
