import { useContext, useCallback } from 'react';
import {
  CleverTapContext,
  CleverTapScreenDisplayedEvent,
  CleverTapTappedOnEvent,
} from '../contexts/CleverTapContext';

/* Migrated to typescript from useCleverTap.js */
export default function useCleverTap() {
  const { cleverTapEvent } = useContext(CleverTapContext);

  return {
    screenDisplayed: {
      push: useCallback(
        <T extends CleverTapScreenDisplayedEvent>({
          screen_name,
          section_name,
          ...props
        }: T) => {
          if (!screen_name) {
            console.error(
              'useCleverTap: event object with screen_name required',
            );
            return;
          }

          cleverTapEvent.push({
            name: 'Screen Displayed',
            screen_name,
            section_name,
            props: {
              ...props,
              screen_width: window.innerWidth,
              screen_height: window.innerHeight,
            },
          });
        },
        [cleverTapEvent],
      ),
    },
    tapped: {
      push: useCallback(
        <T extends CleverTapTappedOnEvent>({
          screen_name,
          tapped_on,
          section_name,
          ...props
        }: T) => {
          cleverTapEvent.push({
            name: 'Tapped',
            screen_name,
            tapped_on,
            section_name,
            props: {
              ...props,
              screen_width: window.innerWidth,
              screen_height: window.innerHeight,
            },
          });
        },
        [cleverTapEvent],
      ),
    },
  };
}
