import { useQuery } from 'react-query';
import {
  PaginatedPayloadBaseType,
  PayloadBaseType,
} from '../../types/configTypes';
import { ECardsType } from '../../types/dataTypes';
import { createFetchAPI } from '../http';

type FetchECardsSummaryResponseType = {
  total_ecards: number;
  active_ecards: number;
  inactive_ecards: number;
  scheduled_ecards: number;
  draft_ecards: number;
};

type FetchECardsListResponseType = {
  ecards: ECardsType[];
  count: number;
};

const fetchECardsSummary = createFetchAPI<
  PayloadBaseType,
  FetchECardsSummaryResponseType
>('/v1/ecards/product_summary');

export const useFetchECardsSummary = (props: PayloadBaseType) =>
  useQuery<FetchECardsSummaryResponseType>(
    ['fetchECardsSummary', { ...props }],
    fetchECardsSummary,
  );

const fetchECardsList = createFetchAPI<
  PaginatedPayloadBaseType,
  FetchECardsListResponseType
>('/v1/ecards');

export const useFetchECardsList = (props: PaginatedPayloadBaseType) =>
  useQuery<FetchECardsListResponseType>(
    ['fetchECardsList', { ...props }],
    fetchECardsList,
  );
