import { Table } from 'fave-ui';
import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { RenderColumn } from '../../../helpers/tableHelpers/RenderColumn';
import {
  calcTableHeight,
  calcTableWidth,
  createOnTableChangeHandler,
  formatFilter,
  valueOrDash,
} from '../../../utils/utilFunctions';
import { ColumnsType } from 'antd/lib/table';
import { format, parseISO } from 'date-fns';
import style from './style.module.css';
import { defaultTableConfig } from '../../../helpers/defaults';
import { TableSettingsType } from '../../../types/configTypes';
import {
  BDDealsType,
  useFetchDealsList,
  useMutateDuplicateDeal,
} from '../../../services/MySubmissions';
import { MySubmissionStatusType } from '../../../types/dataTypes';
import { actionItems, statusFilter } from '../helpers';
import { Funnel } from 'phosphor-react';
import { mySubmissionElementIDs } from '..';

const tableHeight = calcTableHeight();
const tableWidth = calcTableWidth();

export const DealsTab: React.FC = () => {
  const [searchParams] = useSearchParams();
  const keyword = searchParams.get('query');

  const [tableConfig, setTableConfig] =
    useState<TableSettingsType>(defaultTableConfig);

  const tableChangeHandler = createOnTableChangeHandler(
    setTableConfig,
    filters =>
      formatFilter([{ key: 'mc_my_submission_status', value: filters.status }]),
  );

  const { data: dealsData, isFetching } = useFetchDealsList({
    ...tableConfig,
    page: tableConfig.current,
    filter: `partner=${keyword || ''}|${tableConfig.filter || ''}`,
    dataset: 'company',
  });

  const { mutate: mutateDuplicateDeal } = useMutateDuplicateDeal();

  const navigate = useNavigate();

  const dealTabColumns: ColumnsType<BDDealsType> = [
    {
      title: 'Submission Date',
      dataIndex: 'bd_submit_time',
      width: 200,
      render: (date: string) =>
        date ? format(parseISO(date), 'd MMM yyyy') : '-',
    },
    {
      title: 'Submitted by',
      dataIndex: 'bd_email',
      width: 200,
    },
    {
      title: 'Merchant Name',
      dataIndex: ['company', 'name'],
      width: 200,
    },
    {
      title: 'Deal Name',
      dataIndex: 'name',
      width: 200,
    },
    {
      title: 'Margin',
      dataIndex: 'fave_commision',
      width: 100,
      render: data => valueOrDash(data, '%'),
    },
    {
      title: 'Price',
      dataIndex: 'original_price',
      width: 100,
      render: (_, record) => (
        <>
          <span className={style.strikeThroughText}>
            {record.original_price}
          </span>
          <br></br>
          <span>{record.fave_selling_price}</span>
        </>
      ),
    },
    {
      title: 'Submission Status',
      dataIndex: 'status',
      width: 200,
      filterIcon: (
        <Funnel
          size={16}
          weight="light"
          data-id={mySubmissionElementIDs.dealsSubmissionStatusFilterIcon}
        />
      ),
      filters: statusFilter,
      filterMode: 'tree',
      render: (status: MySubmissionStatusType, data) =>
        RenderColumn.mySubmissionsStatusPopover(status, data, 'Deal'),
    },
    {
      title: 'Actions',
      key: 'action',
      width: 120,
      render: (data: BDDealsType) =>
        RenderColumn.dropdownMenu(
          actionItems(data, 'Deal', navigate, mutateDuplicateDeal),
          '. . .',
          'bottomLeft',
          mySubmissionElementIDs.dealsActionBtn,
        ),
      fixed: 'right',
    },
  ];

  return (
    <>
      <Table
        dataSource={dealsData?.bd_deals}
        columns={dealTabColumns}
        scroll={{
          x: tableWidth,
          y: tableHeight,
        }}
        className={style.table}
        onChange={tableChangeHandler}
        loading={isFetching}
        pagination={{
          total: dealsData?.count,
          position: ['bottomCenter'],
          pageSize: tableConfig.pageSize,
          current: tableConfig.page,
          showSizeChanger: false,
        }}
        rowKey={row => row.id}
      />
    </>
  );
};
