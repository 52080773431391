import React from 'react';
import { TabPane, Tabs } from 'fave-ui';
import { PageHeaderWithSpace } from '../../components/common';
import ActionsNeededDashboardView from './PaymentFileDashboardView';

const dashboardTabs = [
  {
    tab: 'Actions Needed',
    dataId: '',
    key: '1',
    children: <ActionsNeededDashboardView />,
  },
];

const MerchantSettlementView: React.FC = () => {
  const onTapClickHandler = (key: string) => console.log(key);

  return (
    <>
      <PageHeaderWithSpace
        title={'Merchant Settlement Dashboard'}
        subTitle={'Tighten merchant settlement control, ensure data integrity'}
      />
      <Tabs defaultActiveKey="1" onTabClick={onTapClickHandler}>
        {dashboardTabs.map(({ tab, key, dataId, ...restItems }) => (
          <TabPane
            {...restItems}
            tab={<span data-id={dataId}>{tab}</span>}
            key={key}
          />
        ))}
      </Tabs>
    </>
  );
};

export default MerchantSettlementView;
