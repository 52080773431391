export const defaultTableConfig = {
  page: 1,
  limit: 10,
  sort_order: undefined,
  sort_by: undefined,
  filter: undefined,
};

export const defaultPaginationConfig = {
  page: 1,
  limit: 10,
};
