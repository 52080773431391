import { Select, Option, CheckeableItem } from 'fave-ui';
import { useContext } from 'react';
import { EnvironmentContext } from '../contexts/EnvironmentContext';
import { useElementIdContext } from '../views/LoginLayout';

type StagingDropdownProps = {
  disabled?: boolean;
};

export const StagingDropdown = ({ disabled }: StagingDropdownProps) => {
  const { selectStaging, optionStaging } = useElementIdContext();
  const { currentStaging, switchStagingServer, stagingEnironments } =
    useContext(EnvironmentContext);

  return (
    <Select
      disabled={disabled}
      showArrow={!disabled}
      style={{ width: 124 }}
      defaultValue={currentStaging}
      optionLabelProp={'label'}
      bordered={false}
      dropdownMatchSelectWidth={false}
      onChange={value => switchStagingServer(value)}
      data-id={selectStaging}
    >
      {stagingEnironments.map(({ key, label }) => (
        <Option
          key={key}
          value={key}
          label={label}
          data-id={`${optionStaging}-${key}`}
        >
          <CheckeableItem>{label}</CheckeableItem>
        </Option>
      ))}
    </Select>
  );
};
