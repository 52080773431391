import { createContext, PropsWithChildren, useContext } from 'react';

// This hook is only meant for components that are used once per parent component.
// If the component is used multiple times within the same parent, or nested parents
// that provide the same context, then `data-id` should be set manually

export function createUiIdContext<ContextType>(value: ContextType) {
  const ElementIdContext = createContext<ContextType>(value);
  ElementIdContext.displayName = 'ElementIdContext';

  return {
    ElementIdProvider: ({ children }: PropsWithChildren<{}>) => {
      return (
        <ElementIdContext.Provider value={value}>
          {children}
        </ElementIdContext.Provider>
      );
    },
    useElementIdContext: () => useContext<ContextType>(ElementIdContext),
  };
}
