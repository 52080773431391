import { Message, UploadFile } from 'fave-ui';
import { useFormikContext } from 'formik';
import { CaretRight } from 'phosphor-react';
import React, { useEffect, useMemo } from 'react';
import { useLocation, useParams } from 'react-router';
import { Input } from '../../../components/common/form/Input';
import SubmitButton from '../../../components/common/form/SubmitButton';
import {
  showRemoveFileModal,
  showUnselectPaymentModeModal,
} from '../../../helpers/formHelpers';
import {
  PaymentModeFormType,
  PaymentModeType,
  useDeletePaymentModeDocument,
} from '../../../services/PaymentMode/usePaymentMode';
import { McApprovalDocumentsType } from '../../../types/dataTypes';
import { filterObjectByKey } from '../../../utils/utilFunctions';
import {
  approvalDocumentsFieldKey,
  outletsFieldKey,
} from '../helpers/paymentModeHelpers';
import style from '../style.module.css';
import { Select } from '../../../components/common/form';
import { ActionForm, OutletStatusPaymentMode } from '../helpers/enums';

type PaymentModeCreateFormProps = {
  isApplicationFeePage?: boolean;
  enableUpdateForm?: boolean;
  listOutlets?: {
    key: string;
    label: string;
    value: string;
    has_draft_payment_mode: boolean;
    has_existing_payment_mode: boolean;
    id: number;
  }[];
  setStatusOutletPaymentMode?: React.Dispatch<
    React.SetStateAction<OutletStatusPaymentMode>
  >;
};

const GroupedInputBoxes = ({
  title,
  paymentModes,
  isFastpay,
  showUncheckModal,
  disabled,
}: {
  title: string;
  paymentModes: PaymentModeType[];
  isFastpay: boolean;
  showUncheckModal?: boolean;
  disabled?: boolean;
}) => (
  <tr className={style.groupedInputBox}>
    <td className={style.group}>
      {title}
      <div className={style.greyLine} />
    </td>
    {paymentModes.map((paymentMode, index) => {
      const objectPath = `${title}.${index}`;

      return (
        <td key={objectPath} className={style.variant}>
          <div className={style.checkbox}>
            {isFastpay && (
              <Input
                disabled={disabled}
                name={'status'}
                type={'checkbox'}
                checked={paymentMode.status as boolean}
                valuePath={objectPath}
                onChangeCheckbox={(isChecked, handleChange) =>
                  showUncheckModal && !isChecked
                    ? showUnselectPaymentModeModal({
                        onOk: handleChange,
                      })
                    : handleChange()
                }
              />
            )}
            <span className={style.title}>{paymentMode.variant}</span>
          </div>
          <div className={style.input}>
            {(paymentMode.status || !isFastpay) && (
              <>
                {paymentMode.is_fpx && (
                  <div className={style.inputBox}>
                    <Input
                      name={
                        'fixed_amount' +
                        `${paymentMode.group.replace(' ', '')}${
                          paymentMode.variant
                        }`
                      }
                      type={'number'}
                      value={
                        paymentMode[
                          `fixed_amount${paymentMode.group.replace(' ', '')}${
                            paymentMode.variant
                          }`
                        ] as number
                      }
                      addonBefore={'RM'}
                      placeholder="5"
                      allowClear
                      bordered={false}
                      valuePath={objectPath}
                      disabled={disabled}
                    />
                  </div>
                )}
                <div className={style.inputBox}>
                  <Input
                    name={
                      'rate' +
                      `${paymentMode.group.replace(' ', '')}${
                        paymentMode.variant
                      }`
                    }
                    type={'number'}
                    value={
                      paymentMode[
                        `rate${paymentMode.group.replace(' ', '')}${
                          paymentMode.variant
                        }`
                      ] as number
                    }
                    addonAfter={'%'}
                    placeholder="5"
                    allowClear
                    bordered={false}
                    valuePath={objectPath}
                    disabled={disabled}
                  />
                </div>
              </>
            )}
          </div>
        </td>
      );
    })}
  </tr>
);

const PaymentModeCreateForm: React.FC<PaymentModeCreateFormProps> = ({
  isApplicationFeePage = true,
  enableUpdateForm = true,
  listOutlets = [],
  setStatusOutletPaymentMode = () => {},
}) => {
  const { id, mode } = useParams();
  const navigate = useLocation();
  const params = new URLSearchParams(navigate.search);
  const parsedID = parseInt(id || '');

  const isCreateMode = mode === ActionForm.Create;
  const isResubmit = mode === ActionForm.Resubmit;

  const { values, getFieldProps } = useFormikContext<PaymentModeFormType>();
  const { mutate: deletePaymentModeDocument } = useDeletePaymentModeDocument();

  const showApprovalDocuments = isApplicationFeePage;
  const showUnselectPaymentModeModal = !isApplicationFeePage;

  const onClickDeleteFile = (file: UploadFile) =>
    isCreateMode
      ? showRemoveFileModal({ file })
      : showRemoveFileModal({
          file,
          onOk: (resolve, reject) =>
            (file as unknown as McApprovalDocumentsType).id
              ? deletePaymentModeDocument(
                  {
                    company_id: parsedID,
                    approval_document_id: (
                      file as unknown as McApprovalDocumentsType
                    ).id,
                  },
                  {
                    onSuccess: () => {
                      resolve();
                      Message.success({
                        content: 'File successfully deleted.',
                      });
                    },
                    onError: reject,
                  },
                )
              : resolve(),
        });

  const paymentModeValues = filterObjectByKey(
    values,
    [approvalDocumentsFieldKey, outletsFieldKey],
    item => {
      const isPaymentMode = !item.includes('_');
      return isPaymentMode && item;
    },
  );

  const fileList = useMemo(
    () =>
      values?.approval_documents?.map((item: any) => {
        const hasFileURL = !!item.file_url;
        const data = {
          ...item,
        };

        if (hasFileURL) {
          data.name = item.file_url.substring(
            item.file_url.lastIndexOf('/') + 1,
          );
        }

        return data;
      }),
    [values.approval_documents],
  );

  const isCheckedAtLeastOne =
    filterObjectByKey(
      values,
      [approvalDocumentsFieldKey, outletsFieldKey],
      item => {
        // application fee values have _ in their key
        const isPaymentMode = !item.includes('_');

        return (
          isPaymentMode &&
          values[item].filter(paymentMode => paymentMode.status).length > 0
        );
      },
    ).length > 0;

  const enableEditInputBoxes = enableUpdateForm;
  const disableFormSubmission = !isCheckedAtLeastOne || !enableUpdateForm;

  useEffect(() => {
    const listSelectedOutletIds = getFieldProps('outlets')?.value;
    if (isResubmit && listSelectedOutletIds) {
      const listExisting = listOutlets.filter(
        outlet =>
          listSelectedOutletIds.includes(`${outlet.id}`) &&
          outlet.has_existing_payment_mode,
      );
      if (listExisting.length === listSelectedOutletIds.length) {
        setStatusOutletPaymentMode(OutletStatusPaymentMode.Existing);
        // For fave mode, if user select outlet which is existing payment mode, set status outlet payment mode
        // to know form can edit without required field or not
      } else {
        setStatusOutletPaymentMode(OutletStatusPaymentMode.New);
      }
    }
  }, [getFieldProps('outlets'), listOutlets]);

  return (
    <>
      <div className={style.table}>
        <table>
          <tbody>
            {paymentModeValues.map((item, index) => (
              <React.Fragment key={index}>
                <tr className={style.topRow}>
                  <td colSpan={3} className={style.topCurvedBar} />
                </tr>
                <GroupedInputBoxes
                  key={item}
                  title={item}
                  isFastpay={params.get('merchant_type') === 'fastpay'}
                  paymentModes={values[item]}
                  showUncheckModal={showUnselectPaymentModeModal}
                  disabled={!enableEditInputBoxes}
                />
                <tr className={style.bottomRow}>
                  <td colSpan={3} className={style.bottomCurvedBar} />
                </tr>
                <tr className={style.transparentRow}>
                  <td colSpan={3} />
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
      {showApprovalDocuments && (
        <Input
          name={approvalDocumentsFieldKey}
          type={'file'}
          fileList={fileList}
          onRemove={onClickDeleteFile}
          Text={
            <>
              <p>Click or drag file(s) to this area to upload</p>
              <span className={style.fontBold}>Approval Document</span>
            </>
          }
        />
      )}
      {params.get('merchant_type') !== 'fastpay' && isApplicationFeePage && (
        <div className="mt-3">
          <Select
            name="outlets"
            label="Apply to Outlet"
            placeholder="Select outlets"
            options={listOutlets}
            mode="multiple"
          />
        </div>
      )}
      <div className={style.submitButtonContainer}>
        <SubmitButton
          isDisabled={disableFormSubmission}
          disableIfNotValid={true}
          className={style.submitButton}
          icon={<CaretRight size={16} />}
          iconPlacement={'end'}
        >
          {(_, isSubmitting) => (isSubmitting ? 'Saving...' : 'Save')}
        </SubmitButton>
      </div>
    </>
  );
};

export default PaymentModeCreateForm;
