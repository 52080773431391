import { useMutation, useQuery } from 'react-query';
import http, { createFetchAPI } from '../http';
import { Member } from './useMakerChecker';

const makerCheckerGroupEndpoint = '/checkmates/v1/checker_groups';

const changesGroupEndpoint = (id?: string) =>
  `/checkmates/v1/checker_groups/${id}/changes`;
const checkerGroupDetailEndpoint = (id?: string) =>
  `/checkmates/v1/checker_groups/${id}`;

export const fetchGroupSearchKey = 'fetchGroupSearchList';
export const fetchGroupDetail = 'fetchGroupDetail';

export type GroupQueryParams = {
  page: number;
  limit: number;
  name: string;
  isAdmin: boolean;
};

export type ChangesGroupQueryParams = {
  id?: string;
};

export type GroupInfo = {
  id: number;
  created_at: string;
  name: string;
  members: string;
  checkers: string;
};

export type PaginationGroupResponse = {
  groups: GroupInfo[];
  count: number;
  total_pages: number;
  total_count: number;
  page: number;
};

type ChangesEvent = {
  event: string;
  field: string;
  old_value: string;
  new_value: string;
  created_at: string;
};

export type ChangesGroupQueryResponse = {
  group_changes: ChangesEvent[];
  members_changes: ChangesEvent[];
};

export type CheckerGroupDetailResponse = {
  name: string;
  members: Member[];
  checkers: Member[];
};

export type CreateCheckerGroupPayload = {
  name: string;
  members: number[];
  checkers: number[];
};

export type UpdateCheckerGroupPayload = CreateCheckerGroupPayload & {
  removed_members: number[];
  removed_checkers: number[];
};

type CreateGroupResponse = {
  id: number;
  name: string;
};

const fetchCheckerGroup = createFetchAPI<
  GroupQueryParams,
  PaginationGroupResponse
>(makerCheckerGroupEndpoint);

export const useFetchCheckerGroup = (params: GroupQueryParams) =>
  useQuery<PaginationGroupResponse>(
    [fetchGroupSearchKey, { ...params }],
    fetchCheckerGroup,
    { enabled: params.isAdmin, refetchOnMount: 'always' },
  );

const fetchChangesGroup = createFetchAPI<
  ChangesGroupQueryParams,
  ChangesGroupQueryResponse
>();

export const useFetchChangesGroup = (params: ChangesGroupQueryParams) =>
  useQuery<ChangesGroupQueryResponse>(
    [fetchGroupSearchKey, { url: changesGroupEndpoint(params.id) }],
    fetchChangesGroup,
    {
      enabled: !!params.id,
    },
  );

const fetchCheckerGroupDetail = createFetchAPI<
  {},
  CheckerGroupDetailResponse
>();

export const useFetchCheckerGroupDetail = (params: ChangesGroupQueryParams) =>
  useQuery<CheckerGroupDetailResponse>(
    [fetchGroupDetail, { url: checkerGroupDetailEndpoint(params.id) }],
    fetchCheckerGroupDetail,
    {
      enabled: !!params.id,
    },
  );

const createGroup = (param: CreateCheckerGroupPayload) =>
  http.post<CreateGroupResponse>(makerCheckerGroupEndpoint, param);

export const useCreateCheckerGroup = () => useMutation(createGroup);

const updateGroup = (param: UpdateCheckerGroupPayload & { id: string }) =>
  http.put(`${makerCheckerGroupEndpoint}/${param.id}`, param);

export const useUpdateCheckerGroup = () => useMutation(updateGroup);
