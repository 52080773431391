import { MutableRefObject, useEffect, useRef } from 'react';
import { Formik, FormikContextType, FormikValues } from 'formik';
import { Form as AntForm } from 'fave-ui';

export interface FormProps<T> {
  formRef?: MutableRefObject<FormikContextType<T> | undefined>;
}

const Form = <T extends {}>({
  initialValues,
  onSubmit,
  validationSchema,
  validateOnChange = false,
  validateOnSchemaChange = false,
  children,
  formRef,
  ...rest
}: FormikValues & FormProps<T>) => {
  const ref = useRef<FormikContextType<T>>();

  useEffect(() => {
    if (validateOnSchemaChange) ref.current?.validateForm();
  }, [ref, validateOnSchemaChange, validationSchema]);

  const refPassthrough = (instance: FormikContextType<T>) => {
    ref.current = instance;

    if (formRef) formRef.current = instance;
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      validateOnChange={validateOnChange}
      validateOnMount
      enableReinitialize
      innerRef={refPassthrough}
      {...rest}
    >
      <AntForm layout="vertical">{children}</AntForm>
    </Formik>
  );
};

export default Form;
