import { Spin } from 'antd';
import { Button, Drawer, Message, Pagination } from 'fave-ui';
import { useEffect, useState } from 'react';
import ClosableTag from '../../components/ClosableTag';
import { SearchBarExpandable } from '../../components/common/index';
import { defaultPaginationConfig } from '../../helpers/defaults';
import {
  queryKeys,
  useFetchDealOutletsList as useFetchLinkedOutletsList,
  useMutateUnlinkOutlet,
} from '../../services/OutletSettings';
import { PaginationSettingsType } from '../../types/configTypes';
import { createOnPaginationChangeHandler } from '../../utils/utilFunctions';
import style from './style.module.css';
import { useQueryClient } from 'react-query';

type LinkedOutletsDrawerProps = {
  isDrawerOpen: boolean;
  setIsDrawerOpen: (bool: boolean) => void;
  dealID: number;
  enableViewOutletsList: boolean;
  enableUnlinkOutlets: boolean;
};

const linkedOutletsPaginationConfig = {
  page: defaultPaginationConfig.page,
  limit: 20,
};

const LinkedOutletsDrawer = ({
  isDrawerOpen,
  setIsDrawerOpen,
  dealID,
  enableViewOutletsList,
  enableUnlinkOutlets,
}: LinkedOutletsDrawerProps) => {
  const queryClient = useQueryClient();
  const enableFetchOutletsList = isDrawerOpen && enableViewOutletsList;

  const [paginationConfig, setPaginationConfig] =
    useState<PaginationSettingsType>(linkedOutletsPaginationConfig);

  const [searchOutletValue, setSearchOutletValue] = useState('');

  const {
    data: linkedOutletsListData,
    isFetching: isFetchingLinkedOutlets,
    refetch: refetchLinkedOutlets,
  } = useFetchLinkedOutletsList(
    {
      dealID,
      filter: `id_or_name=${searchOutletValue}`,
      ...paginationConfig,
    },
    { enabled: enableFetchOutletsList },
  );

  const { mutate: unlinkOutlet } = useMutateUnlinkOutlet();

  useEffect(() => {
    if (enableFetchOutletsList) refetchLinkedOutlets();
  }, [enableFetchOutletsList]);

  const paginationChangeHandler =
    createOnPaginationChangeHandler(setPaginationConfig);

  const LinkedOutletsPagination = () => (
    <Pagination
      className={style.linkedDealOutletsPagination}
      simple
      defaultCurrent={paginationConfig.page}
      pageSize={paginationConfig.limit}
      onChange={paginationChangeHandler}
      total={linkedOutletsListData?.count}
    />
  );

  const CloseDrawerButton = () => (
    <Button
      type="text"
      onClick={() => {
        queryClient.invalidateQueries(queryKeys.availableOutlets);
        queryClient.invalidateQueries(queryKeys.outletSummary);

        setIsDrawerOpen(false);
      }}
    >
      Close
    </Button>
  );

  const handleUnlinkOutlet = (id: number, dealID: number) =>
    unlinkOutlet(
      { eagleID: id, dealID },
      {
        onSuccess: () => {
          Message.success({
            content: 'Outlet successfully unlinked!',
          });
          refetchLinkedOutlets();
        },
      },
    );

  const LinkedOutletsList = () => (
    <Spin spinning={isFetchingLinkedOutlets} wrapperClassName={style.spin}>
      <div>
        {linkedOutletsListData?.outlet_eagle_activities.map(
          ({ id, outlet }) => (
            <ClosableTag
              closable={enableUnlinkOutlets}
              isReversed
              key={id}
              onClose={() => handleUnlinkOutlet(id, dealID)}
            >
              {outlet.name}
            </ClosableTag>
          ),
        )}
      </div>
    </Spin>
  );

  return (
    <Drawer
      visible={isDrawerOpen}
      maskClosable={false}
      extra={<CloseDrawerButton />}
      title={'Linked Outlets'}
      placement="right"
      footer={<LinkedOutletsPagination />}
    >
      <SearchBarExpandable
        onChange={setSearchOutletValue}
        placeholder={'search here'}
        className={style.searchBar}
        alwaysOpen={true}
        alwaysShowBorder={true}
      />
      <LinkedOutletsList />
    </Drawer>
  );
};

export default LinkedOutletsDrawer;
