import { Descriptions, DescriptionsProps, Divider } from 'fave-ui';
import clsx from 'clsx';

import style from './style.module.css';
import Container from '../Container/Container';

const SummaryCard = ({
  className,
  layout = 'vertical',
  children,
  endSideContent,
  ...props
}: DescriptionsProps & { endSideContent?: React.ReactNode }) => {
  return (
    <Container className={style.summaryCard}>
      <Descriptions
        {...props}
        layout={layout}
        className={clsx(style.card, className)}
      >
        {children}
      </Descriptions>

      {endSideContent && (
        <>
          <Divider type="vertical" className={style.divider} />
          <div className={style.endSideContent}>{endSideContent}</div>
        </>
      )}
    </Container>
  );
};

export default SummaryCard;
