import clsx from 'clsx';
import { Tag, TagProps } from 'fave-ui';
import style from './style.module.css';

type ClosableTagProps = TagProps & { isReversed?: boolean };

const ClosableTag = ({
  children,
  className,
  isReversed,
  closable = true,
  ...restProps
}: ClosableTagProps) => (
  <Tag
    closable={closable}
    className={clsx(style.tag, isReversed && style.reversed, className)}
    {...restProps}
  >
    {children}
  </Tag>
);

export default ClosableTag;
