import { useMutation, useQuery } from 'react-query';
import http, { createFetchAPI } from '../http';
import cookieSet from '../../helpers/cookieSet';

const requestEndpoint = '/checkmates/v1/requests';
const modelsEndpoint = '/checkmates/v1/requests/models';
export const fetchRequestCacheKey = 'fetchRequestPage';
export const fetchExportExcel = 'exportExcel';
const searchCheckerEndpoint = '/checkmates/v1/checkers/search';
const searchMemberEndpoint = '/checkmates/v1/checkers/users';

export const fetchCheckerSearchKey = 'fetchCheckerSearchList';
export const fetchMemberSearchKey = 'fetchMemberSearchList';

export type ParamRejectPayload = {
  rejected_reason: string;
  tenant_code: string;
};

export type RejectRequestPayload = {
  request_id: number;
  params: ParamRejectPayload;
};

export type ApproveRequestPayload = {
  request_id: number;
};

export type RequestQueryParams = {
  created_start_datetime?: string;
  created_end_datetime?: string;
  approval_start_datetime?: string;
  approval_end_datetime?: string;
  status?: string;
  requester_name?: string;
  reviewer_name?: string;
  models?: string;
  page: number;
  limit: number;
  request_list_type: string;
};

export type InfoUserRequest = {
  name: string;
  email: string;
  created_at?: Date;
  reviewed_at: Date;
};

export type RequestChangesContent = {
  [subkey: string]: (string | number)[];
};

export type RequestChanges = {
  [key: string]: RequestChangesContent | RequestChangesContent[];
};

export type Request = {
  id: number;
  model: string;
  about: string;
  url: string;
  changes: RequestChanges;
  remarks: string;
  status: string;
  rejected_reason: string;
  merchant_id: string;
  merchant_name: string;
  creator: InfoUserRequest;
  reviewer: InfoUserRequest;
  checkers: InfoUserRequest[];
};

export type PaginationRequest = {
  requests: Request[];
  count: number;
  total_page: number;
  total_count: number;
  page: number;
};

export type CheckerQueryParams = {
  name: string;
};

export type Member = {
  id: number;
  name: string;
};

export type PaginationMember = {
  users: Member[];
  count: number;
  total_page: number;
  total_count: number;
  page: number;
};

export type Models = {
  models: string[];
};

const fetchRequestPage = createFetchAPI<RequestQueryParams, PaginationRequest>(
  requestEndpoint,
);

export const useFetchRequestPageForChecker = (params: RequestQueryParams) =>
  useQuery<PaginationRequest>(
    [fetchRequestCacheKey, { ...params }],
    fetchRequestPage,
  );

const fetchModels = createFetchAPI<{}, Models>(modelsEndpoint);

export const useFetchModels = () =>
  useQuery<Models>([modelsEndpoint], fetchModels);

const rejectRequest = ({ request_id, params }: RejectRequestPayload) =>
  http.post(`checkmates/v1/requests/${request_id}/reject`, {}, { params });

export const useRejectRequestMaker = () => useMutation(rejectRequest);

const approveRequest = ({ request_id }: ApproveRequestPayload) =>
  http.post(`checkmates/v1/requests/${request_id}/approve`);

export const useApproveRequestMaker = () => useMutation(approveRequest);

const cancelRequest = ({ request_id }: ApproveRequestPayload) =>
  http.post(`checkmates/v1/requests/${request_id}/cancel`);

export const useCancelRequestMaker = () => useMutation(cancelRequest);

export const useExportRequest = () =>
  http
    .get('checkmates/v1/requests/export_excel', {
      responseType: 'blob',
      params: {
        tenant_code: cookieSet.currentTenant.get(),
      },
    })
    .then(response => response);

const fetchCheckers = createFetchAPI<CheckerQueryParams, PaginationMember>(
  searchCheckerEndpoint,
);

export const useFetchCheckers = (param?: CheckerQueryParams) =>
  useQuery<PaginationMember>([fetchCheckerSearchKey, param], fetchCheckers, {
    enabled: !!param,
  });

const fetchMembers = createFetchAPI<CheckerQueryParams, PaginationMember>(
  searchMemberEndpoint,
);

export const useFetchMembers = (param?: CheckerQueryParams) =>
  useQuery<PaginationMember>([fetchMemberSearchKey, param], fetchMembers, {
    enabled: !!param,
  });
