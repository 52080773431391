import * as React from 'react';

const FastPayIconSVG = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="40"
      height="20"
      viewBox="0 0 40 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="40" height="20" rx="4.53488" fill="#4823EA" />
      <path
        d="M6.76001 14.6889L7.90824 7.38255C8.00998 6.80437 8.18855 6.3593 8.44394 6.04733C8.69933 5.73744 9.0004 5.52322 9.34923 5.40883C9.69806 5.29444 10.051 5.23621 10.4082 5.23621C11.1391 5.23621 11.8118 5.25076 12.4285 5.27988C13.0452 5.309 13.6535 5.35059 14.2577 5.40051L14.0148 6.98531H10.6864C10.3812 6.98531 10.1549 7.06019 10.0095 7.20993C9.86417 7.35968 9.76658 7.58221 9.71675 7.87962L9.48627 9.39995L13.0182 9.50186L12.7752 10.9972L9.21842 11.0867L8.64534 14.6889H6.76001Z"
        fill="white"
      />
      <path
        d="M15.8234 14.7637C15.4829 14.7637 15.1735 14.6826 14.8932 14.5204C14.6129 14.3582 14.3866 14.1024 14.2163 13.755C14.0461 13.4056 13.9609 12.9543 13.9609 12.4011C13.9609 12.1536 13.9775 11.8999 14.0128 11.6357C14.0461 11.3716 14.0814 11.1511 14.1146 10.9723C14.2246 10.2485 14.424 9.64952 14.7147 9.17741C15.0033 8.70529 15.3978 8.34965 15.894 8.11047C16.3903 7.8713 17.0049 7.75275 17.7358 7.75275C18.1178 7.75275 18.4916 7.78602 18.857 7.85466C19.2224 7.92329 19.5588 8.01688 19.864 8.13543C20.1693 8.25398 20.4205 8.3954 20.6157 8.55763L19.646 14.6889H17.9766L17.8749 13.9984C17.7565 14.1003 17.5863 14.2105 17.3641 14.3311C17.1419 14.4497 16.899 14.5537 16.6311 14.6369C16.3654 14.7221 16.0954 14.7637 15.8234 14.7637ZM16.7412 13.2455C17.0215 13.2455 17.2707 13.1914 17.4866 13.0853C17.7025 12.9793 17.8915 12.8753 18.0534 12.7734L18.5767 9.47898C18.5082 9.43738 18.3919 9.39162 18.2258 9.34587C18.0597 9.30011 17.8624 9.27516 17.6319 9.27516C17.2914 9.27516 17.009 9.34795 16.7848 9.49145C16.5585 9.63704 16.3799 9.84502 16.2491 10.1175C16.1162 10.3899 16.0207 10.7227 15.9625 11.1137C15.9293 11.3092 15.9023 11.4901 15.8857 11.6565C15.8691 11.8229 15.8608 11.956 15.8608 12.0579C15.8608 12.4073 15.9335 12.6923 16.0767 12.9127C16.2221 13.1353 16.4443 13.2455 16.7412 13.2455Z"
        fill="white"
      />
      <path
        d="M24.1307 14.7637C23.8504 14.7637 23.5825 14.7575 23.3271 14.745C23.0717 14.7325 22.8247 14.7138 22.5879 14.6868C22.3492 14.6618 22.1311 14.6265 21.9318 14.5849C21.7325 14.5433 21.556 14.4996 21.4023 14.458L21.5809 13.3474H24.5646C24.7847 13.3474 24.9654 13.3058 25.1066 13.2205C25.2478 13.1352 25.335 13.0125 25.3682 12.8503L25.4056 12.5695C25.4139 12.528 25.418 12.4947 25.418 12.4739C25.418 12.4531 25.418 12.4344 25.418 12.4156C25.418 12.1598 25.2395 12.033 24.8823 12.033H23.8628C23.4891 12.033 23.1527 11.9851 22.8558 11.8853C22.5589 11.7875 22.3263 11.6357 22.1602 11.4319C21.9941 11.2281 21.9111 10.9639 21.9111 10.6395C21.9111 10.5542 21.9152 10.4627 21.9235 10.365C21.9318 10.2672 21.9443 10.1674 21.9609 10.0655L22.0377 9.58088C22.1062 9.22316 22.235 8.90911 22.426 8.63665C22.617 8.3642 22.9056 8.14998 23.2939 7.99192C23.6801 7.83385 24.2013 7.75482 24.8553 7.75482C25.1273 7.75482 25.4222 7.76938 25.7419 7.7985C26.0617 7.82761 26.3732 7.86713 26.6784 7.91289C26.9836 7.96072 27.2473 8.01272 27.4695 8.07303L27.2784 9.18364H24.4857C24.2387 9.18364 24.058 9.22316 23.9438 9.30427C23.8296 9.38538 23.7549 9.51849 23.7216 9.70567L23.6843 9.89701C23.6677 9.95732 23.6573 10.0072 23.6531 10.0509C23.649 10.0925 23.6469 10.132 23.6469 10.1653C23.6469 10.2755 23.6884 10.3608 23.7735 10.4211C23.8587 10.4814 24.0123 10.5105 24.2324 10.5105H25.3807C25.9247 10.5105 26.3607 10.6312 26.6888 10.8745C27.0168 11.1178 27.1788 11.4506 27.1788 11.877C27.1788 11.9622 27.1767 12.0517 27.1726 12.1453C27.1684 12.2389 27.158 12.3324 27.1414 12.426L27.0521 13.0271C26.9504 13.6656 26.6431 14.1148 26.1344 14.3748C25.6236 14.6348 24.955 14.7637 24.1307 14.7637Z"
        fill="white"
      />
      <path
        d="M31.0597 14.6888C30.3703 14.6888 29.8554 14.562 29.5107 14.3062C29.166 14.0503 28.9937 13.6593 28.9937 13.1311C28.9937 12.9772 29.002 12.8004 29.0186 12.6007C29.0352 12.4011 29.0643 12.1806 29.1079 11.9435L29.5792 9.35H28.5203L28.7113 8.13748L29.8844 7.83175L30.4451 5.91626H31.938L31.6327 7.83175H33.2398L33.0094 9.35208H31.3898L30.9807 11.9581C30.9641 12.035 30.9455 12.1369 30.9226 12.2638C30.9018 12.3907 30.8915 12.5071 30.8915 12.609C30.8915 12.8129 30.9455 12.9605 31.0514 13.05C31.1572 13.1394 31.3213 13.1935 31.5414 13.2101L32.32 13.2871L32.0895 14.693H31.0597V14.6888Z"
        fill="white"
      />
    </svg>
  );
};

export default FastPayIconSVG;
