import { Button, Modal } from 'fave-ui';
import { useFormikContext } from 'formik';
import { Info, UploadSimple } from 'phosphor-react';
import { useMemo } from 'react';
import { MutateIPPApprovalStatusFormType } from '../../../services/MasterDashboard/masterDashboardListing';
import { IPPModalProps } from '../helpers/masterDashboardHelpers';
import { Input, SubmitButton } from '../../../components/common/form';
import FileUpload from '../../../components/common/form/FileUpload';
import style from '../style.module.css';

const IPPApprovalModal = ({
  onCancel,
  merchantID,
  isApproveModal = true,
  ...restProps
}: IPPModalProps) => {
  const { values, resetForm } =
    useFormikContext<MutateIPPApprovalStatusFormType>();

  const fileList = useMemo(
    () =>
      values?.documents?.map(item => {
        const hasFileURL = !!item.file_url;
        const data = {
          ...item,
        };

        if (hasFileURL) {
          data.name = item.file_url.substring(
            item.file_url.lastIndexOf('/') + 1,
          );
        }

        return data;
      }),
    [values.documents],
  );

  const visible = !!merchantID;

  const onCloseModal = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (onCancel) onCancel(e);
    resetForm();
  };

  return (
    <Modal
      {...restProps}
      centered={true}
      title={
        <div className="flex items-center">
          <Info size={18} className="mr-3" />
          <span>
            {isApproveModal
              ? `Please upload Proof from 2c2p`
              : `Upload reject proof from 2c2p`}
          </span>
        </div>
      }
      onCancel={onCloseModal}
      visible={visible}
      footer={[
        <Button type={'text'} onClick={onCloseModal} key={1}>
          Cancel
        </Button>,
        <SubmitButton disableIfNotValid disableIfNotDirty key={2}>
          {(_, isSubmitting) =>
            isSubmitting
              ? isApproveModal
                ? 'Saving...'
                : 'Uploading...'
              : isApproveModal
              ? 'Approve'
              : 'Upload'
          }
        </SubmitButton>,
      ]}
    >
      <div className="ml-7">
        <p>
          {isApproveModal
            ? `Once Approve, submission cannot be undone. Be sure to check all
          information thorougly before approving.`
            : `Once Reject, submission cannot be undone. Be sure to check all
          information thorougly before approving.`}
        </p>
        {isApproveModal && (
          <Input
            name={'ipp_merchant_id'}
            label={'IPP Merchant ID'}
            type={'text'}
            maxLength={50}
          />
        )}
        <FileUpload
          name={'documents'}
          fileList={fileList}
          Text={null}
          Hint={null}
          Icon={
            <div className={style.uploadFileIcon}>
              <UploadSimple size={16} className={style.icon} />{' '}
              <span>Click to Upload</span>
            </div>
          }
          accept={'.pdf, .jpg, .png'}
        />
      </div>
    </Modal>
  );
};

export default IPPApprovalModal;
