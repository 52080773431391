import style from './style.module.css';
import { useParams } from 'react-router';
import { Col, Row } from 'antd';
import Form from '../../../../components/common/form/Form';
import { Select } from '../../../../components/common/form/Select';
import { Input } from '../../../../components/common/form/Input';
import { PageHeaderWithBackButton } from '../../../../components/common/PageHeaderWithBackButton';
import { Image } from 'fave-ui';
import {
  useFetchPrizeListingData,
  useFetchPrizeOptions,
} from '../../../../services/FaveArcade/useFetchPrizesListing';
import { capitalizeFirstLetterEachWord } from '../../../../utils/utilFunctions';

export default function PrizeDetailsView() {
  const { id } = useParams();
  const { data: campaignData } = useFetchPrizeListingData({ id: id });
  const { data: currencyData } = useFetchPrizeOptions();

  const initialValues = {
    campaign_name: campaignData?.campaign_name,
    prize_type:
      campaignData?.prize_type &&
      capitalizeFirstLetterEachWord(
        campaignData?.prize_type.split('_').join(' '),
      ),
    prize_name: campaignData?.prize_name,
    prize_description: campaignData?.prize_description,
    prize_subtitle: campaignData?.prize_subtitle,
    cost_of_token: campaignData?.cost_of_token,
    start_date: campaignData?.start_date,
    end_date: campaignData?.end_date,
    max_user_limit: campaignData?.max_user_limit,
    max_campaign_limit: campaignData?.max_campaign_limit,
    is_highlighted: campaignData?.is_highlighted,
    product_types:
      campaignData?.product_types &&
      campaignData?.product_types.map(item =>
        capitalizeFirstLetterEachWord(item.split('_').join(' ')),
      ),
    promo_code_type:
      campaignData?.promo_code_type &&
      capitalizeFirstLetterEachWord(
        campaignData?.promo_code_type.split('_').join(' '),
      ),
    promo_code_value_type:
      campaignData?.promo_code_value_type &&
      capitalizeFirstLetterEachWord(
        campaignData?.promo_code_value_type.split('_').join(' '),
      ),
    amount_value_cents:
      campaignData?.amount_value_cents &&
      campaignData?.amount_value_cents / 100,
    percentage_value: campaignData?.percentage_value,
    max_cap_amount_cents:
      campaignData?.max_cap_amount_cents &&
      campaignData?.max_cap_amount_cents / 100,
    min_purchase_amount_cents:
      campaignData?.min_purchase_amount_cents &&
      campaignData?.min_purchase_amount_cents / 100,
    cashback_type:
      campaignData?.cashback_type &&
      capitalizeFirstLetterEachWord(
        campaignData?.cashback_type.split('_').join(' '),
      ),
    promo_code_prefix: campaignData?.promo_code_prefix,
    validity_day: campaignData?.validity_day,
    whitelisted_outlet_ids: campaignData?.whitelisted_outlet_ids,
    blacklisted_outlet_ids: campaignData?.blacklisted_outlet_ids,
    whitelisted_e_card_ids: campaignData?.whitelisted_e_card_ids,
    blacklisted_e_card_ids: campaignData?.blacklisted_e_card_ids,
    whitelisted_deal_ids: campaignData?.whitelisted_deal_ids,
    blacklisted_deal_ids: campaignData?.blacklisted_deal_ids,
  };

  return (
    <>
      <PageHeaderWithBackButton title={`View Campaign`} />
      <Form
        initialValues={initialValues}
        validateOnMount
        validateOnChange
        validateOnSchemaChange
      >
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Select
              name="prize_type"
              label="* Prize Type"
              placeholder=""
              disabled
            />
          </Col>
          <Col span={24}>
            <Input
              name="campaign_name"
              label="* Campaign Name"
              placeholder=""
              bordered
              disabled
              style={{ marginBottom: 0 }}
            />
          </Col>
          <Col span={24}>
            <Input
              name="prize_name"
              label="* Prize Name"
              placeholder=""
              bordered
              disabled
              style={{ marginBottom: 0 }}
            />
          </Col>
          <Col span={24}>
            <Input
              name="prize_subtitle"
              label="Prize Subtitle"
              placeholder=""
              bordered
              disabled
              style={{ marginBottom: 0 }}
            />
          </Col>
          <Col span={24}>
            <Input
              type="textArea"
              name="prize_description"
              label="* Prize Description"
              placeholder=""
              bordered
              disabled
              style={{ marginBottom: 0 }}
            />
          </Col>
          {campaignData?.prize_type === 'lucky_draw' && (
            <>
              <Col span={12}>
                <Input
                  name="start_date"
                  label="* Start Date"
                  placeholder=""
                  bordered
                  disabled
                  style={{ marginBottom: 0 }}
                />
              </Col>
              <Col span={12}>
                <Input
                  name="end_date"
                  label="* End Date"
                  placeholder=""
                  bordered
                  disabled
                  style={{ marginBottom: 0 }}
                />
              </Col>
              <Col span={24}>
                <Input
                  name="max_user_limit"
                  label="Max Entries per User"
                  placeholder=""
                  bordered
                  disabled
                  style={{ marginBottom: 0 }}
                />
              </Col>
              <Col span={24}>
                <Input
                  name="cost_of_token"
                  label="Price per entry (tokens)"
                  placeholder=""
                  bordered
                  disabled
                  style={{ marginBottom: 0 }}
                />
              </Col>
              <Col span={24}>
                <Input
                  name="max_campaign_limit"
                  label="Max entries (campaign total)"
                  placeholder=""
                  bordered
                  disabled
                  style={{ marginBottom: 0 }}
                />
              </Col>
            </>
          )}
          <Col span={12}>
            <p className={style.label}>* Asset</p>
            <Image src={campaignData?.asset_url} alt="Background Image" />
          </Col>
          <Col span={12}>
            <Input
              name="is_highlighted"
              label="Highlight the Prize"
              type="checkbox"
              checked={campaignData?.is_highlighted}
              disabled
            />
          </Col>
          {campaignData?.prize_type === 'promo_code' && (
            <>
              <Col span={24}>
                <Select
                  name="product_types"
                  label="* Apply to"
                  placeholder=""
                  disabled
                  mode="tags"
                />
              </Col>
              <Col span={24}>
                <Input
                  name="cost_of_token"
                  label="Price of promo code (tokens)"
                  placeholder=""
                  bordered
                  disabled
                  style={{ marginBottom: 0 }}
                />
              </Col>
              <Col span={6}>
                <Input
                  name="promo_code_type"
                  label="Promo code value"
                  placeholder=""
                  bordered
                  disabled
                  style={{ marginBottom: 0 }}
                />
              </Col>
              <Col span={6}>
                <Input
                  name="promo_code_value_type"
                  label="Value Type"
                  placeholder=""
                  bordered
                  disabled
                  style={{ marginBottom: 0 }}
                />
              </Col>
              {campaignData?.amount_value_cents && (
                <Col span={6}>
                  <Input
                    name="amount_value_cents"
                    label={`Amount (${currencyData?.currency})`}
                    placeholder=""
                    bordered
                    disabled
                    style={{ marginBottom: 0 }}
                  />
                </Col>
              )}
              {campaignData?.percentage_value && (
                <>
                  <Col span={6}>
                    <Input
                      name="percentage_value"
                      label="Amount percentage"
                      placeholder=""
                      bordered
                      disabled
                      style={{ marginBottom: 0 }}
                    />
                  </Col>
                  <Col span={6}>
                    <Input
                      name="max_cap_amount_cents"
                      label="Max Cap"
                      placeholder=""
                      bordered
                      disabled
                      style={{ marginBottom: 0 }}
                    />
                  </Col>
                </>
              )}
              {campaignData?.cashback_type && (
                <Col span={6}>
                  <Input
                    name="cashback_type"
                    label="* Cashback Type"
                    placeholder=""
                    bordered
                    disabled
                    style={{ marginBottom: 0 }}
                  />
                </Col>
              )}
              <Col span={24}>
                <Input
                  name="promo_code_prefix"
                  label="* Promo Code Prefix (code)"
                  placeholder=""
                  bordered
                  disabled
                  style={{ marginBottom: 0 }}
                />
              </Col>
              <Col span={24}>
                <Input
                  name="validity_day"
                  label="* Validity Period"
                  placeholder=""
                  bordered
                  disabled
                  style={{ marginBottom: 0 }}
                />
              </Col>
              <Col span={24}>
                <Input
                  name="max_campaign_limit"
                  label="Number of Promos (optional)"
                  placeholder=""
                  bordered
                  disabled
                  style={{ marginBottom: 0 }}
                />
              </Col>
              <Col span={24}>
                <Input
                  name="max_user_limit"
                  label="User purchase limit (optional)"
                  placeholder=""
                  bordered
                  disabled
                  style={{ marginBottom: 0 }}
                />
              </Col>
              <Col span={24}>
                <Input
                  name="min_purchase_amount_cents"
                  label={`Minimum (${currencyData?.currency}) purchase (optional)`}
                  placeholder=""
                  bordered
                  disabled
                  style={{ marginBottom: 0 }}
                />
              </Col>
              <Col span={12}>
                <Select
                  name="whitelisted_outlet_ids"
                  label="Outlets Whitelisted ID (optional)"
                  placeholder=""
                  disabled
                  mode="tags"
                />
              </Col>
              <Col span={12}>
                <Select
                  name="blacklisted_outlet_ids"
                  label="Outlets Blacklisted ID (optional)"
                  placeholder=""
                  disabled
                  mode="tags"
                />
              </Col>
              <Col span={12}>
                <Select
                  name="whitelisted_deal_ids"
                  label="Deals whitelisted ID (optional)"
                  placeholder=""
                  disabled
                  mode="tags"
                />
              </Col>
              <Col span={12}>
                <Select
                  name="blacklisted_deal_ids"
                  label="Deals Blacklisted ID (optional)"
                  placeholder=""
                  disabled
                  mode="tags"
                />
              </Col>
              <Col span={12}>
                <Select
                  name="whitelisted_e_card_ids"
                  label="eCards Whitelisted ID (optional)"
                  placeholder=""
                  disabled
                  mode="tags"
                />
              </Col>
              <Col span={12}>
                <Select
                  name="blacklisted_e_card_ids"
                  label="eCards Blacklisted ID (optional)"
                  placeholder=""
                  disabled
                  mode="tags"
                />
              </Col>
            </>
          )}
        </Row>
      </Form>
    </>
  );
}
