import { Message, ModalProps } from 'fave-ui';

export type IPPModalProps = ModalProps & {
  isApproveModal?: boolean;
  merchantID?: number;
};

export type IPPFormProps = IPPModalProps & {
  onCancel: () => void;
  refetch: () => void;
};

export const handleIPPApprovalStatusUpdated = (
  refetch: () => void,
  // true if approve, false if reject
  // undefined if in_progress or pending
  isApproveOrReject?: boolean,
) => {
  refetch();
  if (isApproveOrReject !== undefined)
    Message.success({
      content: `Successfully ${
        isApproveOrReject ? 'approved' : 'rejected'
      } Instalment Payment Plan`,
    });
  else
    Message.success({
      content: `Successfully updated Instalment Payment Plan`,
    });
};
