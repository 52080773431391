import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { ColumnsType } from 'antd/lib/table';
import { RowSelectionType } from 'antd/lib/table/interface';
import { Button, Dropdown, FilterIcon, Menu, Table } from 'fave-ui';
import { Plus } from 'phosphor-react';
import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { FilterDropdown } from '../../components/common/FilterDropdown';
import { PageHeaderWithSpace } from '../../components/common/PageHeaderWithSpace/PageHeaderWithSpace';
import { RenderColumn } from '../../helpers/tableHelpers/RenderColumn';
import { defaultTableConfig } from '../../helpers/defaults';
import {
  FetchNatureOfBusinessDataResponse,
  useFetchNatureOfBusiness,
  mutateNatureOfBusiness,
} from '../../services/NatureOfBusiness/useFetchNatureOfBusiness';
import { TableSettingsType } from '../../types/configTypes';
import { EnabledStatusType } from '../../types/dataTypes';
import {
  calcTableHeight,
  calcTableWidth,
  createOnTableChangeHandler,
  formatFilter,
} from '../../utils/utilFunctions';
import style from './style.module.css';

type NatureOfBusinessViewProps = {};

const tableHeight = calcTableHeight();
const tableWidth = calcTableWidth();

export const NatureOfBusinessView: React.FC<NatureOfBusinessViewProps> = () => {
  const [tableConfig, setTableConfig] =
    useState<TableSettingsType>(defaultTableConfig);
  const [selectedRows, setSelectedRows] = useState<
    FetchNatureOfBusinessDataResponse[]
  >([]);
  const selectedRowKeys = useMemo(
    () => selectedRows.map(item => item.id),
    [selectedRows],
  );
  const isBulkActionsDisabled = selectedRowKeys.length === 0;
  const [keyword, setKeyword] = useState('');
  const {
    data: natureOfBusinessData,
    isFetching,
    refetch,
  } = useFetchNatureOfBusiness({
    page: tableConfig.page,
    sort_order: tableConfig.sort_order,
    sort_by: tableConfig.sort_by,
    filter: `name=${keyword}|${tableConfig.filter ?? ''}`,
  });
  const navigate = useNavigate();

  const massUpdate = (status: EnabledStatusType) => {
    const results = selectedRows.map(row => {
      return {
        ...row,
        status,
      };
    });

    const onSuccess = () => {
      setSelectedRows([]);
      refetch();
    };

    mutateNatureOfBusiness(results, onSuccess);
  };

  const tableChangeHandler = createOnTableChangeHandler(
    setTableConfig,
    filters => formatFilter([{ key: 'status', value: filters.status }]),
  );

  const menuItems = (id: number): ItemType[] => [
    {
      key: '1',
      label: 'Edit',
      onClick: () => navigate(`/settings/nature_of_business/${id}`),
    },
  ];

  const natureOfBusinessColumns: ColumnsType<FetchNatureOfBusinessDataResponse> =
    [
      {
        title: 'ID',
        dataIndex: 'id',
        width: 50,
        render: RenderColumn.rowText,
      },
      {
        title: 'Name',
        dataIndex: 'name',
        filterDropdown: FilterDropdown(
          setTableConfig,
          'Search Nature of Business',
          setKeyword,
        ),
        filterIcon: FilterIcon,
        width: 200,
        render: RenderColumn.rowText,
      },
      {
        title: 'Status',
        dataIndex: 'status',
        filterIcon: FilterIcon,
        filters: [
          { text: `Enabled`, value: 'enabled' },
          { text: `Disabled`, value: 'disabled' },
        ],
        width: 75,
        render: RenderColumn.onOffStatus,
      },
      {
        title: 'Actions',
        key: 'action',
        width: 75,
        render: ({ id }: FetchNatureOfBusinessDataResponse) =>
          RenderColumn.dropdownMenu(menuItems(id)),
        fixed: 'right',
      },
    ];

  const handleSelectedRows = (
    _: React.Key[],
    newSelectedRows?: FetchNatureOfBusinessDataResponse[],
  ) =>
    setSelectedRows(
      newSelectedRows as unknown as FetchNatureOfBusinessDataResponse[],
    );

  const updateStatusesMenuItems: ItemType[] = [
    {
      key: '1',
      label: 'Mass enable',
      onClick: () => massUpdate('enabled'),
    },
    {
      key: '2',
      label: 'Mass disable',
      onClick: () => massUpdate('disabled'),
    },
  ];

  const rowSelection = {
    type: 'checkbox' as RowSelectionType,
    onChange: handleSelectedRows,
    selectedRowKeys: selectedRowKeys,
    preserveSelectedRowKeys: true,
  };

  return (
    <div className={style.natureOfBusinessView}>
      <PageHeaderWithSpace
        title={'Nature of Business'}
        additionalContent={
          <div className={style.buttonGroup}>
            <Dropdown
              overlay={<Menu items={updateStatusesMenuItems} />}
              trigger={['click']}
              disabled={isBulkActionsDisabled}
            >
              <Button size={'large'} type={'primary'}>
                Bulk Actions{' '}
                {!isBulkActionsDisabled && `(${selectedRowKeys.length})`}
              </Button>
            </Dropdown>
            <Button
              size={'large'}
              type={'primary'}
              icon={<Plus />}
              shape={'circle'}
              onClick={() => navigate('/settings/nature_of_business/new')}
            />
          </div>
        }
      />
      <Table
        rowKey={row => row.id}
        dataSource={natureOfBusinessData?.nature_of_businesses}
        columns={natureOfBusinessColumns}
        rowSelection={rowSelection}
        onChange={tableChangeHandler}
        loading={isFetching}
        scroll={{
          x: tableWidth,
          y: tableHeight,
        }}
        pagination={{
          total: natureOfBusinessData?.count,
          position: ['bottomCenter'],
          pageSize: tableConfig.pageSize,
          current: tableConfig.page,
          showSizeChanger: false,
        }}
      />
    </div>
  );
};
