import { createContext, PropsWithChildren } from 'react';
import cookieSet from '../helpers/cookieSet';
import { isProduction, localhostEndpoint } from '../helpers/env';

type EnvironmentContextType = {
  currentStaging: string;
  isProduction: boolean;
  stagingEnironments: { key: string; label: string }[];
  switchStagingServer: (stagingName: string) => void;
};

const currentStaging = cookieSet.currentStagingName.get();

const stagingList = Object.keys(process.env)
  .filter(key => key.includes('_STAGING_API'))
  .map(key =>
    key.replace('REACT_APP_', '').replace('_STAGING_API', '').toLowerCase(),
  );

const stagingEnironments = stagingList.map(item => {
  return { key: item, label: `${item}-staging` };
});

if (localhostEndpoint)
  stagingEnironments.push({
    key: 'localhost',
    label: `localhost`,
  });

const defaultValue: EnvironmentContextType = {
  currentStaging,
  isProduction,
  stagingEnironments,
  switchStagingServer: stagingName => {
    const exists = stagingEnironments.some(env => env.key === stagingName);

    if (exists) {
      cookieSet.currentStagingName.set(stagingName);
      window.location.reload();
    } else throw 'Invalid staging server';
  },
};

export const EnvironmentContext =
  createContext<EnvironmentContextType>(defaultValue);
EnvironmentContext.displayName = 'EnvironmentContext';

export const EnvironmentContextProvider = ({
  children,
}: PropsWithChildren<{}>) => (
  <EnvironmentContext.Provider value={defaultValue}>
    {children}
  </EnvironmentContext.Provider>
);
