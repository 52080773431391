import { useEffect, useRef } from 'react';

const useClickOutside = (callback: (event: MouseEvent) => void) => {
  const ref = useRef<HTMLElement | null>(null);

  const clickOutsideRef = (element: HTMLElement | null) =>
    (ref.current = element);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node))
        callback(event);
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return { clickOutsideRef };
};

export default useClickOutside;
