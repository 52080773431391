import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { TableConfigType } from '../../types/configTypes';
import { EnabledStatusType } from '../../types/dataTypes';
import http, { createFetchAPI } from '../http';

const endpoint = '/v1/nature_of_businesses';

type NatureOfBusinessResponseType = {
  nature_of_businesses: FetchNatureOfBusinessDataResponse[];
  count: number;
};

export type UpdateOrCreateNatureOfBusinessError = { error: string };

export type FetchNatureOfBusinessDataResponse = {
  id: number;
  name: string;
  status: EnabledStatusType;
  created_at?: string;
};

const fetchNatureOfBusiness = createFetchAPI<
  TableConfigType,
  NatureOfBusinessResponseType
>(endpoint);

const fetchNatureOfBusinessData = (id?: number | string) =>
  createFetchAPI<TableConfigType, FetchNatureOfBusinessDataResponse>(
    `${endpoint}/${id}`,
  );

export const useFetchNatureOfBusiness = (props: TableConfigType) =>
  useQuery<NatureOfBusinessResponseType>(
    ['fetchNatureOfBusiness', { ...props }],
    fetchNatureOfBusiness,
    { staleTime: 0 },
  );

export const mutateNatureOfBusiness = (
  params:
    | FetchNatureOfBusinessDataResponse
    | FetchNatureOfBusinessDataResponse[],
  onSuccess = () => {},
  onError?: (err: AxiosError<UpdateOrCreateNatureOfBusinessError>) => void,
) =>
  Array.isArray(params)
    ? http
        .put(endpoint, {
          nature_of_businesses: params,
        })
        .then(onSuccess)
        .catch(onError)
    : http.post(endpoint, params).then(onSuccess).catch(onError);

export const useFetchNatureOfBusinessData = (
  { id }: { id: number | string },
  enabled = true,
) =>
  useQuery<FetchNatureOfBusinessDataResponse>(
    ['fetchNatureOfBusinessData', id],
    fetchNatureOfBusinessData(id),
    { enabled },
  );
