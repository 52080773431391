import Cookies from 'universal-cookie';
import root from 'window-or-global';

class Cookie {
  instance: Cookies;
  static DEFAULT_OPTIONS = {
    path: '/',
    secure: root.location.protocol === 'https:',
    domain: process.env.REACT_APP_COOKIE_DOMAIN || '',
  };

  constructor() {
    this.instance = new Cookies();
  }

  set(key: string, value: string, options = {}) {
    return this.instance.set(key, value, {
      ...Cookie.DEFAULT_OPTIONS,
      ...options,
    });
  }

  get(key: string) {
    const value = this.instance.get(key);

    if (!value) return value;

    switch (value) {
      case 'true':
        return true;
      case 'false':
        return false;
      default:
        return value;
    }
  }

  getOrDefault(key: string, defaultValue: string) {
    return this.get(key) || defaultValue;
  }

  remove(key: string, options = {}) {
    return this.instance.remove(key, { ...Cookie.DEFAULT_OPTIONS, ...options });
  }
}

export default new Cookie();
