import { CompanyType } from '../../../types/dataTypes';

export const getPath = (value: string) =>
  `/admin/merchant_connect/merchants${value}`;

export type AddProductOptionsType = {
  label: string;
  key: string;
  value: string;
  redirect: boolean;
  show: true;
};

// part of Merchant Connect refactor
export const getNewProductOptions = (
  company: CompanyType,
  hasIPPOnboarding = false,
  flipperPaymentModes = false,
) => {
  const { id, merchant_type, has_draft_ipp, has_draft_payment_mode } = company;
  const fastPayOptions = [
    // fastpay
    {
      label: 'FastPay / Payment Mode(s)',
      key: '1',
      value: `${
        id
          ? `/${id}/favepay${has_draft_payment_mode ? '' : '/new'}`
          : '/add_fastpay'
      }`,
      redirect: true,
      show: true,
    },
    {
      label: 'Instalment payment plan (IPP)',
      key: '2',
      value: `/admin/merchant_connect/my_merchant/merchants/${id}/ipp/${
        has_draft_ipp ? 'edit' : 'new'
      }`,
      redirect: false,
      show: hasIPPOnboarding,
    },
  ].filter(item => item.show);

  const faveOptions = [
    //fave
    {
      label: 'FavePay / Alipay',
      key: '1',
      value: `${id ? `/${id}/favepay/new` : '/add_favepay'}`,
      redirect: true,
    },
    {
      label: 'Payment Mode(s)',
      key: '2',
      value: `/admin/merchant_connect/merchants/${id}/payment_mode/resubmit`,
      redirect: false,
    },
    {
      label: 'Deals',
      key: '3',
      value: `${id ? `/${id}/deals/new` : '/add_deal'}`,
      redirect: true,
    },
    {
      label: 'eCards (FavePay required)',
      key: '4',
      value: `${id ? `/${id}/ecards/new` : '/add_ecard'}`,
      redirect: true,
    },
    {
      label: 'FavePay Later (FavePay required)',
      key: '5',
      value: `${id ? `/${id}/favepay_later/new` : '/add_favepay_later'}`,
      redirect: true,
    },
  ];

  if (!flipperPaymentModes) faveOptions.splice(1, 1); // Remove payment mode option when flipper off

  return merchant_type === 'fastpay' ? fastPayOptions : faveOptions;
};
