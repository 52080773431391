import { useQuery } from 'react-query';
import {
  PaginatedPayloadBaseType,
  PayloadBaseType,
} from '../../types/configTypes';
import { StatusType } from '../../types/dataTypes';

import { createFetchAPI } from '../http';

type FavePaySummaryPayloadType = PayloadBaseType;

type FavePaySummaryResponseType = {
  total_outlets: number;
  live_outlets: number;
  inactive_outlets: number;
};

type FetchFavePaySummaryType = {
  company_id: number;
};

type FavePayOutletsPayloadType = PaginatedPayloadBaseType;

type FavePayOutletsResponseType = {
  favepays: FavepayType[];
  count: number;
};

export type FavepayType = {
  id: number;
  signature_date: string;
  bd_name: string;
  cashback_rate: number;
  fave_fees: number;
  alipay_fees: number | null;
  fee_effective_date: string;
  outlet: OutletType;
  //company: CompanyType; // missing from response but exists under "outlet"
};

type OutletType = {
  id: number;
  name: string;
  favepay_status: StatusType;
  bank_account: BankAccountType;
  company: CompanyType;
  pos_vendor: {
    id: number;
    name: string;
  };
};

type BankAccountType = {
  bank_name: string;
  account_number: string;
};

type CompanyType = {
  id: number;
  name: string;
  company_credits_expiration_time: number;
};

export const endpoint = 'v1/favepays';

const fetchFavepaySummary = createFetchAPI<
  FavePaySummaryPayloadType,
  FavePaySummaryResponseType
>(`${endpoint}/product_summary`);

const fetchFavePayData = createFetchAPI<
  FavePayOutletsPayloadType,
  FavePayOutletsResponseType
>(endpoint);

export const useFetchFavePaySummary = ({
  company_id,
}: FetchFavePaySummaryType) => {
  return useQuery<FavePaySummaryResponseType>(
    ['fetchFavepaySummary', { company_id }],
    fetchFavepaySummary,
  );
};

export const useFetchFavePayData = (props: FavePayOutletsPayloadType) =>
  useQuery<FavePayOutletsResponseType>(
    ['fetchFavePayData', { ...props }],
    fetchFavePayData,
  );
