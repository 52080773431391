import style from './style.module.css';
import { useParams } from 'react-router';
import { Card, Col, Statistic } from 'antd';
import { Row } from 'fave-ui';
import ChangeHistoryTable from '../../../../components/ChangeHistoryTable';
import { useFetchCashbackCampaignListingData } from '../../../../services/CashbackCampaign/useFetchCashbackCampaignListing';
import { PageHeaderWithBackButton } from '../../../../components/common';

const CashbackCampaignChangeLogView = () => {
  const { id } = useParams();
  const parsedID = id ? parseInt(id) : undefined;
  const { data: cashbackCampaignData, isLoading: isCampaignLoading } =
    useFetchCashbackCampaignListingData({ id: id });

  return (
    <>
      <PageHeaderWithBackButton
        title={'Change Log'}
        subTitle={
          'Track and manage current, past and future cashback campaigns'
        }
      />
      <Card className={style.card}>
        <Row>
          <h2 className={style.h2}>{'Change Log Summary'}</h2>
        </Row>
        <Row className={style.summaryWrapper}>
          <Col>
            <Row className={style.statsRow}>
              <Statistic
                loading={isCampaignLoading}
                title="ID"
                value={cashbackCampaignData?.id}
                className={style.statItem}
              />
            </Row>
          </Col>
          <Col className={style.statsCol}>
            <Row className={style.statsRow}>
              <Statistic
                loading={isCampaignLoading}
                title="Campaign"
                value={cashbackCampaignData?.campaign_type}
                className={style.statItem}
              />
            </Row>
          </Col>
          <Col className={style.statsCol}>
            <Row className={style.statsRow}>
              <Statistic
                loading={isCampaignLoading}
                title={
                  cashbackCampaignData?.whitelisted_company_ids
                    ? 'Whitelisted Merchant IDs'
                    : 'Whitelisted Outlet IDs'
                }
                value={
                  cashbackCampaignData?.whitelisted_company_ids
                    ? cashbackCampaignData?.whitelisted_company_ids?.join(', ')
                    : cashbackCampaignData?.whitelisted_outlet_ids?.join(', ')
                }
                className={style.statItem}
                groupSeparator=""
              />
            </Row>
          </Col>
        </Row>
      </Card>
      <Row className={style.table}>
        {parsedID && (
          <ChangeHistoryTable
            item_type={'CashbackCampaign'}
            item_id={parsedID}
          />
        )}
      </Row>
    </>
  );
};

export default CashbackCampaignChangeLogView;
