const IconCompanyLogo = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="13"
    viewBox="0 0 16 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.54004 3.59998H14.06V11.64C14.06 11.9713 13.7914 12.24 13.46 12.24H3.14004C2.80867 12.24 2.54004 11.9713 2.54004 11.64V3.59998Z"
      fill="#FBBF24"
    />
    <path
      d="M14.06 5.75995C14.8553 5.75995 15.5 5.11524 15.5 4.31995H12.62C12.62 5.11524 13.2647 5.75995 14.06 5.75995Z"
      fill="#BE185D"
    />
    <path
      d="M8.29999 5.75995C9.09528 5.75995 9.73999 5.11524 9.73999 4.31995H6.85999C6.85999 5.11524 7.5047 5.75995 8.29999 5.75995Z"
      fill="#BE185D"
    />
    <path
      d="M11.18 5.75995C11.9753 5.75995 12.62 5.11524 12.62 4.31995H9.73999C9.73999 5.11524 10.3847 5.75995 11.18 5.75995Z"
      fill="#F1F5F9"
    />
    <path
      d="M2.53998 5.75995C3.33527 5.75995 3.97998 5.11524 3.97998 4.31995H1.09998C1.09998 5.11524 1.74469 5.75995 2.53998 5.75995Z"
      fill="#BE185D"
    />
    <path
      d="M5.41998 5.75995C6.21527 5.75995 6.85998 5.11524 6.85998 4.31995H3.97998C3.97998 5.11524 4.62469 5.75995 5.41998 5.75995Z"
      fill="#F1F5F9"
    />
    <path
      d="M1.09998 4.31994H15.5L14.06 1.43994H2.53998L1.09998 4.31994Z"
      fill="#FBBF24"
    />
    <path
      d="M2.54004 0.3C2.54004 0.134315 2.67435 0 2.84004 0H13.76C13.9257 0 14.06 0.134315 14.06 0.3V1.44H2.54004V0.3Z"
      fill="#334155"
    />
    <path
      d="M4.70007 7.80001C4.70007 7.46864 4.9687 7.20001 5.30007 7.20001H6.98007C7.31144 7.20001 7.58007 7.46864 7.58007 7.80001V12.24H4.70007V7.80001Z"
      fill="white"
    />
    <rect
      x="9.02002"
      y="7.20001"
      width="2.88"
      height="2.88"
      rx="0.6"
      fill="white"
    />
    <path d="M12.62 4.32H15.5L14.06 1.44H11.54L12.62 4.32Z" fill="#DE206A" />
    <path
      d="M3.97998 4.31994H1.09998L2.53998 1.43994H5.05998L3.97998 4.31994Z"
      fill="#DE206A"
    />
    <path
      d="M3.97998 4.32L5.05998 1.44H7.21998L6.85998 4.32H3.97998Z"
      fill="white"
    />
    <path d="M11.54 1.44L12.62 4.32H9.74L9.38 1.44H11.54Z" fill="white" />
    <path
      d="M9.73999 4.32H6.85999L7.21999 1.44H9.37999L9.73999 4.32Z"
      fill="#DE206A"
    />
    <path
      d="M2.51316 10.4729C2.31628 9.68392 2.11782 7.98183 2.89902 7.48518C3.87552 6.86435 4.25923 8.90574 2.51316 10.4729Z"
      fill="#059669"
    />
    <path
      d="M2.74361 10.924C2.05879 10.4856 0.745903 9.38428 0.972906 8.48683C1.25666 7.36502 2.93718 8.58582 2.74361 10.924Z"
      fill="#10B981"
    />
    <path
      d="M3.58577 10.2857H1.27148L1.6572 12.6H3.20006L3.58577 10.2857Z"
      fill="#334155"
    />
  </svg>
);

export default IconCompanyLogo;
