import { useState } from 'react';
import style from './style.module.css';
import {
  ColumnsType,
  ColumnType,
  SortOrder,
  TablePaginationConfig,
} from 'antd/lib/table/interface';
import {
  Button,
  Dropdown,
  Empty,
  FilterIcon,
  HeaderWithSort,
  Menu,
  ModalFunctions,
  Row,
  Select,
  Table,
  Tag,
} from 'fave-ui';
import { Plus } from 'phosphor-react';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { format, parseISO } from 'date-fns';

import useCleverTap from '../../hooks/useCleverTap';
import useOnceCallback from '../../hooks/useOnceCallback';
import {
  CLEVERTAP_ADDITIONAL,
  CLEVERTAP_SCREEN_NAME,
  CLEVERTAP_SECTION_NAME,
  CLEVERTAP_TAPPED_ON,
} from '../../helpers/ClevertapKeys';
import { useNavigate } from 'react-router';
import {
  FetchMerchantType,
  useFetchFlipper,
  useFetchMerchants,
} from '../../services/merchants';
import { createSearchParams } from 'react-router-dom';
import {
  calcTableHeight,
  calcTableWidth,
  createOnTableChangeHandler,
  formatFilter,
} from '../../utils/utilFunctions';
import {
  CompanyType,
  MerchantType,
  OutletType,
  UserRole,
  UserType,
} from '../../types/dataTypes';
import { toolRedirect } from '../../services/http';
import { PageHeaderWithSpace } from '../../components/common/PageHeaderWithSpace/PageHeaderWithSpace';
import { MerchantAvatar } from '../../components/MerchantAvatar/MerchantAvatar';
import { RenderColumn } from '../../helpers/tableHelpers/RenderColumn';
import { FilterDropdown } from '../../components/common/FilterDropdown';
import { useUserContext } from '../../contexts/UserContext';
import { SearchBarExpandable } from '../../components/common/SearchBarExpandable/SearchBarExpandable';
import { useTenantContext } from '../../contexts/TenantContext';
import {
  AddProductOptionsType,
  getPath,
  getNewProductOptions,
} from './helpers';
import { ContractStatus } from '../../helpers/TypeConstant';
import IconCompanyLogo from '../../assests/icons/IconCompanyLogo';

const tableHeight = calcTableHeight();
const tableWidth = calcTableWidth();
const tableConfigDefault = {
  current: 1,
  pageSize: 10,
  sort_order: undefined,
};

const addNewMerchantRedirect = (type: MerchantType) =>
  toolRedirect(
    `/admin/merchant_connect/merchants/new`,
    `merchant_type=${type}`,
  );

export const getValidActionItems = (
  items: ItemType[],
  user: UserType,
  company: CompanyType,
) => {
  const merchantType =
    company.merchant_type !== 'fastpay' ? 'fave' : company.merchant_type; // null should be remove after API rake task to replace all null into 'fave'

  const validActionKeys = {
    fave: [
      'view_merchant',
      'product_dashboard',
      'generate_master_contract',
      'add_new_product',
    ],
    fastpay: ['view_merchant', 'product_dashboard', 'add_new_product'],
  };

  const validActionItems = items.filter(item => {
    const valid = validActionKeys[merchantType].includes(item?.key as string);

    if (merchantType === 'fave') {
      if (item?.key === 'generate_master_contract') {
        const allowedRoles: UserRole[] = [UserRole.SuperAdmin];
        const allowedContractStatus: ContractStatus[] = [
          ContractStatus.NotSent,
          ContractStatus.Cancelled,
        ];

        return (
          valid &&
          (allowedRoles.some(role => role === user.role) ||
            (!company.ongoing_contract_migration &&
              allowedContractStatus.some(
                status => status === company.contract_status,
              )))
        );
      }
    }

    return valid;
  });

  return validActionItems;
};

const MyMerchantView = () => {
  const [tableConfig, setTableConfig] = useState<
    TablePaginationConfig & FetchMerchantType
  >(tableConfigDefault);

  const cleverTap = useCleverTap();
  const navigate = useNavigate();

  const { user } = useUserContext();

  const { data, isFetching, isLoading } = useFetchMerchants({
    currentPage: tableConfig.current,
    limit: tableConfig.pageSize,
    sort_order: tableConfig.sort_order,
    filter: `assigned_to=my|status=approved|${tableConfig.filter || ''}`,
    dataset: 'outlets',
  });

  const { isID, selectedTenant } = useTenantContext();

  const { data: flipper } = useFetchFlipper({
    key: `fave_payment_mode_enabled_${selectedTenant}`,
  });

  const handleAction = (url: string) => toolRedirect(url);

  useOnceCallback(() =>
    cleverTap.screenDisplayed.push({
      screen_name: CLEVERTAP_SCREEN_NAME.MY_MERCHANT,
      platform: CLEVERTAP_ADDITIONAL.MERCHANT_CONNECT,
    }),
  );

  const { getPageSettings } = useUserContext();

  const pageSettings = getPageSettings('merchant_connect');

  const enableFastPay = !!pageSettings?.fastpay_onboarding;
  const enableIPPOnboarding = !!pageSettings?.ipp_onboarding;

  const showNewProductModal = (company: CompanyType) => {
    const newProductOptions = getNewProductOptions(
      company,
      enableIPPOnboarding,
      flipper?.value,
    );

    const defaultPath = getPath(newProductOptions[0].value);

    let path: { value: string; redirect: boolean } = {
      value: defaultPath,
      redirect: true,
    };

    const handleSelect = (value: string, option: AddProductOptionsType) => {
      path = {
        value: option.redirect ? getPath(value) : value,
        redirect: option.redirect,
      };
    };

    const handleAction = (url: string) => {
      path.redirect ? toolRedirect(url) : navigate(url);
    };

    const modalContent = (
      <div>
        <span>
          Please select the product you would like to add
          {company ? ` to ${company.name}.` : '.'}
        </span>
        <Select
          className={style.addNewProduclModal}
          onChange={(value, option) =>
            handleSelect(value, option as AddProductOptionsType)
          }
          placeholder="Select a product"
          showArrow
          options={newProductOptions}
          defaultValue={newProductOptions[0]}
        />
      </div>
    );

    ModalFunctions.confirm({
      title: 'Select a product',
      content: modalContent,
      okText: 'Confirm',
      cancelText: 'Cancel',
      confirmMode: false,
      okButtonProps: {
        // Modal is not react component, hence it cannot change value dynamically
        disabled: false,
      },
      hasIcon: false,
      centered: true,
      icon: null,
      onOk: () => handleAction(path.value),
    });
  };

  const dataColumns: ColumnsType<CompanyType> = [
    {
      title: ({
        sortColumns,
      }: {
        sortColumns: {
          column: ColumnType<{}>;
          order: SortOrder;
        }[];
      }) => (
        <HeaderWithSort sortColumns={sortColumns} title={'Merchant Name'} />
      ),
      dataIndex: 'name',
      width: 210,
      sorter: true,
      filterDropdown: FilterDropdown(setTableConfig, 'Search merchants'),
      filterIcon: FilterIcon,
      render: (name: string, company: CompanyType) => {
        return (
          <div className={style.merchantColumn}>
            <MerchantAvatar
              logo={company.logo != null ? company.logo : <IconCompanyLogo />}
            />
            {name}
          </div>
        );
      },
      filterSearch: true,
    },
    {
      title: 'Status',
      dataIndex: 'flagged_reason',
      width: 150,
      render: RenderColumn.merchantStatus,
    },
    {
      title: 'Outlets',
      dataIndex: 'outlets',
      width: 90,
      render: (outlets: OutletType[]) => <>{outlets.length}</>,
    },
    {
      title: 'Date Created',
      dataIndex: 'created_at',
      width: 208,
      ellipsis: {
        showTitle: false,
      },
      render: (date: string) =>
        date ? format(parseISO(date), 'd MMM yyyy') : '-',
    },
    {
      title: 'Master Contract',
      dataIndex: 'contract_status',
      width: 176,
      render: (_, { contract_status }: { contract_status: string }) => (
        <>
          <Tag
            color={
              contract_status === 'Signed'
                ? 'success'
                : contract_status === 'Not sent'
                ? 'error'
                : 'default'
            }
          >
            {contract_status}
          </Tag>
        </>
      ),
    },
    {
      title: 'Products',
      dataIndex: 'products',
      width: 136,
      render: RenderColumn.products,
    },
    {
      title: 'Actions',
      key: 'action',
      width: 124,
      render: (company: CompanyType) => {
        const id = company.id;

        const onClickAddNewProductToMerchant = () => {
          cleverTap.tapped.push({
            screen_name: CLEVERTAP_SCREEN_NAME.MY_MERCHANT,
            section_name: CLEVERTAP_SECTION_NAME.ACTIONS_MENU,
            tapped_on: CLEVERTAP_TAPPED_ON.BUTTON_ADD_NEW_PRODUCT,
            platform: CLEVERTAP_ADDITIONAL.MERCHANT_CONNECT,
          });

          showNewProductModal(company);
        };

        const onClickViewMerchant = () =>
          handleAction(`${window.location.pathname}/${id}/outlets?`);

        const onClickProductDashboard = (merchantId: number) => {
          cleverTap.tapped.push({
            screen_name: CLEVERTAP_SCREEN_NAME.MY_MERCHANT,
            tapped_on: CLEVERTAP_TAPPED_ON.BUTTON_PRODUCT_DASHBOARD,
            section_name: CLEVERTAP_SECTION_NAME.ACTIONS_MENU,
          });

          navigate(`${merchantId}/product_dashboard`);
        };

        const onClickGenerateMasterContract = () => {
          cleverTap.tapped.push({
            screen_name: CLEVERTAP_SCREEN_NAME.MY_MERCHANT,
            tapped_on: CLEVERTAP_TAPPED_ON.BUTTON_GENERATE_MASTER_CONTRACT,
            section_name: CLEVERTAP_SECTION_NAME.ACTIONS_MENU,
            platform: CLEVERTAP_ADDITIONAL.MERCHANT_CONNECT,
          });

          handleAction(`${window.location.pathname}/${id}/contract_migration`);
        };

        const actionItems: ItemType[] = [
          {
            key: 'view_merchant',
            label: 'View Merchant',
            onClick: onClickViewMerchant,
          },
          {
            key: 'product_dashboard',
            label: 'Product Dashboard',
            onClick: () => onClickProductDashboard(id),
          },
          {
            key: 'generate_master_contract',
            label: 'Generate Master Contract',
            onClick: onClickGenerateMasterContract,
          },
          {
            key: 'add_new_product',
            label: 'Add New Product',
            onClick: onClickAddNewProductToMerchant,
          },
        ];

        const menu = (
          <Menu items={getValidActionItems(actionItems, user!, company)} />
        );

        return (
          <Dropdown overlay={menu} trigger={['hover']}>
            <Button type={'text'} onClick={e => e.preventDefault()}>
              Actions
            </Button>
          </Dropdown>
        );
      },
      fixed: 'right',
    },
  ];

  if (!isID)
    dataColumns.unshift({
      title: 'Company',
      dataIndex: 'merchant_type',
      filterIcon: FilterIcon,
      filters: [
        { text: `Fave`, value: 'fave' },
        { text: `FastPay`, value: 'fastpay' },
      ],
      width: 130,
      render: RenderColumn.merchantType,
    });

  const handleAddNewMerchant = () => {
    cleverTap.tapped.push({
      screen_name: CLEVERTAP_SCREEN_NAME.MY_MERCHANT,
      section_name: CLEVERTAP_SECTION_NAME.ADD_MENU,
      platform: CLEVERTAP_ADDITIONAL.MERCHANT_CONNECT,
      tapped_on: CLEVERTAP_TAPPED_ON.BUTTON_ADD_NEW_MERCHANT,
    });

    enableFastPay
      ? ModalFunctions.info({
          className: style.addNewMerchantModal,
          title: 'Add new merchant',
          content: <p>Which type of merchant is this?</p>,
          icon: null,
          okText: 'Fave',
          okButtonProps: {
            onClick: () => addNewMerchantRedirect('fave'),
          },
          cancelText: 'FastPay',
          cancelButtonProps: {
            onClick: () => addNewMerchantRedirect('fastpay'),
            className: style.btnFastpay,
          },
          width: 360,
          centered: true,
        })
      : addNewMerchantRedirect('fave');
  };

  const _handleSearch = (value: string) => {
    const params = { query: value };
    if (value) {
      navigate(
        `/admin/merchant_connect/my_merchant/merchants?${createSearchParams(
          params,
        )}`,
      );
    }
  };

  const handleChangeTable = createOnTableChangeHandler(
    setTableConfig,
    filters =>
      formatFilter([
        { key: 'merchant_type', value: filters.merchant_type },
        { key: 'merchant_name_or_bd_email', value: filters.name },
      ]),
  );

  return (
    <div>
      <PageHeaderWithSpace
        title={'My Merchants'}
        subTitle={'Here is the list of merchants you are working with.'}
        additionalContent={
          <div className={style.inputSearch}>
            <SearchBarExpandable onSearch={_handleSearch} />
            <Button
              size={'large'}
              type={'primary'}
              shape={'circle'}
              icon={<Plus />}
              onClick={handleAddNewMerchant}
            />
          </div>
        }
      />
      <Row>
        <Table
          size="large"
          rowKey={row => row.id}
          loading={isFetching || isLoading}
          dataSource={data?.fave_save_merchants}
          columns={dataColumns}
          scroll={{
            x: tableWidth,
            y: tableHeight,
          }}
          className={style.table}
          pagination={{
            total: data?.count,
            position: ['bottomCenter'],
            pageSize: tableConfig.pageSize,
            current: tableConfig.current,
            showSizeChanger: false,
          }}
          locale={{
            emptyText: !isFetching && (
              <Empty
                emptyType="cant-find-anything"
                style={{ height: tableHeight }}
              />
            ),
          }}
          onChange={handleChangeTable}
        />
      </Row>
    </div>
  );
};

export default MyMerchantView;
