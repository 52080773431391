import React, {
  PropsWithChildren,
  ReactNode,
  useContext,
  useMemo,
  useState,
} from 'react';
import {
  Layout,
  BottomPane,
  Content,
  Header,
  HeaderEndContent,
  LogoutButton,
  PageHeaderSubtitle,
  PageHeaderTitle,
  Sider,
  SiderHeader,
  Menu,
  Select,
  CheckeableItem,
  Option,
  ModalFunctions,
  Grid,
} from 'fave-ui';

import { UserContext } from '../../contexts/UserContext';
import { EnvironmentContext } from '../../contexts/EnvironmentContext';
import { useNavigate } from 'react-router';
import { TenantContext, TenantCodeType } from '../../contexts/TenantContext';
import { StagingDropdown } from '../StagingDropdown';
import { CleverTapContext } from '../../contexts/CleverTapContext';
import { toolRedirect } from '../../services/http';
import type { MenuInfo } from 'rc-menu/lib/interface';
import SpinOverlay from '../common/SpinOverlay/Spin';
import LockPage from '../LockPage';
import { IconType, SideNavRoutesType } from '../../types/configTypes';
import useClickOutside from '../../hooks/useClickOutside';
import { Breadcrumbs } from '../common/Breadcrumbs';
import FaveToolsLogo from '../../assests/icons/FaveToolsLogo';
import { CaretDoubleLeft, CaretDoubleRight, X } from 'phosphor-react';
import style from './style.module.css';

const caretLeft = <CaretDoubleLeft color="white" size={16} />;
const caretRight = <CaretDoubleRight color="white" size={16} />;

const logoutElementIDs = {
  btnExpandSideNav: 'btnExpandSideNav',
  btnLogout: 'btnLogout',
  btnConfirmLogout: 'btnConfirmLogout',
  btnCancelLogout: 'btnCancelLogout',
  btnCloseModal: 'btnCloseModal',
};

export const LayoutHeader = ({
  title,
  subTitle,
}: {
  title: ReactNode;
  subTitle: ReactNode;
}) => (
  <>
    <PageHeaderTitle>{title}</PageHeaderTitle>
    <PageHeaderSubtitle>{subTitle}</PageHeaderSubtitle>
  </>
);

const getMenuItems = (
  menuItems: SideNavRoutesType[],
  currentPathName: string,
  keyInfo: {
    selectedKeyPath: string[];
    screenkey: string[];
  },
): SideNavRoutesType[] => {
  const newMenuItems = menuItems.map(item => {
    const exists = currentPathName.includes(item.key);

    if (exists) {
      keyInfo.selectedKeyPath.push(item.key);
      if (item.screenkey) keyInfo.screenkey[0] = item.screenkey;
    }

    const newMenuItem: SideNavRoutesType = {
      ...item,
    };

    if (item.icon) {
      const Icon = item.icon as IconType;

      newMenuItem.icon = <Icon weight={exists ? 'fill' : 'light'} />;
    }

    if (item.children)
      newMenuItem.children = getMenuItems(
        item.children,
        currentPathName,
        keyInfo,
      );

    return newMenuItem;
  });
  return newMenuItems as unknown as SideNavRoutesType[];
};

const AppLayout = ({ children }: PropsWithChildren<{}>) => {
  const navigate = useNavigate();
  const {
    user,
    logout,
    userSettingsLoading,
    getPageSettings,
    filteredMenuItems,
  } = useContext(UserContext);
  const { isProduction } = useContext(EnvironmentContext);
  const { cleverTapProfile } = useContext(CleverTapContext);
  const { selectedTenant, switchTenant } = useContext(TenantContext);

  const [collapsed, setCollapsed] = useState(true);
  const [openKeys, setOpenKeys] = React.useState<string[]>([]);

  const { clickOutsideRef } = useClickOutside(() => setCollapsed(true));

  const { useBreakpoint } = Grid;
  const { xl: showSidebar } = useBreakpoint();
  const collapsedWidth = showSidebar ? undefined : 0;

  const currentPathName = window.location.pathname;

  const routeInfo = useMemo(() => {
    const keyInfo = {
      selectedKeyPath: [],
      screenkey: [''],
    };

    const newMenuItems = getMenuItems(
      filteredMenuItems,
      currentPathName,
      keyInfo,
    );

    return {
      newMenuItems,
      ...keyInfo,
      screenkey: keyInfo.screenkey[0],
    };
  }, [currentPathName, filteredMenuItems]);

  const pageSettings = getPageSettings(routeInfo.screenkey);

  const canShowContent = currentPathName === '/' || pageSettings?.show;

  const rootSubmenuKeys = routeInfo.newMenuItems.map(item => item.key);

  const handleMenuOpen = (keys: string[]) => {
    const latestOpenKey = keys.find(key => openKeys.indexOf(key) === -1) || '';

    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) setOpenKeys(keys);
    else setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
  };

  const handleMenuItemClick = (selectionInfo: MenuInfo) => {
    const selectedPathArray = selectionInfo.keyPath.reverse();

    let currentRouteItems = routeInfo.newMenuItems;
    let clickedNode: SideNavRoutesType | undefined;

    selectedPathArray.forEach((key, index) => {
      const node = currentRouteItems.find(item => item.key === key);

      if (node && index === selectedPathArray.length - 1) clickedNode = node;
      else currentRouteItems = node?.children as SideNavRoutesType[];
    });

    if (clickedNode?.redirect) toolRedirect(clickedNode.key);
    else {
      const path = selectedPathArray.join('');
      navigate(path);
    }
  };

  const handleLogoutClick = () => {
    ModalFunctions.confirm({
      title: 'Logout',
      content: 'Are you sure? :(',
      okText: <span data-id={logoutElementIDs.btnConfirmLogout}>Confirm</span>,
      cancelText: (
        <span data-id={logoutElementIDs.btnCancelLogout}>Cancel</span>
      ),
      closeIcon: <X size={16} data-id={logoutElementIDs.btnCloseModal} />,
      onOk: () => {
        logout();
        navigate('/login');
      },
    });
  };

  const handleSwitchTenant = (tenant: TenantCodeType) => {
    if (user) cleverTapProfile.push(user, tenant);
    switchTenant(tenant);
  };

  return (
    <Layout className="ui-app-layout" style={{ height: window.innerHeight }}>
      {userSettingsLoading ? (
        <SpinOverlay />
      ) : (
        <>
          <Sider
            trigger={null}
            collapsible
            collapsed={collapsed}
            ref={clickOutsideRef}
            collapsedWidth={collapsedWidth}
          >
            {user && (
              <SiderHeader
                text={user.name}
                collapsed={collapsed}
                setCollapsed={setCollapsed}
              />
            )}
            <Menu
              mode="inline"
              selectedKeys={routeInfo.selectedKeyPath}
              inlineIndent={16}
              openKeys={openKeys}
              onOpenChange={handleMenuOpen}
              onClick={handleMenuItemClick}
              items={routeInfo.newMenuItems}
            />
            <BottomPane>
              <LogoutButton
                data-id={logoutElementIDs.btnLogout}
                onClick={handleLogoutClick}
              />
            </BottomPane>
          </Sider>

          <Layout className={!isProduction ? 'staging' : ''}>
            <Header>
              <div className="header__logo">
                <button
                  className={style.siderButton}
                  onClick={() => setCollapsed(!collapsed)}
                  data-id={logoutElementIDs.btnLogout}
                >
                  {collapsed ? caretRight : caretLeft}
                </button>
                {/* temporarily hide logo in smaller screens */}
                <FaveToolsLogo className={'xs:hidden'} />
              </div>
              <HeaderEndContent>
                {!isProduction && <StagingDropdown disabled={true} />}

                <Select
                  style={{ paddingRight: '0.5rem' }}
                  defaultValue={selectedTenant}
                  optionLabelProp={'label'}
                  bordered={false}
                  dropdownMatchSelectWidth={false}
                  showArrow
                  onChange={handleSwitchTenant}
                >
                  {user?.tenants.map(item => (
                    <Option
                      key={item.code}
                      value={item.code}
                      label={item.label}
                    >
                      <CheckeableItem>{item.label}</CheckeableItem>
                    </Option>
                  ))}
                </Select>
              </HeaderEndContent>
            </Header>
            <Content className={style.layoutContent}>
              {canShowContent ? (
                <>
                  <Breadcrumbs />
                  {children}
                </>
              ) : (
                <LockPage />
              )}
            </Content>
          </Layout>
        </>
      )}
    </Layout>
  );
};

export default AppLayout;
