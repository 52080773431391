import { AxiosResponse } from 'axios';
import { Message } from 'fave-ui';
import { UseMutateAsyncFunction, UseMutateFunction } from 'react-query';
import { mySubmissionElementIDs } from '..';
import { toolRedirect } from '../../../services/http';
import {
  BDDealsType,
  BDPaymentDetailsType,
  ContractMigrationMCContractSubmissionsType,
  ECardsActionsType,
  MCContractSubmissionsType,
  PostDuplicateDealPayload,
  PostECardDuplicatePayload,
  ProductType,
} from '../../../services/MySubmissions';
import {
  copyToClipboard,
  downloadFileFromURL,
} from '../../../utils/utilFunctions';

// need to refactor this function
// better to create individual actionItems function based on tab
// for easier maintenance
export const actionItems = (
  data:
    | BDPaymentDetailsType
    | BDDealsType
    | ECardsActionsType
    | MCContractSubmissionsType,
  productType: Exclude<ProductType, 'Contract Migration'>,
  navigateFn: (url: string) => void,
  // only used for Deals, Ecards
  duplicateFn?: UseMutateFunction<
    AxiosResponse<PostDuplicateDealPayload | PostECardDuplicatePayload>,
    unknown,
    PostDuplicateDealPayload
  >,
) => {
  const redirectURL = {
    FavePay: 'favepay',
    Deal: 'deals',
    eCard: 'ecards',
    IPP: 'ipp',
  };

  const productRedirectURL = redirectURL[productType];

  const isDealsOrEcard = productType === 'Deal' || productType === 'eCard';

  const isDeals = productType === 'Deal';
  const isECards = productType === 'eCard';
  const isIPP = productType === 'IPP';

  let viewBtnID;
  let editResubmitID;
  let copyLinkID;
  let downloadContractID;
  let duplicateID;
  let copyProductID;

  switch (productType) {
    case 'FavePay':
      viewBtnID = mySubmissionElementIDs.favePayViewBtn;
      editResubmitID = mySubmissionElementIDs.favePayEditResubmitBtn;
      copyLinkID = mySubmissionElementIDs.favePayCopyLinkBtn;
      downloadContractID = mySubmissionElementIDs.favePayDownloadContractBtn;
      break;
    case 'Deal':
      viewBtnID = mySubmissionElementIDs.dealsViewBtn;
      editResubmitID = mySubmissionElementIDs.dealsEditResubmitBtn;
      copyLinkID = mySubmissionElementIDs.dealsCopyLinkBtn;
      downloadContractID = mySubmissionElementIDs.dealsDownloadContractBtn;
      duplicateID = mySubmissionElementIDs.dealsDuplicateBtn;
      copyProductID = mySubmissionElementIDs.dealsCopyIDBtn;
      break;
    case 'eCard':
      viewBtnID = mySubmissionElementIDs.eCardsViewBtn;
      editResubmitID = mySubmissionElementIDs.eCardsEditResubmitBtn;
      copyLinkID = mySubmissionElementIDs.eCardsCopyLinkBtn;
      downloadContractID = mySubmissionElementIDs.eCardsDownloadContractBtn;
      duplicateID = mySubmissionElementIDs.eCardsDuplicateBtn;
      copyProductID = mySubmissionElementIDs.eCardsCopyIDBtn;
      break;
    case 'IPP':
      viewBtnID = mySubmissionElementIDs.ippViewBtn;
      editResubmitID = mySubmissionElementIDs.ippEditResubmitBtn;
      copyLinkID = mySubmissionElementIDs.ippCopyLinkBtn;
      downloadContractID = mySubmissionElementIDs.ippDownloadContractBtn;
  }

  const buttonDataIDs = {
    viewBtn: viewBtnID,
    editResubmitBtn: editResubmitID,
    copyLinkBtn: copyLinkID,
    downloadContractBtn: downloadContractID,
    duplicateBtn: duplicateID,
    copyProductIDBtn: copyProductID,
  };

  const productID = isECards
    ? (data as ECardsActionsType).e_card.id
    : isIPP
    ? (data as MCContractSubmissionsType).mc_company_ipp_setting.id
    : data.id;

  const menuArray = [
    {
      key: '1',
      label: <span data-id={buttonDataIDs.viewBtn}>View</span>,
      onClick: () => navigateFn(`${productRedirectURL}/${productID}`),
    },
  ];

  const productStatus = isIPP
    ? (data as MCContractSubmissionsType).mc_company_ipp_setting
        .onboarding_status
    : isDeals
    ? (data as BDDealsType).status
    : (data as ECardsActionsType).status;

  const canManageSubmission = data.can_manage_submission;

  const addEditAndResubmitToActions = () =>
    menuArray.push({
      key: '2',
      label: (
        <span data-id={buttonDataIDs.editResubmitBtn}>{'Edit & Resubmit'}</span>
      ),
      onClick: () =>
        isIPP
          ? navigateFn(
              `/admin/merchant_connect/my_merchant/merchants/${data.company.id}/ipp/edit?resubmit=true`,
            )
          : toolRedirect(
              `/admin/merchant_connect/merchants/${data.company.id}/${productRedirectURL}/${productID}/edit`,
            ),
    });

  switch (productStatus) {
    case 'Pending signature':
    case 'Pending Ack.':
      if (canManageSubmission) {
        addEditAndResubmitToActions();

        if (productStatus === 'Pending Ack.' && data.addendum_link !== null)
          menuArray.push({
            key: '3',
            label: <span data-id={buttonDataIDs.copyLinkBtn}>Copy Link</span>,
            onClick: () => copyToClipboard(data.addendum_link),
          });
      }

      break;
    case 'Contract Signed':
    case 'Pending Operations':
    case 'Completed':
    case 'Cancelled':
      if (productStatus === 'Cancelled' && canManageSubmission)
        addEditAndResubmitToActions();

      if (data.contract_url !== null)
        menuArray.push({
          key: '3',
          label: (
            <span data-id={buttonDataIDs.downloadContractBtn}>
              Download Contract
            </span>
          ),
          onClick: () => downloadFileFromURL(data.contract_url),
        });

      if (
        isDealsOrEcard &&
        productStatus === 'Completed' &&
        canManageSubmission
      )
        menuArray.push({
          key: '4',
          label: (
            <span
              data-id={buttonDataIDs.copyProductIDBtn}
            >{`Copy ${productType} ID`}</span>
          ),
          onClick: () => copyToClipboard(productID),
        });
      break;
  }

  if (isDealsOrEcard && duplicateFn && canManageSubmission)
    menuArray.push({
      key: '5',
      label: <span data-id={buttonDataIDs.duplicateBtn}>Duplicate</span>,
      onClick: () =>
        duplicateFn([productID], {
          onSuccess: () => {
            Message.success({
              content: `Successfully duplicated ${productType}.`,
            }).then(() =>
              toolRedirect(
                `/admin/merchant_connect/merchants/${data.company.id}/${productRedirectURL}`,
              ),
            );
          },
        }),
    });

  return menuArray;
};

export const contractMigrationActionItems = (
  data: ContractMigrationMCContractSubmissionsType,
  cancelContractFn: UseMutateAsyncFunction<
    AxiosResponse<number>,
    unknown,
    number,
    unknown
  >,
  refetchListFn: () => void,
) => {
  const menuArray = [];
  const productStatus = data.status;

  switch (productStatus) {
    case 'Pending signature':
      menuArray.push({
        key: '1',
        label: (
          <span
            data-id={mySubmissionElementIDs.contractMigrationCancelContractBtn}
          >
            Cancel Contract
          </span>
        ),
        onClick: () => {
          cancelContractFn(data.id, {
            onSuccess: () => {
              Message.success({ content: 'Successfully cancelled contract.' });
              refetchListFn();
            },
          });
        },
      });
      break;

    case 'Cancelled':
      break;

    case 'Signed':
      if (data.contract_url !== null)
        menuArray.push({
          key: '1',
          label: (
            <span
              data-id={
                mySubmissionElementIDs.contractMigrationDownloadContractBtn
              }
            >
              Download Contract
            </span>
          ),
          onClick: () => downloadFileFromURL(data.contract_url),
        });
  }

  return menuArray;
};

export const statusFilter = [
  { text: `Pending Signature`, value: 'pending signature' },
  { text: `Pending Ack.`, value: 'pending ack.' },
  { text: `Contract Signed`, value: 'contract signed' },
  { text: `Pending Operations`, value: 'pending operations' },
  { text: `Completed`, value: 'completed' },
  { text: `Cancelled`, value: 'cancelled' },
];

export const contractMigrationStatusFilter = [
  { text: `Pending Signature`, value: 'pending signature' },
  { text: `Cancelled`, value: 'cancelled' },
  { text: `Signed`, value: 'signed' },
];
