import React from 'react';
import { PageHeader, PageHeaderProps, Row } from 'fave-ui';
import style from './style.module.css';

export type PageHeaderWithSpaceProps = PageHeaderProps & {
  additionalContent?: React.ReactNode;
};

export const PageHeaderWithSpace: React.FC<PageHeaderWithSpaceProps> = ({
  className,
  additionalContent,
  ...props
}) => (
  <Row className={style.headerWrapper}>
    <PageHeader className={className} {...props} />
    {additionalContent && (
      <div className={style.additionalContent}>{additionalContent}</div>
    )}
  </Row>
);
