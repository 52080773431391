import { useState } from 'react';
import { useNavigate } from 'react-router';
import style from './style.module.css';
import { PageHeaderWithSpace } from '../../../components/common/PageHeaderWithSpace/PageHeaderWithSpace';
import { ColumnsType } from 'antd/lib/table/interface';
import { Card, Col } from 'antd';
import {
  Empty,
  FilterIcon,
  Row,
  Table,
  Button,
  Notification,
  Menu,
  Dropdown,
  Modal,
} from 'fave-ui';
import { RenderColumn } from '../../../helpers/tableHelpers/RenderColumn';
import {
  createOnTableChangeHandler,
  formatFilter,
} from '../../../utils/utilFunctions';
import {
  PrizeListingItem,
  useFetchPrizesListing,
  UpdatePrizePayload,
  useUpdatePrizeMutation,
} from '../../../services/FaveArcade/useFetchPrizesListing';
import { defaultTableConfig } from '../../../helpers/defaults';
import { TableSettingsType } from '../../../types/configTypes';
import { DotsThree, Plus } from 'phosphor-react';
import { Link } from 'react-router-dom';
import { SearchBarExpandable } from '../../../components/common/SearchBarExpandable/SearchBarExpandable';
import { capitalizeFirstLetterEachWord } from '../../../utils/utilFunctions';
import { ArcadePrizeType, CampaignStatusType } from '../../../types/dataTypes';
import { AxiosResponse } from 'axios';
import { ItemType } from 'antd/lib/menu/hooks/useItems';

const successNotification = (message: string) => {
  Notification.success({
    message: message,
  });
};

const PrizeCenterView = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [tableConfig, setTableConfig] =
    useState<TableSettingsType>(defaultTableConfig);
  const [campaign, setCampaign] = useState<PrizeListingItem>();
  const [deActivateModal, setDeActivateModal] = useState(false);
  const { mutateAsync: updatePrizeStatus } = useUpdatePrizeMutation();
  const navigate = useNavigate();

  const handleUpdateStatus = async (campaign: UpdatePrizePayload) =>
    await updatePrizeStatus(campaign, {
      onSuccess: (res: AxiosResponse) => {
        successNotification(res?.data?.message ?? 'Your action is success!');
      },
    });

  const tableChangeHandler = createOnTableChangeHandler(
    setTableConfig,
    filters => formatFilter([{ key: 'status', value: filters.status }]),
  );

  const { data: prizeData, isFetching } = useFetchPrizesListing({
    page: tableConfig.page,
    limit: tableConfig.limit,
    sort_order: tableConfig.sort_order,
    sort_by: tableConfig.sort_by,
    filter: `name=${searchQuery}|${tableConfig.filter ?? ''}`,
  });

  const prizeListingData = prizeData?.prizes.map((prize: PrizeListingItem) => {
    return {
      ...prize,
      status: capitalizeFirstLetterEachWord(prize.status) as CampaignStatusType,
      prize_type: capitalizeFirstLetterEachWord(
        prize.prize_type.split('_').join(' '),
      ) as ArcadePrizeType,
    };
  });

  const prizeListingColumns: ColumnsType<PrizeListingItem> = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: 90,
      render: RenderColumn.rowText,
    },
    {
      title: 'Campaign Name',
      dataIndex: 'campaign_name',
      width: 250,
      render: RenderColumn.rowText,
    },
    {
      title: 'Prize Name',
      dataIndex: 'prize_name',
      width: 220,
      render: RenderColumn.rowText,
    },
    {
      title: 'Type of Prize',
      dataIndex: 'prize_type',
      width: 220,
      render: RenderColumn.rowText,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      filterIcon: FilterIcon,
      filters: [
        { text: `Active`, value: 'active' },
        { text: `Inactive`, value: 'inactive' },
      ],
      width: 120,
      render: RenderColumn.campaignStatusTag,
    },
    {
      title: 'Cost of Prize (tokens)',
      dataIndex: 'cost_of_token',
      width: 120,
      render: RenderColumn.rowText,
    },
    {
      title: 'Redemptions Available (per user)',
      dataIndex: 'max_user_limit',
      width: 120,
      render: RenderColumn.rowText,
    },
    {
      title: 'Redemptions Available (total)',
      dataIndex: 'max_campaign_limit',
      width: 120,
      render: RenderColumn.rowText,
    },
    {
      title: 'Change Log',
      width: 120,
      render: (row: PrizeListingItem) => (
        <Link to={`changelog/${row.id}`}>
          <Button type={'link'}>View</Button>
        </Link>
      ),
    },
    {
      title: 'Actions',
      key: 'action',
      width: 120,
      render: (row: PrizeListingItem) => {
        const actionItems: ItemType[] = [
          {
            key: 'view',
            label: 'View',
            onClick: () =>
              navigate(`/fave_arcade/prize_catalog/view_campaign/${row.id}`),
          },
          {
            key: 'editStatus',
            label: row.status === 'Active' ? 'Deactivate' : 'Activate',
            onClick: () => {
              if (row.status === 'Active') {
                setDeActivateModal(true);
                setCampaign(row);
              } else {
                handleUpdateStatus({
                  id: row.id,
                  status_event: row.status_event,
                });
              }
            },
          },
          {
            key: 'editCampaign',
            label: 'Edit',
            onClick: () =>
              navigate(`/fave_arcade/prize_catalog/edit_campaign/${row.id}`),
          },
        ];

        const menu = <Menu items={actionItems} />;

        return (
          <Dropdown overlay={menu} trigger={['click']}>
            <Button
              type={'link'}
              className={style.btnText}
              onClick={e => e.preventDefault()}
            >
              <DotsThree size={32} />
            </Button>
          </Dropdown>
        );
      },
    },
  ];

  return (
    <>
      <PageHeaderWithSpace title={'Prize Catalog Manager'} subTitle={''} />
      <Card style={{ width: '100%' }}>
        <Row className={style.summaryWrapper}>
          <Col>
            <h2 className={style.h2}>{'Prize Summary'}</h2>
          </Col>
          <Col className={style.actionsCol}>
            <Row>
              <SearchBarExpandable
                onSearch={keyword => setSearchQuery(keyword.toLowerCase())}
              />
              <Button
                icon={<Plus className="icon-start" size={16} />}
                iconPlacement="start"
                onClick={() => navigate('/fave_arcade/prize_catalog/new')}
                type="primary"
              >
                Create
              </Button>
            </Row>
          </Col>
        </Row>
      </Card>
      <Row>
        <Table
          size="large"
          rowKey={row => row.id}
          loading={isFetching}
          columns={prizeListingColumns}
          onChange={tableChangeHandler}
          dataSource={prizeListingData}
          className={style.table}
          pagination={{
            total: prizeData?.count,
            position: ['bottomCenter'],
            pageSize: tableConfig.pageSize,
            current: tableConfig.current,
            showSizeChanger: false,
          }}
          locale={{
            emptyText: !isFetching && <Empty emptyType="cant-find-anything" />,
          }}
        />
      </Row>
      <Modal
        title={`Warning!`}
        visible={deActivateModal}
        okText="Yes, proceed"
        cancelText="Cancel"
        onOk={() =>
          campaign &&
          handleUpdateStatus({
            id: campaign.id,
            status_event: campaign.status_event,
          })
        }
        onCancel={() => setDeActivateModal(false)}
      >
        You are about to deactivate {campaign?.campaign_name}, proceed?
      </Modal>
    </>
  );
};

export default PrizeCenterView;
