import { useParams } from 'react-router';

import { useFetchPrizeListingData } from '../../../../services/FaveArcade/useFetchPrizesListing';
import { PageHeaderWithBackButton } from '../../../../components/common';
import EditPromoCodeForm from './EditPromoCodeForm';
import EditLuckyDrawForm from './EditLuckyDrawForm';

export default function EditPrizeCampaign() {
  const { id } = useParams();
  const { data: campaignData } = useFetchPrizeListingData({ id: id });

  if (!campaignData) return null;

  return (
    <>
      <PageHeaderWithBackButton title={`Edit Prize Campaign`} />

      {campaignData?.prize_type === 'promo_code' ? (
        <EditPromoCodeForm initialData={campaignData} />
      ) : campaignData?.prize_type === 'lucky_draw' ? (
        <EditLuckyDrawForm initialData={campaignData} />
      ) : (
        <span>Invalid prize type: {campaignData.prize_type}</span>
      )}
    </>
  );
}
