import { ColumnsType } from 'antd/lib/table';
import { Table } from 'fave-ui';
import { Funnel } from 'phosphor-react';
import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { mySubmissionElementIDs } from '..';
import { TableHeaderSort } from '../../../components/common';
import { defaultTableConfig } from '../../../helpers/defaults';
import { RenderColumn } from '../../../helpers/tableHelpers/RenderColumn';
import {
  MCContractSubmissionsType,
  useFetchIPPList,
} from '../../../services/MySubmissions';
import { TableSettingsType } from '../../../types/configTypes';
import { MySubmissionStatusType } from '../../../types/dataTypes';
import {
  createOnTableChangeHandler,
  dateOrDash,
  formatFilter,
  scrollTableConfig,
} from '../../../utils/utilFunctions';
import { actionItems, statusFilter } from '../helpers';
import style from './style.module.css';

const scrollConfig = scrollTableConfig();

export const InstalmentPaymentPlanTab: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const keyword = searchParams.get('query');

  const [tableConfig, setTableConfig] =
    useState<TableSettingsType>(defaultTableConfig);

  const tableChangeHandler = createOnTableChangeHandler(
    setTableConfig,
    filters =>
      formatFilter([
        {
          key: 'ipp_mc_my_submission_status',
          // match with dataIndex for submission status
          value: filters['mc_company_ipp_setting.onboarding_status'],
        },
      ]),
  );

  const { data: ippData, isFetching } = useFetchIPPList({
    ...tableConfig,
    page: tableConfig.current,
    filter: `ipp_submission=true|partner=${keyword || ''}|${
      tableConfig.filter || ''
    }`,
    dataset: 'company',
    sort_order: tableConfig.sort_order,
    sort_by: Array.isArray(tableConfig.sort_by)
      ? tableConfig.sort_by.join('.')
      : tableConfig.sort_by,
  });

  const ippTabColumns: ColumnsType<MCContractSubmissionsType> = [
    {
      title: TableHeaderSort(
        'Submission Date',
        mySubmissionElementIDs.ippSortSubmissionDateBtn,
      ),
      sorter: true,
      dataIndex: 'submission_time',
      width: 200,
      render: date => dateOrDash(date),
    },
    {
      title: 'Submitted by',
      dataIndex: 'bd_email',
      width: 200,
      render: RenderColumn.rowText,
    },
    {
      title: 'Merchant Name',
      dataIndex: ['company', 'name'],
      width: 200,
      render: RenderColumn.rowText,
    },
    {
      title: 'Instalment Payment Plan',
      width: 200,
      render: () => 'Instalment Payment Plan',
    },
    {
      title: 'Submission Status',
      dataIndex: ['mc_company_ipp_setting', 'onboarding_status'],
      width: 200,
      filterIcon: (
        <Funnel
          size={16}
          weight="light"
          data-id={mySubmissionElementIDs.ippSubmissionStatusFilterIcon}
        />
      ),
      filters: statusFilter,
      filterMode: 'tree',
      render: (status: MySubmissionStatusType, data) =>
        RenderColumn.mySubmissionsStatusPopover(status, data, 'IPP'),
    },
    {
      title: 'Actions',
      key: 'action',
      width: 120,
      render: data =>
        RenderColumn.dropdownMenu(
          actionItems(data, 'IPP', navigate),
          '. . .',
          'bottomLeft',
          mySubmissionElementIDs.ippActionBtn,
        ),
      fixed: 'right',
    },
  ];

  return (
    <Table
      dataSource={ippData?.mc_contract_submissions}
      columns={ippTabColumns}
      scroll={scrollConfig}
      className={style.table}
      rowKey={key => key.id}
      onChange={tableChangeHandler}
      loading={isFetching}
      pagination={{
        total: ippData?.count,
        position: ['bottomCenter'],
        pageSize: tableConfig.pageSize,
        current: tableConfig.page,
        showSizeChanger: false,
      }}
    />
  );
};
