import { useParams } from 'react-router';
import { Col, Row } from 'antd';
import Form from '../../../../components/common/form/Form';
import { Select } from '../../../../components/common/form/Select';
import { Input } from '../../../../components/common/form/Input';
import { PageHeaderWithBackButton } from '../../../../components/common/PageHeaderWithBackButton';
import { useFetchCampaignListingData } from '../../../../services/FaveArcade/useFetchCampaignListing';

export default function TicketDetailsView() {
  const { id } = useParams();
  const { data: campaignData } = useFetchCampaignListingData({ id: id });
  const initialValues = {
    campaign_name: campaignData?.campaign_name,
    campaign_type: campaignData?.campaign_type,
    product_types: campaignData?.product_types,
    payment_method_types: campaignData?.payment_method_types,
    condition_type: campaignData?.condition_type,
    amount_cents:
      campaignData?.amount_cents && campaignData?.amount_cents / 100,
    no_of_ticket: campaignData?.no_of_ticket,
  };

  return (
    <>
      <PageHeaderWithBackButton title={`View Campaign`} />
      <Form
        initialValues={initialValues}
        validateOnMount
        validateOnChange
        validateOnSchemaChange
      >
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Input
              name="campaign_name"
              label="Campaign Name"
              placeholder=""
              allowClear
              bordered
              style={{ marginBottom: 0 }}
              disabled
            />
          </Col>
          <Col span={24}>
            <Input
              name="campaign_type"
              label="Ticket Campaign"
              placeholder=""
              disabled
            />
          </Col>
          <Col span={24}>
            <Select
              name="product_types"
              label="Product"
              placeholder=""
              mode="tags"
              disabled
            />
          </Col>
          <Col span={24}>
            <Select
              name="payment_method_types"
              label="Payment Method"
              placeholder=""
              mode="tags"
              disabled
            />
          </Col>
          <Col span={5}>
            <Select
              name="condition_type"
              label="Condition"
              placeholder=""
              mode="tags"
              disabled
            />
          </Col>
          <Col span={19}>
            <Input
              type="number"
              name="amount_cents"
              label={`Amount In ${campaignData?.currency}`}
              allowClear
              bordered
              disabled
            />
          </Col>
          <Col span={24}>
            <Input
              type="number"
              name="no_of_ticket"
              label="Issue Tickets"
              placeholder="Enter number of tickets"
              allowClear
              bordered
              disabled
              style={{ marginBottom: 0 }}
            />
          </Col>
        </Row>
      </Form>
    </>
  );
}
