import { FilterDropdownProps } from 'antd/lib/table/interface';
import { Button, Input } from 'fave-ui';
import React, { BaseSyntheticEvent, useState } from 'react';
import style from './style.module.css';

type SearchFilterDropdownConfirmProps = Pick<FilterDropdownProps, 'confirm'>;

export type SearchFilterDropdownOuterProps = {
  // todo - remove setKeyword and related logic, refactor respective logic on NaturalOfBussiness
  setKeyword?: (text: string) => void;
  // end todo
  onOk?: () => void;
  onReset?: () => void;
  placeholder?: string;
};

type SearchFilterDropdownProps = SearchFilterDropdownOuterProps &
  SearchFilterDropdownConfirmProps &
  FilterDropdownProps;

const SearchFilterDropdown: React.FC<SearchFilterDropdownProps> = ({
  setKeyword,
  onOk,
  onReset,
  confirm,
  setSelectedKeys,
  placeholder,
}) => {
  const [search, setSearch] = useState('');
  return (
    <div className={style.searchFilterDropdown}>
      <Input
        placeholder={placeholder}
        value={search}
        autoFocus={true}
        onChange={(e: BaseSyntheticEvent) =>
          setSearch(e.target.value ? [e.target.value][0] : [])
        }
      />
      <div className={style.buttonContainer}>
        <Button
          onClick={() => {
            setSelectedKeys([]);
            confirm({ closeDropdown: true });
            setKeyword && setKeyword('');
            setSearch('');
            onReset && onReset();
          }}
          type={'text'}
        >
          Reset
        </Button>
        <Button
          onClick={() => {
            setSelectedKeys([search]);
            confirm({ closeDropdown: true });
            onOk && onOk();
            setKeyword && setKeyword(search);
          }}
          type={'primary'}
        >
          OK
        </Button>
      </div>
    </div>
  );
};

export const searchFilterDropdownHOC = (
  outerProps: SearchFilterDropdownOuterProps,
) => {
  return (dropdownProps: FilterDropdownProps) => (
    <SearchFilterDropdown {...outerProps} {...dropdownProps} />
  );
};
