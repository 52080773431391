import { TablePaginationConfig } from 'antd';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { format, parseISO } from 'date-fns';
import { Message } from 'fave-ui';
import { Dispatch, SetStateAction } from 'react';
import {
  FormatFilterType,
  PaginationSettingsType,
  TableSettingsType,
} from '../types/configTypes';
import { CompanyType } from '../types/dataTypes';

export const merchantNameSorter = (a: CompanyType, b: CompanyType) =>
  a.name.localeCompare(b.name);

export const isTableDataEmpty = <T extends {}>(data: T[] | undefined) =>
  data && data.length === 0;

export const calcTableHeight = (height = 320) => `calc(100vh - ${height}px)`;

export const calcTableWidth = (width = 1200) => `calc(100vw - ${width}px)`;

export const scrollTableConfig = (props?: {
  height?: number;
  width?: number;
}) => {
  return {
    x: calcTableWidth(props && props.width),
    y: calcTableHeight(props && props.height),
  };
};

const tableStatesHandler = <DataType>(
  state: TableSettingsType,
  pagination: TablePaginationConfig,
  sorter: SorterResult<DataType> | SorterResult<DataType>[],
  filters: Record<string, FilterValue | null>,
  createFilter:
    | ((filter: Record<string, FilterValue | null>) => string)
    | undefined,
  createSorter?: (sorter: SorterResult<DataType>) => void,
): TableSettingsType => {
  if (createSorter && !Array.isArray(sorter)) createSorter(sorter);

  return {
    ...state,
    ...pagination,
    page: pagination.current,
    sort_order: Array.isArray(sorter) ? undefined : sorter.order,
    sort_by: Array.isArray(sorter) ? undefined : (sorter.field as string),
    filter: createFilter ? createFilter(filters) : undefined,
  };
};

export const createOnTableChangeHandler =
  <SettingsType extends TableSettingsType>(
    setTableConfig: Dispatch<SetStateAction<any>>,
    createFilter?: (filter: Record<string, FilterValue | null>) => string,
    createSorter?: <CreateSorterType>(
      sorter: SorterResult<CreateSorterType>,
    ) => void,
    tableId?: number,
  ) =>
  <DataType>(
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<DataType> | SorterResult<DataType>[],
  ) =>
    setTableConfig((states: SettingsType | SettingsType[]) =>
      Array.isArray(states)
        ? states.map(state =>
            state.id === tableId
              ? tableStatesHandler(
                  state,
                  pagination,
                  sorter,
                  filters,
                  createFilter,
                  createSorter,
                )
              : state,
          )
        : tableStatesHandler(
            states,
            pagination,
            sorter,
            filters,
            createFilter,
            createSorter,
          ),
    );

export const createOnPaginationChangeHandler =
  (setPaginationConfig: Dispatch<SetStateAction<PaginationSettingsType>>) =>
  (page: number, pageSize: number) =>
    setPaginationConfig({ page, limit: pageSize });

export const formatFilter = (filters: FormatFilterType[]) => {
  const newFilter = filters.map(item => {
    const filterKey = item.key;
    const filterValue = item.value ? item.value.toString() : '';

    return `${filterKey}=${filterValue}`;
  });

  return newFilter.join('|');
};

const defaultDateFormat = 'dd MMM yyyy';
const defaultDateTimeFormat = `${defaultDateFormat} hh:mm aaa`;
export const defaultDateTimeSecondsForm = `${defaultDateFormat} hh:mm:ss aaa`;

export const formatDate = (
  date: string | Date | undefined,
  dateFormat = defaultDateFormat,
) => {
  if (date === undefined) return '';

  return format(typeof date === 'string' ? parseISO(date) : date, dateFormat);
};

export const dateOrDash = (
  date?: Date | string,
  dateFormat = defaultDateFormat,
) => (date ? formatDate(date, dateFormat) : '-');

export const formatDateTime = (
  dateTime: string | Date | undefined,
  dateFormat = defaultDateTimeFormat,
) => formatDate(dateTime, dateFormat);

export const dateTimeOrDash = (
  dateTime?: Date | string,
  dateFormat = defaultDateTimeFormat,
) => (dateTime ? formatDateTime(dateTime, dateFormat) : '-');

export const formatLongNumbersWithCommas = (number: number, format = 'en-US') =>
  number.toLocaleString(format);

export const formatDecimalPlaces = (decimalNumber: number, decimalPlaces = 2) =>
  decimalNumber.toFixed(decimalPlaces);

export const formatDateRange = (
  startDate: Date,
  endDate: Date,
  dateFormat = defaultDateFormat,
) =>
  `${dateOrDash(startDate, dateFormat)} - ${dateOrDash(endDate, dateFormat)}`;

export const valueOrDash = (data?: number | string, customText = '') =>
  typeof data === 'number' ? `${data || 0}${customText}` : `${data || '-'}`;

export const customBooleanValue = (
  data?: boolean,
  customText = { valueIfTrue: 'Yes', valueIfFalse: 'No' },
) => (data ? customText.valueIfTrue : customText.valueIfFalse);

// TODO
// incorrect return type, fix in future
export const groupBy = <
  GroupingField extends keyof DataType,
  ReturnType extends { [key in keyof DataType]: DataType[] },
  DataType,
>(
  array: DataType[] | undefined,
  groupBy: GroupingField,
  callback?: (item: DataType) => DataType,
) => {
  return array?.reduce<ReturnType>((object, item) => {
    const newItem = callback ? callback(item) : item;

    const groupingField = item[groupBy] as unknown as keyof ReturnType;

    if (object[groupingField]) object[groupingField].push(newItem);
    else (object[groupingField] as DataType[]) = [newItem];

    return object;
  }, Object.create({}));
};

export const groupByArray = <
  GroupingField extends keyof DataType,
  ReturnType extends {
    [key in keyof DataType]: DataType[key];
  } & {
    id: string;
    items: DataType[];
  },
  DataType,
>(
  array: DataType[],
  groupByFields: GroupingField[],
): ReturnType[] => {
  const helper = Object.create({});

  return array.reduce<ReturnType[]>((groupArray, currentObject) => {
    const key = groupByFields
      .map(groupByField => currentObject[groupByField])
      .join('|');

    if (!helper[key]) {
      const newGroupObject = {
        items: [currentObject],
        id: key,
      } as unknown as ReturnType;

      groupByFields.forEach(
        groupByField =>
          (newGroupObject[groupByField] = currentObject[
            groupByField
          ] as ReturnType[GroupingField]),
      );

      helper[key] = newGroupObject;

      groupArray.push(helper[key]);
    } else helper[key].items.push(currentObject);

    return groupArray;
  }, []);
};

export const filterObjectByKey = <T extends {}>(
  values: T,
  excludeKey: string[],
  customFilter?: (key: string) => void,
) =>
  Object.keys(values).filter(item => {
    const isExcludedItem = !excludeKey.includes(item);
    return customFilter !== undefined
      ? isExcludedItem && customFilter(item)
      : isExcludedItem;
  });

export const capitalizeFirstLetterEachWord = (text: string) =>
  text
    .split(' ')
    .map(item => item.charAt(0).toUpperCase() + item.slice(1))
    .join(' ');

export const arrayToString = (array: string[], separator: string) =>
  array.map(item =>
    capitalizeFirstLetterEachWord(item.split(separator).join(' ')),
  );

export const arrayToOptions = (array: string[], separator: string) =>
  array?.map(item => {
    return {
      label: capitalizeFirstLetterEachWord(item.split(separator).join(' ')),
      key: item,
      value: item,
    };
  });

export const appendFormData = <T extends {}>(data: T) => {
  const formData = new FormData();
  const arrayObj = Object.entries(data);

  arrayObj.forEach(obj => {
    if (Array.isArray(obj[1])) {
      obj[1].forEach(item => {
        if (typeof item === 'object') {
          Object.keys(item).forEach(child => {
            formData.append(`${obj[0]}[][${child}]`, item[child]);
          });
        } else {
          formData.append(`${obj[0]}[]`, item);
        }
      });
    } else {
      formData.append(obj[0], obj[1] as string | Blob);
    }
  });
  return formData;
};

export const copyToClipboard = async (
  text: string | number,
  customSuccessMessage?: string,
) => {
  try {
    await navigator.clipboard.writeText(String(text));
    Message.success({
      content:
        customSuccessMessage || 'Successfully copied contents to clipboard.',
    });
  } catch (err) {
    Message.error({ content: 'Something went wrong.' });
    console.error('Failed to copy: ', err);
  }
};

export const downloadFileFromURL = (url: string) => {
  const fileName = url.substring(url.lastIndexOf('/') + 1);

  fetch(url, {
    method: 'get',
    referrerPolicy: 'no-referrer',
  })
    .then(res => res.blob())
    .then(res => {
      const aElement = document.createElement('a');
      aElement.setAttribute('download', fileName);
      const href = URL.createObjectURL(res);
      aElement.href = href;
      aElement.setAttribute('target', '_blank');
      aElement.click();
      URL.revokeObjectURL(href);
    });
};

const base64ToArrayBuffer = (fileBytes: string) => {
  const binaryString = window.atob(fileBytes);
  const binaryLen = binaryString.length;
  const bytes = new Uint8Array(binaryLen);
  for (let i = 0; i < binaryLen; i++) {
    const ascii = binaryString.charCodeAt(i);
    bytes[i] = ascii;
  }
  return bytes;
};

const saveByteArray = (fileName: string, file: BlobPart) => {
  const blob = new Blob([file]);
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = fileName;
  link.click();
};

export const downloadFileFromBase64Bytes = (
  fileBytes: string,
  fileName: string,
) => {
  const file = base64ToArrayBuffer(fileBytes);
  saveByteArray(fileName, file);
};
