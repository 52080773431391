import { Spin } from 'antd';
import { Empty, Message, Table } from 'fave-ui';
import React, { useMemo } from 'react';
import { useLocation, useParams } from 'react-router';
import Form from '../../../components/common/form/Form';
import { PageHeaderWithBackButton } from '../../../components/common/PageHeaderWithBackButton';
import GreyDivider from '../../../components/GreyDivider';
import {
  AdminToolCompanyPaymentModePayload,
  AdminToolOutletPaymentModeChangeResponse,
  AdminToolOutletPaymentModeParam,
  AdminToolOutletPaymentModePayload,
  PaymentModeFormType,
  PaymentModeType,
  UpdateAdminToolCompanyPaymentModePayload,
  useFetchAdminToolCompanyPaymentMode,
  useFetchAdminToolOutletPaymentMode,
  useFetchAdminToolOutletPaymentModeChanges,
  useFetchCompanyApplicationFee,
  useFetchPaymentModeList,
  useUpdatePaymentModeAndApplicationFee,
  useUpdatePaymentModeForAdmin,
} from '../../../services/PaymentMode/usePaymentMode';
import { calcTableHeight } from '../../../utils/utilFunctions';
import ApplicationFeeForm from '../ApplicationFeeForm';
import PaymentModeCreateForm from '../PaymentModeCreateForm';

import style from '../style.module.css';
import * as Yup from 'yup';
import { ObjectShape } from 'yup/lib/object';
import {
  dataColumnsPaymentModeChanges,
  groupByPaymentMode,
  groupByPaymentModeSchema,
} from '../helpers/paymentModeHelpers';
import { FormikHelpers } from 'formik';
import { useUserContext } from '../../../contexts/UserContext';
import { FieldName } from '../helpers/enums';
import { QueryObserverResult } from 'react-query';
import useSetBreadCrumbParams from '../../../hooks/useSetBreadCrumbParams';

const tableHeight = calcTableHeight(225);

const applicationFeeFieldKey = 'application_fee';
const companyPaymentModesFieldKey = 'company_payment_modes';

const onSuccessSubmitForm = () =>
  Message.success({
    content: `Successfully updated Application Fee and Payment Mode.`,
  });

const onSubmitForm = (
  isEditMode: boolean,
  values: UpdateAdminToolCompanyPaymentModePayload,
  companyID: number,
  outletId: number,
  isFastpay: boolean,
  updatePaymentModeForAdmin: (
    data: AdminToolOutletPaymentModePayload,
    response: { onSuccess: () => void; onError: () => void },
  ) => void,
  updatePaymentModeAndApplicationFee: (
    data: AdminToolCompanyPaymentModePayload,
    response: { onSuccess: () => void; onError: () => void },
  ) => void,
  refetchChangeLogs: () => Promise<
    QueryObserverResult<AdminToolOutletPaymentModeChangeResponse[], unknown>
  >,
  { setSubmitting }: FormikHelpers<PaymentModeFormType>,
) => {
  const formData = new FormData();
  const listPaymentModes = [] as AdminToolOutletPaymentModeParam[];

  Object.keys(values).forEach(value => {
    const isApplicationFeeFormFields = value.includes('_');

    if (isApplicationFeeFormFields) {
      formData.append(
        `${applicationFeeFieldKey}[${value}]`,
        String(values[value]),
      );
    } else
      values[value].forEach(item => {
        if (!isFastpay)
          listPaymentModes.push({
            id: item.id,
            payment_mode_id: item.payment_mode?.id,
            rate: item[
              `rate${item.group.replace(' ', '')}${item.variant}`
            ] as number,
            fixed_amount: item[
              `fixed_amount${item.group.replace(' ', '')}${item.variant}`
            ] as number,
            status: item.status ? 'enabled' : 'disabled',
            is_fpx: item.is_fpx,
          });
        formData.append(
          `${companyPaymentModesFieldKey}[][status]`,
          item.status ? 'enabled' : 'disabled',
        );
        formData.append(
          `${companyPaymentModesFieldKey}[][rate]`,
          String(
            item[`rate${item.group.replace(' ', '')}${item.variant}`] as number,
          ),
        );

        if (item.is_fpx) {
          formData.append(
            `${companyPaymentModesFieldKey}[][is_fpx]`,
            String(item.is_fpx),
          );
          formData.append(
            `${companyPaymentModesFieldKey}[][fixed_amount]`,
            String(
              item[
                `fixed_amount${item.group.replace(' ', '')}${item.variant}`
              ] || 0,
            ),
          );
        } else {
          if (isEditMode) {
            formData.append(
              `${companyPaymentModesFieldKey}[][id]`,
              String(item.id),
            );
            formData.append(
              `${companyPaymentModesFieldKey}[][payment_mode_id]`,
              String(item.payment_mode.id),
            );
          } else
            formData.append(
              `${companyPaymentModesFieldKey}[][payment_mode_id]`,
              String(item.id),
            );
        }
      });
  });
  if (isFastpay) {
    updatePaymentModeAndApplicationFee(
      { company_id: companyID, params: formData },
      {
        onSuccess: () => {
          onSuccessSubmitForm();
          setSubmitting(false);
        },
        onError: () => setSubmitting(false),
      },
    );
  } else {
    updatePaymentModeForAdmin(
      {
        outlet_id: outletId,
        params: { outlet_payment_modes: listPaymentModes },
      },
      {
        onSuccess: () => {
          onSuccessSubmitForm();
          setSubmitting(false);
          refetchChangeLogs();
        },
        onError: () => setSubmitting(false),
      },
    );
  }
};

const ApplicationPaymentModeView: React.FC = () => {
  const { id } = useParams();
  const parsedID = parseInt(id || '');

  const navigate = useLocation();
  const params = new URLSearchParams(navigate.search);
  const isFastpay = params.get('merchant_type') === 'fastpay';
  const outletId = parseInt(params.get('outlet_id') || '');

  const { getPageSettings } = useUserContext();

  const pageSettings = getPageSettings('partners');

  const enableUpdateForm = isFastpay
    ? !!pageSettings?.update_payment_modes_and_application_fee
    : !!pageSettings?.update_outlet_payment_modes;

  const isApplicationFeeFormPage = false;

  const hasIdInUrl = !!parsedID;

  useSetBreadCrumbParams(
    [
      { label: '', key: parsedID.toString() },
      { label: '', key: 'edit' },
    ],
    !!hasIdInUrl,
  );

  const {
    data: fetchCompanyPaymentModeListData,
    isFetching: isFetchingCompanyModeListDataFetching,
  } = useFetchAdminToolCompanyPaymentMode({ company_id: parsedID, isFastpay });

  const {
    data: fetchOutletPaymentModeListData,
    isFetching: isFetchingOutletModeListDataFetching,
  } = useFetchAdminToolOutletPaymentMode({ outlet_id: outletId, isFastpay });

  const {
    data: fetchCompanyApplicationFeeData,
    isFetching: isFetchingCompanyApplicationFeeData,
  } = useFetchCompanyApplicationFee({ company_id: parsedID, isFastpay });

  const {
    data: fetchOutletPaymentModeChangeListData,
    isFetching: isFetchingOutletModeListChangeDataFetching,
    refetch: refetchChangeLogs,
  } = useFetchAdminToolOutletPaymentModeChanges({
    outlet_id: outletId,
    isFastpay,
  });

  const { data: paymentModeListData, isFetching: isPaymentModeListFetching } =
    useFetchPaymentModeList();

  const { mutate: updatePaymentModeAndApplicationFee } =
    useUpdatePaymentModeAndApplicationFee();

  const { mutate: updatePaymentModeForAdmin } = useUpdatePaymentModeForAdmin();

  const newCompanyPaymentModeListData =
    fetchCompanyPaymentModeListData?.company_payment_modes.map(item => {
      return {
        ...item,
        group: item.payment_mode.group,
        variant: item.payment_mode.variant,
      };
    });

  const newOutletPaymentModeListData =
    fetchOutletPaymentModeListData?.outlet_payment_modes.map(item => {
      return {
        ...item,
        group: item.payment_mode.group,
        variant: item.payment_mode.variant,
      };
    });

  const isLoading =
    isFetchingCompanyModeListDataFetching ||
    isFetchingOutletModeListDataFetching ||
    isFetchingCompanyApplicationFeeData ||
    isPaymentModeListFetching;

  const isEditMode = isFastpay
    ? !!(
        fetchCompanyPaymentModeListData?.company_payment_modes &&
        fetchCompanyPaymentModeListData?.company_payment_modes.length > 0
      )
    : !!(
        fetchOutletPaymentModeListData?.outlet_payment_modes &&
        fetchOutletPaymentModeListData?.outlet_payment_modes.length > 0
      );

  const groupedPaymentModes = useMemo(() => {
    return groupByPaymentMode(
      isEditMode
        ? isFastpay
          ? newCompanyPaymentModeListData
          : newOutletPaymentModeListData
        : (paymentModeListData?.payment_modes as PaymentModeType[]),
      isFastpay,
    );
  }, [
    isFastpay,
    isEditMode,
    newCompanyPaymentModeListData,
    newOutletPaymentModeListData,
    paymentModeListData,
  ]);

  const initialValues = (
    isFastpay
      ? {
          ...groupedPaymentModes,
          ...fetchCompanyApplicationFeeData,
        }
      : { ...groupedPaymentModes }
  ) as PaymentModeFormType;

  const dynamicSchema =
    groupedPaymentModes && groupByPaymentModeSchema(groupedPaymentModes);

  const formSchema = Yup.object().shape({
    ...(dynamicSchema as unknown as ObjectShape),
  });

  return (
    <div className={style.applicationPaymentModeView}>
      <PageHeaderWithBackButton
        title={'Fee(s)'}
        subTitle={
          'Please fill in related fee(s) and select at least one payment mode'
        }
      />
      <div className={style.content}>
        <div className={style.form} style={{ height: tableHeight }}>
          <Spin spinning={isLoading}>
            <Form
              initialValues={initialValues}
              onSubmit={(
                values: UpdateAdminToolCompanyPaymentModePayload,
                helpers: FormikHelpers<PaymentModeFormType>,
              ) =>
                onSubmitForm(
                  isEditMode,
                  values,
                  parsedID,
                  outletId,
                  isFastpay,
                  updatePaymentModeForAdmin,
                  updatePaymentModeAndApplicationFee,
                  refetchChangeLogs,
                  helpers,
                )
              }
              validationSchema={formSchema}
              validateOnMount
              validateOnChange
              validateOnSchemaChange
            >
              {isFastpay && (
                <>
                  <p className={style.formTitle}>Other Applicable Fees</p>
                  <GreyDivider />
                  <ApplicationFeeForm
                    isApplicationFeeFormPage={isApplicationFeeFormPage}
                    enableEditDatepicker={enableUpdateForm}
                  />
                </>
              )}
              <p className={style.formTitle}>Payment Mode</p>
              <GreyDivider />
              <PaymentModeCreateForm
                isApplicationFeePage={isApplicationFeeFormPage}
                enableUpdateForm={enableUpdateForm}
              />
            </Form>
            {!isFastpay &&
              enableUpdateForm &&
              fetchOutletPaymentModeChangeListData &&
              fetchOutletPaymentModeChangeListData.map(
                (paymentModeChange, index) => (
                  <div key={index}>
                    <GreyDivider />
                    <p className={style.tableTitle}>
                      {paymentModeChange.group_name}{' '}
                      {paymentModeChange.group_name !==
                        FieldName.GroupNameOnlineBanking &&
                        `- ${paymentModeChange.variant_name}`}
                    </p>
                    <Table
                      pagination={{
                        hideOnSinglePage: true,
                        pageSize: paymentModeChange.changes.length || 0,
                      }}
                      size="large"
                      loading={isFetchingOutletModeListChangeDataFetching}
                      dataSource={paymentModeChange.changes.filter(
                        pm => pm.field !== FieldName.FieldId,
                      )}
                      columns={dataColumnsPaymentModeChanges}
                      className={style.tablePaymentModeChange}
                      locale={{
                        emptyText:
                          !isFetchingOutletModeListChangeDataFetching && (
                            <Empty
                              emptyType="cant-find-anything"
                              style={{ height: tableHeight }}
                            />
                          ),
                      }}
                    />
                  </div>
                ),
              )}
          </Spin>
        </div>
      </div>
    </div>
  );
};

export default ApplicationPaymentModeView;
