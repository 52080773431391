import { CleverTapContextProvider } from './contexts/CleverTapContext';
import { UserContextProvider } from './contexts/UserContext';
import { ReactQueryDevtools } from 'react-query/devtools';
import { MutationCache, QueryClient, QueryClientProvider } from 'react-query';
import { AdminToolRoutes } from './Routes';
import { TenantContextProvider } from './contexts/TenantContext';
import { EnvironmentContextProvider } from './contexts/EnvironmentContext';
import { BrowserRouter } from 'react-router-dom';
import { defaultFetchSettings, handleApiError } from './services/http';
import { AxiosError } from 'axios';
import { BreadCrumbsContextProvider } from './contexts/BreadCrumbsContext';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundry';

const mutationCache = new MutationCache({
  onError: (error, _, __, { options, meta }) => {
    // use allowGlobalErrorHandling to enable both
    // global and local error handling
    if (!options.onError || meta?.allowGlobalErrorHandling)
      handleApiError(error as AxiosError<{ error: string }, unknown>);
  },
});

const queryClient = new QueryClient({
  mutationCache,
  defaultOptions: {
    queries: defaultFetchSettings,
  },
});

function App() {
  return (
    <BrowserRouter>
      <TenantContextProvider>
        <EnvironmentContextProvider>
          <CleverTapContextProvider>
            <QueryClientProvider client={queryClient}>
              <UserContextProvider>
                <BreadCrumbsContextProvider>
                  <ErrorBoundary>
                    <AdminToolRoutes />
                  </ErrorBoundary>
                </BreadCrumbsContextProvider>
              </UserContextProvider>
              <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
          </CleverTapContextProvider>
        </EnvironmentContextProvider>
      </TenantContextProvider>
    </BrowserRouter>
  );
}

export default App;
