import React from 'react';
import { FormModal } from '../../../components/Modal/FormModal';
import { Input } from '../../../components/common/form';
import { formRejectedSchema } from '../helper/formSchema';
import { FormikContextType } from 'formik';

type RejectFormModalType = {
  formRejected: React.MutableRefObject<FormikContextType<{}> | undefined>;
  handleSubmitForm: () => void;
  visible: boolean;
  confirmLoading: boolean;
  onCancel: () => void;
};

const RejectFormModal: React.FC<RejectFormModalType> = props => {
  const { formRejected, handleSubmitForm, visible, confirmLoading, onCancel } =
    props;
  return (
    <FormModal
      formRef={formRejected}
      initialValuesForm={{ rejected_reason: '' }}
      validationFormSchema={formRejectedSchema}
      title="Remarks"
      visible={visible}
      width={430}
      okText="Submit"
      onOk={handleSubmitForm}
      confirmLoading={confirmLoading}
      cancelText={<div />}
      onCancel={confirmLoading ? undefined : onCancel}
      destroyOnClose
    >
      <div className="w-[367px]">
        <Input
          type="textArea"
          name="rejected_reason"
          rows={6}
          placeholder="Please type reason of rejection so that maker knows where to ammend ..."
        />
      </div>
    </FormModal>
  );
};

export default RejectFormModal;
