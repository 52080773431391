import React, { useMemo, useState } from 'react';
import { ColumnsType } from 'antd/lib/table/interface';
import { Descriptions, DescriptionsItem, FilterIcon, Table } from 'fave-ui';
import { OutletsModalButton } from '../../../components/OutletModalButton/OutletModalButton';
import { useFetchCompanyOutlets } from '../../../services/useFetchCompanyOutlets';
import { ProductTabsProps } from '../ProductDashboardView';
import { StatusTag } from '../../../components/common/StatusTag';
import { TableHeaderSort } from '../../../components/common/TableHeaderSort';
import { useFetchBDUsersList } from '../../../services/ProductDashboard/bdUsersList';
import {
  useFetchECardsList,
  useFetchECardsSummary,
} from '../../../services/ProductDashboard/eCardsTabAPI';
import { TableSettingsType } from '../../../types/configTypes';
import {
  calcTableHeight,
  calcTableWidth,
  createOnTableChangeHandler,
  dateOrDash,
  formatLongNumbersWithCommas,
  formatDateRange,
  formatFilter,
} from '../../../utils/utilFunctions';
import style from '../style.module.css';
import { ECardsType } from '../../../types/dataTypes';

const tableHeight = calcTableHeight();
const tableWidth = calcTableWidth();

const tableConfigDefault = {
  page: 1,
  limit: 10,
  sort_order: undefined,
  sort_by: undefined,
  filter: undefined,
};

export const ECardsTab: React.FC<ProductTabsProps> = ({ company_id }) => {
  const [tableConfig, setTableConfig] =
    useState<TableSettingsType>(tableConfigDefault);

  const { data: eCardsSummaryData } = useFetchECardsSummary({
    company_id,
  });
  const { data: bdUsersListData } = useFetchBDUsersList({
    company_id,
  });

  const { data: eCardsOutletsList } = useFetchCompanyOutlets({
    company_id,
    scope: 'favepay',
  });

  const { data: eCardsListData, isFetching } = useFetchECardsList({
    company_id,
    page: tableConfig.current,
    sort_order: tableConfig.sort_order,
    sort_by: tableConfig.sort_by,
    filter: tableConfig.filter,
  });

  const descriptionItems = [
    {
      key: '1',
      label: 'Total eCards',
      children: eCardsSummaryData?.total_ecards,
    },
    {
      key: '2',
      label: 'Active eCards',
      children: eCardsSummaryData?.active_ecards,
    },
    {
      key: '3',
      label: 'Inactive eCards',
      children: eCardsSummaryData?.inactive_ecards,
    },
    {
      key: '4',
      label: 'Scheduled eCards',
      children: eCardsSummaryData?.scheduled_ecards,
    },
    {
      key: '5',
      label: 'Draft eCards',
      children: eCardsSummaryData?.draft_ecards,
    },
    {
      key: '6',
      label: 'No. of Outlets',
      children: eCardsOutletsList?.outlets && (
        <OutletsModalButton outlets={eCardsOutletsList.outlets} />
      ),
    },
  ];

  const statusFilters = [
    { text: `Active (${eCardsSummaryData?.active_ecards})`, value: 'active' },
    {
      text: `Inactive (${eCardsSummaryData?.inactive_ecards})`,
      value: 'inactive',
    },
    {
      text: `Scheduled (${eCardsSummaryData?.scheduled_ecards})`,
      value: 'scheduled',
    },
    { text: `Draft (${eCardsSummaryData?.draft_ecards})`, value: 'draft' },
  ];

  const eCardsTabColumns: ColumnsType<ECardsType> = [
    {
      dataIndex: 'signature_date',
      title: 'Date Signed',
      width: 135,
      render: date => dateOrDash(date),
    },
    {
      dataIndex: 'bd_name',
      title: 'Signed by',
      filters: bdUsersListData
        ? bdUsersListData.map(item => {
            return { text: item.bd_name, value: item.bd_email };
          })
        : [],
      filterIcon: FilterIcon,
      filterMode: 'tree',
      filterSearch: true,
      width: 130,
      render: data => data || 'N/A',
    },
    {
      dataIndex: 'status',
      title: 'eCard Status',
      filterIcon: FilterIcon,
      width: 145,
      filters: statusFilters,
      filterMode: 'tree',
      render: data => <StatusTag status={data} />,
    },
    {
      dataIndex: 'id',
      title: TableHeaderSort('eCard ID'),
      sorter: true,
      width: 120,
      render: data => data,
    },
    {
      dataIndex: 'payable_amount',
      title: 'Payable Amount',
      width: 150,
      render: data => data,
    },
    {
      dataIndex: 'bonus_credit_amount',
      title: 'Bonus Credit',
      width: 150,
      render: data => data,
    },
    {
      dataIndex: 'commission_rate_percent',
      title: 'Commission Rate',
      width: 150,
      render: data => `${data}%`,
    },
    {
      title: 'Feature Period',
      width: 236,
      render: data =>
        data && data.start_date && data.end_date
          ? formatDateRange(data.start_date, data.end_date)
          : 'N/A',
    },
    {
      title: 'Sold Count / Capacity',
      width: 180,
      render: data =>
        data && data.sold_count >= 0 && data.capacity
          ? `${formatLongNumbersWithCommas(
              data.sold_count,
            )}/${formatLongNumbersWithCommas(data.capacity)}`
          : 'N/A',
    },
  ];

  const tableChangeHandler = useMemo(
    () =>
      createOnTableChangeHandler(setTableConfig, filters =>
        formatFilter([
          { key: 'bd_email', value: filters.bd_name },
          { key: 'status', value: filters.status },
        ]),
      ),
    [setTableConfig],
  );

  return (
    <div className={style.ecardsTab}>
      <Descriptions
        title={'eCards Summary'}
        layout="vertical"
        colon={false}
        column={5}
        className={style.descriptions}
      >
        {descriptionItems.map(({ key, ...item }) => (
          <DescriptionsItem key={key} {...item} />
        ))}
      </Descriptions>
      <Table
        size={'large'}
        dataSource={eCardsListData?.ecards}
        rowKey={row => row.id}
        columns={eCardsTabColumns}
        scroll={{
          x: tableWidth,
          y: tableHeight,
        }}
        loading={isFetching}
        onChange={tableChangeHandler}
        pagination={{
          hideOnSinglePage: true,
          total: eCardsListData?.count,
          position: ['bottomCenter'],
          pageSize: tableConfig.pageSize,
          current: tableConfig.current,
          showSizeChanger: false,
        }}
      />
    </div>
  );
};
