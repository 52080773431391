import { useState } from 'react';
import { defaultTableConfig } from '../../helpers/defaults';
import {
  PeekyBannerItem,
  useDeletePeekyBannerMutation,
  useFetchPeekyBannerListing,
} from '../../services/PeekyBanner/useFetchPeekyBannerListing';
import { TableSettingsType } from '../../types/configTypes';
import {
  createOnTableChangeHandler,
  formatDate,
  formatFilter,
} from '../../utils/utilFunctions';
import { RenderColumn } from '../../helpers/tableHelpers/RenderColumn';
import {
  Button,
  Drawer,
  Dropdown,
  Empty,
  FilterIcon,
  Menu,
  ModalFunctions,
  Notification,
  Row,
  Table,
  UploadFile,
} from 'fave-ui';
import { Card, Col } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { DotsThree, PencilSimpleLine, Plus, Trash } from 'phosphor-react';
import style from './style.module.css';
import {
  PageHeaderWithSpace,
  SearchBarExpandable,
} from '../../components/common';
import { ColumnsType } from 'antd/lib/table';
import PeekyBannerForm from './PeekyBannerForm';

const deleteSuccessNotification = (item?: PeekyBannerItem) => {
  Notification.success({
    message: 'Peeky banner deleted',
    description: `Peeky Banner ${item?.title} successfully deleted`,
  });
};

const PeekyBannersView = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [openDrawer, setOpenDrawer] = useState(false);
  const [editItem, setEditItem] = useState<PeekyBannerItem>();

  const [tableConfig, setTableConfig] =
    useState<TableSettingsType>(defaultTableConfig);

  const tableChangeHandler = createOnTableChangeHandler(
    setTableConfig,
    filters => formatFilter([{ key: 'status', value: filters.status }]),
  );

  const { data: peekyBannerListing, isFetching } = useFetchPeekyBannerListing({
    page: tableConfig.page,
    limit: tableConfig.limit,
    sort_order: tableConfig.sort_order,
    sort_by: tableConfig.sort_by,
    filter: `name=${searchQuery}|${tableConfig.filter ?? ''}`,
  });

  const deletePeekyBannerModal = (item: PeekyBannerItem) => {
    ModalFunctions.confirm({
      title: 'Delete Peeky Banner?',
      icon: <Trash size={24} />,
      hasIcon: true,
      content: (
        <div>
          <span className={style.modalPrimary}>{item.title}</span>
          <div>Are you sure to delete the peeky banner above?</div>
        </div>
      ),
      okText: 'Confirm',
      cancelText: 'Cancel',
      onOk: () => handleDeletePeekyBanner(item),
    });
  };

  const { mutateAsync: deletePeekyBanner } = useDeletePeekyBannerMutation();

  const handleDeletePeekyBanner = (item: PeekyBannerItem) =>
    deletePeekyBanner(item.id, {
      onSuccess: res => {
        deleteSuccessNotification(res.data);
      },
    });

  const handleEditPeekyBanner = (item: PeekyBannerItem) => {
    const defaultUploadFile: UploadFile = {
      uid: 'default-uid',
      name: 'default-file',
      url: item.app_image_url,
    };
    item = { ...item, app_image: [defaultUploadFile] };
    setEditItem(item);
  };

  const peekyBannerListingColumns: ColumnsType<PeekyBannerItem> = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: 93,
      render: RenderColumn.rowText,
    },
    {
      title: 'Title',
      dataIndex: 'title',
      width: 262,
      render: RenderColumn.rowText,
    },
    {
      title: 'Deeplink',
      dataIndex: 'deeplink',
      width: 200,
      render: RenderColumn.rowText,
    },
    {
      title: 'Start Date',
      dataIndex: 'start_date',
      width: 200,
      render: start_date => {
        return formatDate(start_date);
      },
    },
    {
      title: 'End Date',
      dataIndex: 'end_date',
      width: 200,
      render: end_date => {
        return formatDate(end_date);
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      filterIcon: FilterIcon,
      filters: [
        { text: `Active`, value: 'active' },
        { text: `Inactive`, value: 'inactive' },
      ],
      width: 127,
      render: RenderColumn.bannerStatusTag,
    },
    {
      title: 'Actions',
      key: 'action',
      width: 60,
      render: (row: PeekyBannerItem) => {
        const actionItems: ItemType[] = [
          {
            key: 'edit',
            label: 'Edit',
            icon: <PencilSimpleLine size={16} />,
            onClick: () => handleEditPeekyBanner(row),
          },
          {
            key: 'delete',
            label: 'Delete',
            icon: <Trash size={16} />,
            onClick: () => deletePeekyBannerModal(row),
          },
        ];

        const menu = <Menu items={actionItems} />;

        return (
          <Dropdown overlay={menu} trigger={['click']}>
            <Button
              type={'link'}
              className={style.btnText}
              onClick={event => event.preventDefault()}
            >
              <DotsThree size={32} />
            </Button>
          </Dropdown>
        );
      },
      fixed: 'right',
    },
  ];

  return (
    <>
      <PageHeaderWithSpace
        title={'Peeky Banner Tool'}
        subTitle={'One-stop to manage Peeky Banners'}
      />

      <Card style={{ width: '100%' }}>
        <Row className={style.summaryWrapper}>
          <Col>
            <h2 className={style.h2}>{'Peeky Banner'}</h2>
          </Col>
          <Col className={style.actionsCol}>
            <Row>
              <SearchBarExpandable
                onSearch={keyword => setSearchQuery(keyword.toLowerCase())}
              />
              <Button
                icon={<Plus className="icon-start" size={16} />}
                iconPlacement="start"
                onClick={() => setOpenDrawer(true)}
                type="primary"
              >
                Add Peeky Banner
              </Button>
            </Row>
          </Col>
        </Row>
      </Card>

      <Row>
        <Table
          size="large"
          rowKey={row => row.id}
          loading={isFetching}
          columns={peekyBannerListingColumns}
          onChange={tableChangeHandler}
          dataSource={peekyBannerListing?.peeky_banners}
          className={style.table}
          pagination={{
            total: peekyBannerListing?.count,
            position: ['bottomCenter'],
            pageSize: tableConfig.pageSize,
            current: tableConfig.current,
            showSizeChanger: false,
          }}
          locale={{
            emptyText: !isFetching && <Empty emptyType="cant-find-anything" />,
          }}
        />
      </Row>

      <Drawer
        visible={openDrawer || !!editItem}
        onClose={() => {
          setOpenDrawer(false);
          setEditItem(undefined);
        }}
        maskClosable={false}
        extra={
          <Button
            type="text"
            onClick={() => {
              setOpenDrawer(false);
              setEditItem(undefined);
            }}
          >
            Cancel
          </Button>
        }
        title={editItem ? 'Edit Peeky Banner' : 'Add New Peeky Banner'}
        placement="right"
      >
        <PeekyBannerForm
          onSuccessCb={() => {
            setOpenDrawer(false);
            setEditItem(undefined);
          }}
          initialData={
            editItem && {
              id: editItem.id,
              title: editItem.title,
              deeplink: editItem.deeplink,
              start_date: editItem.start_date,
              end_date: editItem.end_date,
              status: editItem.status,
              app_image: editItem.app_image,
              adtech_campaign_id: editItem.adtech_campaign_id,
            }
          }
        />
      </Drawer>
    </>
  );
};

export default PeekyBannersView;
