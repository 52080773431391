import { useQuery } from 'react-query';
import {
  PaginatedPayloadBaseType,
  PayloadBaseType,
} from '../../types/configTypes';
import { DealsType } from '../../types/dataTypes';
import { createFetchAPI } from '../http';

type FetchDealsSummaryResponseType = {
  total_deals: number;
  live_deals: number;
  scheduled_deals: number;
  inactive_deals: number;
  expired_deals: number;
};

type FetchDealsSummaryPayloadType = PayloadBaseType;

type FetchDealsListResponseType = {
  deals: DealsType[];
  count: number;
};

type FetchDealsListPayloadType = PaginatedPayloadBaseType;

const fetchDealsSummary = createFetchAPI<null, FetchDealsSummaryResponseType>(
  '/v1/deals/product_summary',
);

const fetchDealsList = createFetchAPI<
  FetchDealsListPayloadType,
  FetchDealsListResponseType
>('/v1/deals');

export const useFetchDealsSummary = ({
  company_id,
}: FetchDealsSummaryPayloadType) => {
  return useQuery<FetchDealsSummaryResponseType>(
    ['fetchDealsSummary', { company_id }],
    fetchDealsSummary,
  );
};

export const useFetchDealsList = (props: FetchDealsListPayloadType) => {
  return useQuery<FetchDealsListResponseType>(
    ['fetchDealsList', { ...props, dataset: 'outlets' }],
    fetchDealsList,
  );
};
