import { Spin as AntSpin, SpinProps } from 'antd';
import style from './style.module.css';

const SpinOverlay: React.FC<SpinProps> = props => (
  <div className={style.spinContainer}>
    <AntSpin {...props} />
  </div>
);

export default SpinOverlay;
