import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'fave-ui/dist/cjs/index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { isDevelopment } from './helpers/env';
import cookieSet from './helpers/cookieSet';
import { TenantCodeType } from './contexts/TenantContext';
import { validateTenant } from './services/auth';
import { Message } from 'fave-ui';

if (isDevelopment) require('./mocks/setup');

const urlSearchParams = new URLSearchParams(window.location.search);
const params: { tenant_code?: TenantCodeType } = Object.fromEntries(
  urlSearchParams.entries(),
);

if (params.tenant_code) {
  const isAuthenticated = cookieSet.auth.get();

  if (isAuthenticated) {
    const isValidTenant = validateTenant(params.tenant_code);

    if (isValidTenant) cookieSet.currentTenant.set(params.tenant_code);
    else
      Message.error({
        content: `This tenant: ${params.tenant_code} is not valid for the current user`,
      });
  } else cookieSet.currentTenant.set(params.tenant_code);
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
