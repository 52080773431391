import { FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router';
import { Notification, PopConfirm, Button } from 'fave-ui';
import { Col, Row } from 'antd';
import Form from '../../../../components/common/form/Form';
import { Select } from '../../../../components/common/form/Select';
import { Input } from '../../../../components/common/form/Input';
import SubmitButton from '../../../../components/common/form/SubmitButton';
import { StatusType } from '../../../../types/dataTypes';
import { PageHeaderWithBackButton } from '../../../../components/common/PageHeaderWithBackButton';
import {
  useFetchCampaignOptions,
  useAddCampaignMutation,
} from '../../../../services/FaveArcade/useFetchCampaignListing';
import { arrayToOptions } from '../../../../utils/utilFunctions';

type CampaignFormFields = {
  id?: number;
  campaign_name: string;
  campaign_type: string;
  product_types: string[];
  payment_method_types: string[];
  condition_type: string;
  amount_cents: number | undefined;
  no_of_ticket: number | undefined;
  isLoading?: boolean;
  isError?: boolean;
  status?: StatusType;
};

const initialValues: CampaignFormFields = {
  campaign_name: '',
  campaign_type: '',
  product_types: [],
  payment_method_types: [],
  condition_type: '>',
  amount_cents: undefined,
  no_of_ticket: undefined,
};

const CampaignFormSchema = Yup.object().shape({
  campaign_name: Yup.string()
    .min(1)
    .required('Please give your campaign a name'),
  campaign_type: Yup.string().required(
    'Please select at least one ticket campaign',
  ),
  product_types: Yup.array().of(
    Yup.string().required('Please select at least one product'),
  ),
  payment_method_types: Yup.array().of(
    Yup.string().required('Please select at least one payment method'),
  ),
  condition_type: Yup.string().required('Please select one condition'),
  amount_cents: Yup.number()
    .min(1)
    .required('Please enter the amount of discount'),
  no_of_ticket: Yup.number()
    .min(1)
    .required('Please enter enter the number of tickets'),
});

const successNotification = () => {
  Notification.success({
    message: 'New Campaign Submitted!',
    description:
      'Your new submission has been added too Fave Arcade ticket center succesfully',
  });
};

export default function NewCampaign() {
  const { mutateAsync: addNewCampaign } = useAddCampaignMutation();
  const { data: optionsData } = useFetchCampaignOptions();
  const navigate = useNavigate();
  const campaignTypes =
    optionsData && arrayToOptions(optionsData?.campaign_types, '_');
  const productTypes =
    optionsData && arrayToOptions(optionsData?.product_types, '_');
  const paymentMethods =
    optionsData && arrayToOptions(optionsData?.payment_methods, '_');
  const conditions =
    optionsData && arrayToOptions(optionsData?.condition_types, '_');

  const handleSubmit = async (
    values: CampaignFormFields,
    { setSubmitting }: FormikHelpers<CampaignFormFields>,
  ) => {
    const payload = {
      ...values,
      amount_cents: (values.amount_cents as number) * 100,
    };
    try {
      await addNewCampaign(
        { ...payload },
        {
          onSuccess: () => {
            successNotification();
          },
        },
      );
    } finally {
      setSubmitting(false);
      navigate('/fave_arcade/ticket_center');
    }
  };

  return (
    <>
      <PageHeaderWithBackButton title={`Create New Ticket`} />
      <Form
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={CampaignFormSchema}
        validateOnMount
        validateOnChange
        validateOnSchemaChange
      >
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Input
              name="campaign_name"
              label="Campaign Name"
              placeholder="Enter campaign name"
              allowClear
              bordered
              style={{ marginBottom: 0 }}
            />
          </Col>
          <Col span={24}>
            <Select
              name="campaign_type"
              label="Ticket Campaign"
              placeholder="Please Select one option"
              options={campaignTypes}
            />
          </Col>
          <Col span={24}>
            <Select
              name="product_types"
              label="Product"
              placeholder="Please select one or more options"
              showArrow
              withCheckbox
              treeDefaultExpandAll
              style={{ width: '100%' }}
              options={productTypes}
            />
          </Col>
          <Col span={24}>
            <Select
              name="payment_method_types"
              label="Payment Method"
              placeholder="Please select one or more options"
              showArrow
              withCheckbox
              treeDefaultExpandAll
              style={{ width: '100%' }}
              options={paymentMethods}
            />
          </Col>
          <Col span={5}>
            <Select
              name="condition_type"
              label="Condition"
              placeholder="Please select"
              defaultValue=">"
              options={conditions}
            />
          </Col>
          <Col span={19}>
            <Input
              type="number"
              name="amount_cents"
              label={`Amount In ${optionsData?.currency}`}
              placeholder={`Enter the amount in ${optionsData?.currency}`}
              allowClear
              bordered
            />
          </Col>
          <Col span={24}>
            <Input
              type="number"
              name="no_of_ticket"
              label="Issue Tickets"
              placeholder="Enter number of tickets"
              allowClear
              bordered
              style={{ marginBottom: 0 }}
            />
          </Col>
          <Col span={24}>
            <SubmitButton disableIfNotValid disableIfNotDirty>
              {(_, isSubmitting) => (isSubmitting ? 'Submiting...' : 'Save')}
            </SubmitButton>
            <PopConfirm
              title="Are you sure you want to cancel? All progress will be lost for the current campaign."
              onConfirm={() => navigate('/fave_arcade/ticket_center')}
              okText="Yes, I am sure"
              cancelText="Go Back"
            >
              <Button type="primary" size="large">
                Cancel
              </Button>
            </PopConfirm>
          </Col>
        </Row>
      </Form>
    </>
  );
}
