import { PropsWithChildren } from 'react';
import clsx from 'clsx';

import style from './style.module.css';

type ContainerProps = {
  className?: string;
};

const Container = ({
  className,
  children,
}: PropsWithChildren<ContainerProps>) => (
  <div className={clsx(style.container, className)}>{children}</div>
);

export default Container;
