import React from 'react';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { Button, ButtonProps, Dropdown, Menu, Popover } from 'fave-ui';
import { Info } from 'phosphor-react';
import {
  EnabledStatusType,
  CampaignStatusType,
  MySubmissionStatusType,
  BannerStatusType,
} from '../../types/dataTypes';
import {
  MerchantStatusTag,
  OnOffStatusTag,
  CampaignStatusTag,
  MySubmissionsStatusTag,
  BannerStatusTag,
} from '../../components/common/StatusTag';
import { TooltipPlacement } from 'antd/lib/tooltip';
import FaveIconPinkSVG from '../../assests/icons/FaveIconPinkSVG';
import FastPayIconSVG from '../../assests/icons/FastPayIconSVG ';
import style from './style.module.css';
import { valueOrDash } from '../../utils/utilFunctions';
import { DropdownProps } from 'antd';
import {
  BDDealsType,
  BDPaymentDetailsType,
  ECardsActionsType,
  MCContractSubmissionsType,
  ProductType,
} from '../../services/MySubmissions';
import SubmissionStatusWithPopover from '../../views/MySubmissionView/helpers/SubmissionStatus';

type InfoPopoverType = {
  title?: string;
  text?: string;
  content: React.ReactNode | string;
  popoverPlacement?: TooltipPlacement;
};

type MappedTextType = {
  name: string;
  id: number;
};

export const RenderColumn = {
  merchantType: (type: string) =>
    type === 'fastpay' ? <FastPayIconSVG /> : <FaveIconPinkSVG />,
  products: (products: string[]) => {
    const hasProduct = products.length > 0;
    const content = (
      <div>
        {products.map((item: string, index: number) => {
          return <p key={index}>{item}</p>;
        })}
      </div>
    );

    return (
      <>
        {hasProduct ? (
          <Popover
            title="Products"
            content={content}
            trigger={'hover'}
            placement={'bottom'}
          >
            <div className={style.productPopover}>
              {products.length} {products.length > 1 ? 'products' : 'product'}
              <Info style={{ marginLeft: '6px' }} size={16} />
            </div>
          </Popover>
        ) : (
          <>0 product</>
        )}
      </>
    );
  },
  campaignStatusTag: (status: CampaignStatusType) => (
    <CampaignStatusTag status={status} />
  ),
  merchantStatus: (status: string) => <MerchantStatusTag status={status} />,
  onOffStatus: (status: EnabledStatusType) => (
    <OnOffStatusTag status={status} />
  ),
  bannerStatusTag: (status: BannerStatusType) => (
    <BannerStatusTag status={status} />
  ),
  rowText: (text?: string) => valueOrDash(text),
  customRowText: (customText: string) => (text?: string) =>
    valueOrDash(text, customText),
  mappedText: (arrayText?: MappedTextType[]) =>
    arrayText?.map(item => (
      <p key={item.id} className={style.mappedText}>
        {item.name}
      </p>
    )) || '-',
  dropdownMenu: (
    menuItems: ItemType[],
    dropdownContent: string | React.ReactElement = 'Actions',
    placement: DropdownProps['placement'] = 'bottomLeft',
    dataId = '', //todo: deprecate
    options?: {
      buttonProps?: ButtonProps & { 'data-id'?: string };
      // add other options here
    },
  ) => {
    const menu = <Menu items={menuItems} />;

    return (
      <Dropdown
        disabled={options?.buttonProps?.disabled}
        overlay={menu}
        trigger={['hover']}
        placement={placement}
      >
        <Button
          type={'text'}
          data-id={dataId} //todo: deprecate
          {...options?.buttonProps}
          onClick={e => e.preventDefault()}
        >
          {dropdownContent}
        </Button>
      </Dropdown>
    );
  },
  // todo: refactor RenderColumn.infoPopover to accept children, title, content at the same time
  // while still being able to show/hide the icon externally
  infoPopover: ({
    title,
    text,
    content,
    popoverPlacement = 'bottom',
  }: InfoPopoverType) => {
    const showIcon = !!content;
    return (
      <Popover
        title={title}
        content={content}
        trigger={'hover'}
        placement={popoverPlacement}
      >
        <div className={style.productPopover}>
          {text}
          {showIcon && <Info style={{ marginLeft: '6px' }} size={16} />}
        </div>
      </Popover>
    );
  },
  componentOrDash: ({
    value,
    Component,
  }: {
    value?: string | boolean;
    Component: React.ReactNode;
  }) => (value ? Component : '-'),
  mySubmissionsStatusPopover: (
    status: MySubmissionStatusType,
    data:
      | BDPaymentDetailsType
      | BDDealsType
      | ECardsActionsType
      | MCContractSubmissionsType,
    productType: ProductType,
  ) => {
    const shouldRenderStatusTagComponent =
      status !== 'Pending Operations' && status !== 'Contract Signed';

    return shouldRenderStatusTagComponent ? (
      <MySubmissionsStatusTag status={status} />
    ) : (
      <SubmissionStatusWithPopover productType={productType} data={data} />
    );
  },
};
