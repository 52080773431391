import React, { useContext } from 'react';
import { Navigate, useLocation } from 'react-router';
import { createSearchParams, useSearchParams } from 'react-router-dom';
import { UserContext } from '../../contexts/UserContext';

export const RedirectToLoginPageView: React.FC = ({ children }) => {
  const { isAuthenticated, isManualLogout } = useContext(UserContext);
  const location = useLocation();
  const [queryString] = useSearchParams();
  const restParams = Object.fromEntries(queryString);
  const params = {
    from: location.pathname,
    ...restParams,
  };

  // TODO: need to refactor, below is too hacky to handle checking if user manual / auto logout, need to set isManualLogout value first
  // before changing isAuthenticated in UserContext, else logout URL will have flashes. proper way to handle should be done either in Routes or other place.
  if (!isAuthenticated)
    return (
      <Navigate
        to={isManualLogout ? '/login' : `/login?${createSearchParams(params)}`}
        replace
      />
    );
  else return <>{children}</>;
};

export const RedirectToAuthenticatedPagesView: React.FC = ({ children }) => {
  const { isAuthenticated } = useContext(UserContext);
  const [queryString] = useSearchParams();
  const queryStringObject = Object.fromEntries(queryString);
  const { from, ...restQueryString } = queryStringObject;

  // redirect user to link with query strings
  const redirectLink = from
    ? `${from}?${createSearchParams(restQueryString)}`
    : '/';

  if (isAuthenticated) return <Navigate to={redirectLink} replace />;
  else return <>{children}</>;
};
