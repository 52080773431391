import { useQuery } from 'react-query';
import { UserType } from '../types/dataTypes';
import { createFetchAPI } from './http';

export type UserSettingsResponseType = {
  [key: string]: UserPagePermission;
};

export type UserPagePermission = {
  show: boolean;
  create?: boolean;
  update?: boolean;
  delete?: boolean;
  fastpay_onboarding?: boolean;
  update_payment_modes_and_application_fee?: boolean;
  ipp_onboarding?: boolean;
  update_ipp?: boolean;
  update_mid?: boolean;
  view_outlets_of_eagle_activity?: boolean;
  link_outlets_to_eagle_activity?: boolean;
  unlink_outlets_from_eagle_activity?: boolean;
  show_outlet_payment_modes?: boolean;
  update_outlet_payment_modes?: boolean;
  hello_my_submissions?: boolean;
  download?: boolean;
};

const fetchUserPermissions = createFetchAPI<{}, UserSettingsResponseType>(
  '/v1/settings',
);

const useFetchUserPermissions = (user: UserType | undefined) =>
  useQuery<UserSettingsResponseType>(
    'fetchUserPermission',
    fetchUserPermissions,
    {
      enabled: !!user,
    },
  );

export default useFetchUserPermissions;
