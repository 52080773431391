import React, { useEffect, useState } from 'react';
import { Modal as FaveModal, ModalProps, TextArea } from 'fave-ui';

type AutoSizeType = {
  minRows?: number;
  maxRows?: number;
};

type TextAreaModalProps = ModalProps & {
  onConfirmClick: (text: string) => void;
  placeholderText?: string;
  textAreaAutoSize?: boolean | AutoSizeType | undefined;
};

export const TextAreaModal: React.FC<TextAreaModalProps> = ({
  onConfirmClick,
  placeholderText,
  textAreaAutoSize = { minRows: 4, maxRows: 4 },
  okButtonProps,
  visible = false,
  ...restProps
}) => {
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    if (!visible) setInputValue('');
  }, [visible]);

  return (
    <FaveModal
      {...restProps}
      centered
      visible={visible}
      okButtonProps={{
        ...okButtonProps,
        onClick: () => onConfirmClick(inputValue),
        disabled: !(inputValue.length > 0),
      }}
    >
      <TextArea
        value={inputValue}
        onChange={val => {
          setInputValue(val.target.value);
        }}
        placeholder={placeholderText}
        autoSize={textAreaAutoSize}
      />
    </FaveModal>
  );
};
