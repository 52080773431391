import { Button } from 'fave-ui';
import { ArrowLeft } from 'phosphor-react';
import * as React from 'react';
import { useNavigate } from 'react-router';
import {
  PageHeaderWithSpace,
  PageHeaderWithSpaceProps,
} from './PageHeaderWithSpace/PageHeaderWithSpace';

type PageHeaderWithBackButtonProps = PageHeaderWithSpaceProps;

export const PageHeaderWithBackButton: React.FC<
  PageHeaderWithBackButtonProps
> = ({ title, subTitle, additionalContent }) => {
  const navigate = useNavigate();

  return (
    <PageHeaderWithSpace
      additionalContent={additionalContent}
      backIcon={
        <Button
          shape="circle"
          size="large"
          icon={<ArrowLeft size={16} className="circle" />}
        />
      }
      onBack={() => navigate(-1)}
      title={title}
      subTitle={subTitle}
    />
  );
};
