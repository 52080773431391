import { useQuery } from 'react-query';
import { createFetchAPI } from '../http';

export type FetchBDUsersListResponseType = {
  bd_name: string;
  bd_email: string;
  id: number;
};

type FetchBdListParamType = {
  company_id: number;
};

const fetchBDUsersList = createFetchAPI<null, FetchBDUsersListResponseType[]>(
  '/v1/mc_contract_submissions/bd_users',
);

export const useFetchBDUsersList = ({ company_id }: FetchBdListParamType) =>
  useQuery<FetchBDUsersListResponseType[]>(
    ['fetchBDUsersList', { company_id }],
    fetchBDUsersList,
  );
