import { Notification, UploadFile } from 'fave-ui';
import * as Yup from 'yup';
import {
  useAddPeekyBannerMutation,
  useEditPeekyBannerMutation,
} from '../../services/PeekyBanner/useFetchPeekyBannerListing';
import { FormikHelpers } from 'formik';
import {
  appendFormData,
  arrayToOptions,
  formatDate,
} from '../../utils/utilFunctions';
import { Col, Row } from 'antd';
import { Input } from '../../components/common/form/Input';
import { Select } from '../../components/common/form/Select';
import Form from '../../components/common/form/Form';
import DatePicker from '../../components/common/form/DatePicker';
import ImageUpload from '../../components/common/form/ImageUpload';
import { SubmitButton } from '../../components/common/form';

type PeekyBannerFormFields = {
  id?: number;
  title: string;
  deeplink: string;
  start_date: string;
  end_date: string;
  status: string;
  app_image?: UploadFile[];
  adtech_campaign_id?: string;
};

const initialValues: PeekyBannerFormFields = {
  title: '',
  deeplink: '',
  start_date: '',
  end_date: '',
  status: 'inactive',
  app_image: [],
};

const PeekyBannerFormSchema = Yup.object().shape({
  title: Yup.string().required('Please give your peeky banner a name'),
  deeplink: Yup.string().required('Please give your peeky banner a deeplink'),
  start_date: Yup.date()
    .typeError('Please select a start date')
    .required('Please select a start date'),
  end_date: Yup.date()
    .typeError('Please select an end date')
    .min(Yup.ref('start_date'), 'End date must be before start date')
    .required('Please select an end date'),
  app_image: Yup.array()
    .of(Yup.mixed().nullable().required('An image is required'))
    .min(1, 'Please upload an image'),
});

const successNotification = () => {
  Notification.success({
    message: 'New Peeky Banner Submitted!',
    description:
      'Your new submission has been added to Peeky Banner Tool successfully',
  });
};

const editSuccessNotification = () => {
  Notification.success({
    message: 'Peeky Banner Edited',
    description: 'Your edit to the peeky banner has been saved successfully',
  });
};

export default function PeekyBannerForm({
  onSuccessCb,
  initialData,
}: {
  onSuccessCb?: () => void;
  initialData?: PeekyBannerFormFields;
}) {
  const { mutateAsync: addNewPeekyBanner } = useAddPeekyBannerMutation();
  const { mutateAsync: editPeekyBanner } = useEditPeekyBannerMutation();
  const statusTypes = arrayToOptions(['active', 'inactive'], '_');

  const handleSubmit = async (
    formValues: PeekyBannerFormFields,
    { setSubmitting }: FormikHelpers<PeekyBannerFormFields>,
  ) => {
    const payload = {
      ...formValues,
      start_date: formatDate(formValues.start_date, 'yyyy-MM-d'),
      end_date: formatDate(formValues.end_date, 'yyyy-MM-d'),
      app_image:
        formValues.app_image &&
        formValues.app_image.length > 0 &&
        formValues.app_image[0].originFileObj != undefined
          ? formValues.app_image[0].originFileObj
          : [],
    };
    const formData = appendFormData(payload);

    try {
      if (initialData) {
        await editPeekyBanner(
          { id: payload.id, formData: formData },
          {
            onSuccess: () => {
              editSuccessNotification();
              onSuccessCb?.();
            },
          },
        );
      } else {
        await addNewPeekyBanner(
          { formData },
          {
            onSuccess: () => {
              successNotification();
              onSuccessCb?.();
            },
          },
        );
      }
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Form
      initialValues={initialData ?? initialValues}
      onSubmit={handleSubmit}
      validationSchema={PeekyBannerFormSchema}
      validateOnMount
      validateOnChange
      validateOnSchemaChange
    >
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Input
            name="title"
            label="Title"
            placeholder="title!"
            allowClear
            bordered
            style={{ marginBottom: 0 }}
          />
        </Col>
        <Col span={24}>
          <Input
            name="deeplink"
            label="Deeplink"
            placeholder="deeplink!"
            allowClear
            bordered
            style={{ marginBottom: 0 }}
          />
        </Col>
        <Col span={24}>
          <DatePicker
            name="start_date"
            label="Start Date"
            allowClear
            bordered
          />
        </Col>
        <Col span={24}>
          <DatePicker name="end_date" label="End Date" allowClear bordered />
        </Col>
        <Col span={24}>
          <Select
            name="status"
            label="Status"
            placeholder="Select one"
            options={statusTypes}
          />
        </Col>
        <Col span={24}>
          <ImageUpload
            name="app_image"
            label="App Image"
            types={['image/jpeg', 'image/png']}
            imgNumber={1}
            defaultDisplayImgList={
              initialData && initialData !== undefined
                ? initialData.app_image
                : []
            }
          />
        </Col>
        <Col span={24}>
          <Input
            name="adtech_campaign_id"
            label="Adtech Campagin ID"
            placeholder="adtech_campaign_id!"
            allowClear
            bordered
            style={{ marginBottom: 0 }}
          />
        </Col>

        <div>
          <SubmitButton disableIfNotValid disableIfNotDirty>
            {(_, isSubmitting) =>
              isSubmitting ? 'Submiting...' : initialData ? 'Save' : 'Submit'
            }
          </SubmitButton>
        </div>
      </Row>
    </Form>
  );
}
