import clsx from 'clsx';
import {
  FormItem,
  Input as FaveInput,
  InputProps as FaveInputProps,
  Checkbox,
  Dragger,
  UploadProps,
  DraggerProps,
  TextArea,
  TextAreaProps,
} from 'fave-ui';
import { getIn, useFormikContext } from 'formik';
import { Paperclip } from 'phosphor-react';
import { useMemo } from 'react';
import { showRemoveFileModal } from '../../../helpers/formHelpers';

import styles from './style.module.css';

export type InputProps<T> = FaveInputProps &
  TextAreaProps &
  Pick<UploadProps<T>, 'onRemove' | 'fileList'> &
  Pick<DraggerProps, 'Text'> & {
    name: keyof T;
    allowClear?: boolean;
    placeholder?: string;
    bordered?: boolean;
    label?: string;
    type?: string;
    help?: string;
    isError?: boolean;
    valuePath?: string;
    uploadURL?: string;
    onChangeCheckbox?: (isChecked: boolean, handleChange: () => void) => void;
    onAdditionBlur?: (e: React.FocusEvent<HTMLInputElement, Element>) => void;
  };

const handleChange = (
  e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => void,
  path?: string,
) => {
  const { name, value, type } = e.target;
  const { valueAsNumber, valueAsDate, checked } = e.target as HTMLInputElement;

  const newValue = valueAsDate || valueAsNumber || value;

  setFieldValue(
    path ? `${path}.${name}` : name,
    type === 'checkbox' ? checked : newValue,
  );
};

export const Input = <T extends {}>({
  className,
  style,
  name,
  allowClear,
  placeholder,
  bordered,
  label,
  type,
  help,
  isError,
  value,
  checked,
  valuePath,
  uploadURL,
  onRemove = file => showRemoveFileModal({ file }),
  onAdditionBlur,
  fileList,
  disabled,
  onChangeCheckbox,
  Text,
  ...restProps
}: InputProps<T>) => {
  const {
    errors,
    touched,
    handleBlur,
    values,
    setFieldValue,
    setFieldTouched,
  } = useFormikContext<T>();

  const innerValue = (values[name] as unknown as string) || value;

  const error = valuePath
    ? getIn(errors, valuePath) && getIn(errors, valuePath)[name]
    : (errors[name] as unknown as string);

  const isTouched = touched[name] as unknown as string;

  const helpText =
    isTouched &&
    (error !== undefined || (isError !== undefined && help !== undefined))
      ? help && isError
        ? help
        : error
      : '';

  const input = useMemo(() => {
    switch (type) {
      case 'checkbox':
        return (
          <Checkbox
            name={name}
            checked={checked}
            onChange={e => {
              if (onChangeCheckbox)
                onChangeCheckbox(e.target.checked, () =>
                  handleChange(
                    e as unknown as React.ChangeEvent<HTMLInputElement>,
                    setFieldValue,
                    valuePath,
                  ),
                );
              else
                handleChange(
                  e as unknown as React.ChangeEvent<HTMLInputElement>,
                  setFieldValue,
                  valuePath,
                );
            }}
            disabled={disabled}
          />
        );
      case 'file':
        return (
          <Dragger
            name={name}
            beforeUpload={() => false}
            action={uploadURL}
            fileList={fileList}
            onChange={info =>
              setFieldValue(
                name,
                info.fileList.length === 0 ? undefined : info.fileList,
              )
            }
            onRemove={onRemove}
            iconRender={() => <Paperclip size={16} />}
            Text={Text}
          />
        );
      case 'textArea':
        return (
          <TextArea
            {...restProps}
            name={name}
            bordered={bordered}
            placeholder={placeholder}
            allowClear={allowClear}
            onChange={e =>
              handleChange(
                e as unknown as React.ChangeEvent<HTMLInputElement>,
                setFieldValue,
                valuePath,
              )
            }
            onBlur={e => {
              handleBlur(e);
              setFieldTouched(name as string, true);
            }}
            value={innerValue}
            className={clsx(className, styles.input)}
            disabled={disabled}
          />
        );
      default:
        return (
          <FaveInput
            {...restProps}
            name={name}
            bordered={bordered}
            placeholder={placeholder}
            allowClear={allowClear}
            onChange={e => handleChange(e, setFieldValue, valuePath)}
            value={innerValue}
            type={type}
            onBlur={e => {
              handleBlur(e);
              setFieldTouched(name as string, true);
              onAdditionBlur && onAdditionBlur(e);
            }}
            className={clsx(className, styles.input)}
            disabled={disabled}
            onWheel={e => {
              if (type === 'number') e.currentTarget.blur();
            }}
          />
        );
    }
  }, [
    name,
    innerValue,
    checked,
    allowClear,
    bordered,
    handleBlur,
    setFieldTouched,
    placeholder,
    setFieldValue,
    type,
    className,
    valuePath,
    handleChange,
    uploadURL,
    onRemove,
    disabled,
  ]);

  // TODO
  // inputs without labels do not show error, need to wrap with FormItem
  // isTouched not triggered with dynamic inputs with valuePath
  // need to investigate why touched not trigger in formik
  return label ? (
    <FormItem
      className={styles.formItem}
      label={label}
      validateStatus={error !== undefined && isTouched ? 'error' : 'success'}
      help={helpText}
      style={style}
    >
      {input}
    </FormItem>
  ) : (
    <FormItem
      validateStatus={error !== undefined && isTouched ? 'error' : 'success'}
      help={helpText}
      style={{ ...style, margin: 0 }}
    >
      {input}
    </FormItem>
  );
};
