import style from './style.module.css';
import * as Yup from 'yup';
import { useNavigate } from 'react-router';
import {
  PopConfirm,
  Button,
  UploadFile,
  Notification,
  Col,
  Row,
} from 'fave-ui';
import Form from '../../../../components/common/form/Form';
import { Input } from '../../../../components/common/form/Input';
import SubmitButton from '../../../../components/common/form/SubmitButton';
import ImageUpload from '../../../../components/common/form/ImageUpload';
import {
  PrizeDataItem,
  useEditPrizeCampaignMutation,
} from '../../../../services/FaveArcade/useFetchPrizesListing';
import { appendFormData } from '../../../../utils/utilFunctions';
import { DatePicker } from '../../../../components/common/form';

type EditLuckyDrawFormFields = Pick<
  PrizeDataItem,
  | 'id'
  | 'campaign_name'
  | 'prize_name'
  | 'prize_subtitle'
  | 'prize_description'
  | 'start_date'
  | 'end_date'
  | 'max_campaign_limit'
  | 'max_user_limit'
  | 'cost_of_token'
  | 'asset_url'
> & { asset: UploadFile[] };

type EditLuckyDrawProps = {
  initialData: PrizeDataItem;
};

const EditLuckyDrawFormSchema = Yup.object().shape({
  campaign_name: Yup.string().required('Please give your prize a name'),
  prize_name: Yup.string().required('Please give your prize a name'),
  prize_subtitle: Yup.string().optional(),
  prize_description: Yup.string().required(
    'Please give your prize a description',
  ),
  start_date: Yup.date()
    .typeError('Please select a start date')
    .required('Please select a start date'),
  end_date: Yup.date()
    .typeError('Please select an end date')
    .min(Yup.ref('start_date'), "End date can't be before start date")
    .required('Please select an end date'),
  max_user_limit: Yup.number()
    .min(1)
    .required('Please enter enter the max entries per user'),
  max_campaign_limit: Yup.number().min(0),
  asset: Yup.array()
    .of(Yup.mixed().nullable().required('An image is required'))
    .min(1, 'Please upload an image'),
});

const successNotification = () =>
  Notification.success({
    message: 'Prize Campaign Updated!',
    description:
      'The prize campaign has been successfully updated. You can view it in the Prize Catalog.',
  });

const EditLuckyDrawForm = ({ initialData }: EditLuckyDrawProps) => {
  const navigate = useNavigate();
  const { mutateAsync: updatePrize } = useEditPrizeCampaignMutation({
    id: initialData?.id,
    type: 'lucky_draw',
  });

  const handleSubmit = async (formValues: EditLuckyDrawFormFields) => {
    const payload = {
      ...formValues,
      asset: formValues?.asset[0].originFileObj,
    };

    // Remove asset if it is not edited (i.e. it is not a File object)
    if (!(payload.asset instanceof File)) {
      delete payload.asset;
    }

    const formData = appendFormData(payload);

    updatePrize(formData, {
      onSuccess: () => {
        successNotification();
        navigate('/fave_arcade/prize_catalog');
      },
    });
  };

  const assetFiles: UploadFile[] = [
    {
      uid: '-1',
      name: 'image.png',
      url: initialData?.asset_url,
      thumbUrl: initialData?.asset_url,
    },
  ];

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={{
        ...initialData,
        validity_day: initialData?.validity_day ?? 0,
        asset: assetFiles,
      }}
      validationSchema={EditLuckyDrawFormSchema}
      validateOnMount
      validateOnChange
      validateOnSchemaChange
    >
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Input
            name="campaign_name"
            label="* Campaign Name"
            placeholder="Enter campaign name"
            allowClear
            bordered
            style={{ marginBottom: 0 }}
          />
        </Col>
        <Col span={24}>
          <Input
            name="prize_name"
            label="* Prize Name"
            placeholder="Enter prize name"
            allowClear
            bordered
            style={{ marginBottom: 0 }}
          />
        </Col>
        <Col span={24}>
          <Input
            name="prize_subtitle"
            label="Prize Subtitle (optional)"
            placeholder="Enter prize subtitle"
            allowClear
            bordered
            style={{ marginBottom: 0 }}
          />
        </Col>
        <Col span={24}>
          <Input
            type="textArea"
            name="prize_description"
            label="* Prize Description"
            placeholder="Enter prize description"
            bordered
            style={{ marginBottom: 0 }}
          />
        </Col>
        <Col span={12}>
          <DatePicker
            name="start_date"
            label="Start Date"
            allowClear
            bordered
          />
        </Col>
        <Col span={12}>
          <DatePicker name="end_date" label="End Date" allowClear bordered />
        </Col>
        <Col span={24}>
          <Input
            type="number"
            name="max_user_limit"
            label="Max Entries per User"
            placeholder="Enter number of max entries"
            allowClear
            bordered
            style={{ marginBottom: 0 }}
          />
        </Col>
        <Col span={24}>
          <Input
            type="number"
            name="max_campaign_limit"
            label="Max entries (campaign total)"
            placeholder="Enter number of campaign max entries"
            min={0}
            allowClear
            bordered
            style={{ marginBottom: 0 }}
          />
        </Col>
        <Col span={24}>
          <Input
            type="number"
            name="cost_of_token"
            label="Price per entry (tokens)"
            placeholder="Enter number of tokens"
            allowClear
            bordered
            style={{ marginBottom: 0 }}
          />
        </Col>
        <Col span={12}>
          <ImageUpload
            name="asset"
            label="* Asset (image)"
            types={['image/jpeg', 'image/png']}
            imgSize={2}
            defaultFileList={assetFiles}
          />
        </Col>

        <Col span={24}>
          <SubmitButton disableIfNotValid disableIfNotDirty>
            {(_, isSubmitting) => (isSubmitting ? 'Updating...' : 'Update')}
          </SubmitButton>
          <PopConfirm
            title="Are you sure you want to cancel?"
            onConfirm={() => navigate('/fave_arcade/prize_catalog')}
            okText="Yes, I am sure"
            cancelText="Go Back"
          >
            <Button type="primary" size="large" className={style.mgLeft}>
              Cancel
            </Button>
          </PopConfirm>
        </Col>
      </Row>
    </Form>
  );
};

export default EditLuckyDrawForm;
