import { ColumnType, SortOrder } from 'antd/lib/table/interface';
import { HeaderWithSort } from 'fave-ui';

type TableHeaderSortType = {
  title: string;
  sortColumns: {
    column: ColumnType<{}>;
    order: SortOrder;
  }[];
};

const Header = (props: TableHeaderSortType) => <HeaderWithSort {...props} />;

export const TableHeaderSort = (title: string, dataId = '') => {
  // refactor due to pre-commit issue
  const SortComponent = ({
    sortColumns,
  }: {
    sortColumns: {
      column: ColumnType<{}>;
      order: SortOrder;
    }[];
  }) => <Header data-id={dataId} title={title} sortColumns={sortColumns} />;

  SortComponent.displayName = 'SortComponent';
  return SortComponent;
};
