import { PropsWithChildren } from 'react';
import style from './style.module.css';

const SectionHeader = ({ children }: PropsWithChildren<{}>) => (
  <div className={style.sectionHeader}>
    <span className={style.sectionText}>{children}</span>
  </div>
);

export default SectionHeader;
