import { FormItem } from 'fave-ui';
import { useFormikContext } from 'formik';
import React from 'react';
import { Checkbox, Input } from '../../../components/common/form';
import RequiredFieldLabel from '../../../components/common/RequiredFieldLabel';
import {
  CreateUpdateIPPFormType,
  FetchIPPResponse,
} from '../../../services/IPP/useIPP';
import { IPPTenureListFormProps } from '../helpers/ippHelpers';
import style from './style.module.css';

const IPPTenureListForm: React.FC<IPPTenureListFormProps> = ({
  ippTenureKey,
  showRequiredLabel = true,
  height,
  disableInputs = false,
}) => {
  const { values, errors } = useFormikContext<
    CreateUpdateIPPFormType & FetchIPPResponse
  >();

  const formKey = ippTenureKey;
  const ippTenureValues = values[ippTenureKey];

  const error =
    errors && errors[formKey] && typeof errors[formKey] === 'string'
      ? errors[formKey]
      : '';

  return (
    <div
      className={style.container}
      style={{ height, overflowY: height ? 'auto' : 'unset' }}
    >
      {showRequiredLabel && (
        <RequiredFieldLabel text={'Select Tenure & key in Fees'} />
      )}
      {ippTenureValues?.map((item, index: number) => {
        const objectPath = `${ippTenureKey}.${index}`;
        const checked = item.status as boolean;

        return (
          <div className={style.tenureRow} key={index}>
            <Checkbox
              name={'status'}
              checked={checked}
              valuePath={objectPath}
              disabled={disableInputs}
            />
            <span className={style.tenure}>{item.tenure} mth</span>
            <div className={style.inputWrapper}>
              <Input
                name={'rate'}
                type={'number'}
                placeholder={'123'}
                addonAfter={'%'}
                allowClear
                bordered={false}
                value={item.rate}
                valuePath={objectPath}
                disabled={!item.status || disableInputs}
              />
            </div>
          </div>
        );
      })}
      <FormItem
        className={style.formItem}
        validateStatus={error ? 'error' : 'success'}
        help={error}
      />
    </div>
  );
};

export default IPPTenureListForm;
