import {
  ChangeDataOutletPaymentModeResponse,
  PaymentModeObjectType,
  PaymentModeType,
} from '../../../services/PaymentMode/usePaymentMode';
import { formatDateTime, groupBy } from '../../../utils/utilFunctions';
import * as Yup from 'yup';
import { ColumnsType } from 'antd/lib/table/interface';
import { FieldName, OutletStatusPaymentMode } from './enums';

type PaymentModeShemaType = {
  [key: string]:
    | Yup.BooleanSchema<boolean | undefined, {}, boolean | undefined>
    | Yup.NumberSchema<number | undefined, {}, number | undefined>;
};

const createFavePaymentModeValidationSchema = (
  keys: string[],
  statusOutletPaymentMode?:
    | OutletStatusPaymentMode.Existing
    | OutletStatusPaymentMode.New,
) => {
  const defaultSchema: PaymentModeShemaType = { status: Yup.boolean() };

  keys.forEach(key => {
    const excludeKey = keys.filter(exclude => exclude !== key);
    defaultSchema[key] = Yup.number()
      .when('status', {
        is: true,
        then: schema =>
          statusOutletPaymentMode === OutletStatusPaymentMode.Existing
            ? schema.moreThan(0, 'Value must be more than 0')
            : schema
                .required('Please enter value')
                .moreThan(0, 'Value must be more than 0'),
      })
      .when(excludeKey, {
        is: (...values: (number | undefined)[]) =>
          values.some(val => val && val > 0),
        then: schema => schema.notRequired(),
      });
  });
  // After add fields for all payment mode in each group base on rule rate/fixed_amount{group}{variant},
  // Need to get pairs exclude for checking not need field require
  const pairs = keys
    .map((key, index) => keys.slice(index + 1).map(nextKey => [key, nextKey]))
    .flat() as [string, string][];

  return Yup.array().of(Yup.object().shape(defaultSchema, [...pairs]));
};

export const companyPaymentModesFieldKey = 'mc_company_payment_modes';
export const approvalDocumentsFieldKey = 'approval_documents';
export const outletsFieldKey = 'outlets';
export const favePaymentModesFieldKey = 'mc_outlet_payment_modes';

export const isFPX = (key: string) => key === 'FPX' || key === 'Online Banking';

export const groupByPaymentMode = (
  values: PaymentModeType[] | undefined,
  isFastpay: boolean,
) =>
  values &&
  groupBy(values, 'group', item => {
    const data: PaymentModeType = {
      ...item,
      status: item.status === 'enabled' ? true : false,
      rate: item.rate || undefined,
      [`rate${item.group.replace(' ', '')}${item.variant}`]:
        item.rate || undefined,
    };

    if (!isFastpay) {
      // for fave, every fields payment modes is required
      data.status = true;
    }

    const isGroupFPX = isFPX(item.group);

    if (isGroupFPX) {
      data.is_fpx = true;
      data.fixed_amount = item.fixed_amount || undefined;
      data[`fixed_amount${item.group.replace(' ', '')}${item.variant}`] =
        item.fixed_amount || undefined;
    }
    return data;
  });

export const groupByPaymentModeSchema = (
  values: PaymentModeObjectType,
  statusOutletPaymentMode?:
    | undefined
    | OutletStatusPaymentMode.Existing
    | OutletStatusPaymentMode.New,
) =>
  values &&
  Object.keys(values).reduce<{ [key: string]: unknown }>(
    (array: { [key: string]: unknown }, current: string) => {
      const isCurrentFPX = isFPX(current);
      const keys = values[current].map(pm =>
        isCurrentFPX
          ? `fixed_amount${pm.group.replace(' ', '')}${pm.variant}`
          : `rate${pm.group.replace(' ', '')}${pm.variant}`,
      );
      if (isCurrentFPX)
        array[current] = createFavePaymentModeValidationSchema(
          keys,
          statusOutletPaymentMode,
        );
      else
        array[current] = createFavePaymentModeValidationSchema(
          keys,
          statusOutletPaymentMode,
        );
      return array;
    },
    {},
  );

export const dataColumnsPaymentModeChanges: ColumnsType<ChangeDataOutletPaymentModeResponse> =
  [
    {
      title: 'When',
      dataIndex: 'created_at',
      width: 200,
      render: (created_at: string) =>
        isNaN(new Date(created_at).getDate())
          ? ''
          : formatDateTime(new Date(created_at)),
    },
    {
      title: 'Who',
      dataIndex: 'user',
      width: 200,
      render: (user: string) => user,
    },
    {
      title: 'Changed field',
      dataIndex: 'field',
      width: 200,
      render: (field: string) => field,
    },
    {
      title: 'Old value',
      dataIndex: 'old_value',
      width: 200,
      render: (old_value: number | null | string, rowData) => {
        if (
          rowData.field === FieldName.CreateAt ||
          rowData.field === FieldName.UpdateAt
        )
          return isNaN(new Date(old_value || '').getDate())
            ? ''
            : old_value && formatDateTime(new Date(old_value));
        return old_value;
      },
    },
    {
      title: 'New value',
      dataIndex: 'new_value',
      width: 200,
      render: (new_value: number | null | string, rowData) => {
        if (
          rowData.field === FieldName.CreateAt ||
          rowData.field === FieldName.UpdateAt
        )
          return isNaN(new Date(new_value || '').getDate())
            ? ''
            : new_value && formatDateTime(new Date(new_value));
        return new_value;
      },
    },
  ];
