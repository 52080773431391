import { FormikHelpers } from 'formik';
import { useNavigate, useParams } from 'react-router';
import { PopConfirm, Button, Col, Row, Notification } from 'fave-ui';
import Form from '../../../../components/common/form/Form';
import { Input } from '../../../../components/common/form/Input';
import SubmitButton from '../../../../components/common/form/SubmitButton';
import { StatusType } from '../../../../types/dataTypes';
import { PageHeaderWithBackButton } from '../../../../components/common/PageHeaderWithBackButton';
import {
  useFetchCashbackCampaignListingData,
  useUpdateWhitelistedMutation,
} from '../../../../services/CashbackCampaign/useFetchCashbackCampaignListing';
import { Select } from '../../../../components/common/form/Select';
import { arrayToOptions } from '../../../../utils/utilFunctions';

type CampaignFormFields = {
  id?: number;
  campaign_type: string;
  whitelisted_company_ids?: (string | number)[];
  isLoading?: boolean;
  isError?: boolean;
  status?: StatusType;
};

const successNotification = () => {
  Notification.success({
    message: 'Campaign Successfully Edited!',
    description: 'Your cashback campaign has been edited succesfully',
  });
};

export default function EditFaveCashbackCampaign() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { data: cashbackCampaignData } = useFetchCashbackCampaignListingData({
    id: id,
  });
  const { mutateAsync: updateCampaignWhitelisted } =
    useUpdateWhitelistedMutation();

  const idOptions =
    cashbackCampaignData?.whitelisted_company_ids &&
    arrayToOptions(
      cashbackCampaignData?.whitelisted_company_ids.map(item =>
        item.toString(),
      ),
      ' ',
    );

  const initialValues: CampaignFormFields = {
    campaign_type: cashbackCampaignData?.campaign_type as string,
    whitelisted_company_ids: cashbackCampaignData?.whitelisted_company_ids,
  };

  const handleSubmit = async (
    values: CampaignFormFields,
    { setSubmitting }: FormikHelpers<CampaignFormFields>,
  ) => {
    const companysId =
      values.whitelisted_company_ids &&
      values.whitelisted_company_ids
        .map(value => {
          if (typeof value === 'string') {
            return value.split(' ');
          }
          return value;
        })
        .flat();

    const payload = {
      id: id as string | number,
      whitelisted_company_ids: companysId,
    };
    await updateCampaignWhitelisted(
      { ...payload },
      {
        onSuccess: () => {
          successNotification();
        },
        onSettled: () => {
          setSubmitting(false);
        },
      },
    );
  };

  return (
    <>
      <PageHeaderWithBackButton title={`Edit Cashback Campaign`} />
      <Form
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validateOnMount
        validateOnChange
        validateOnSchemaChange
      >
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Input
              name="campaign_type"
              label="Campaign Type"
              disabled
              style={{ marginBottom: 0 }}
            />
          </Col>
          <Col span={24}>
            <Select
              name="whitelisted_company_ids"
              label="Whitelisted Merchants"
              placeholder=""
              mode="tags"
              bordered
              options={idOptions}
              style={{ marginBottom: 0 }}
            />
          </Col>
          <Col span={24}>
            <SubmitButton disableIfNotValid disableIfNotDirty>
              {(_, isSubmitting) => (isSubmitting ? 'Submiting...' : 'Save')}
            </SubmitButton>
            <PopConfirm
              title="Are you sure you want to cancel? All progress will be lost for the current campaign."
              onConfirm={() =>
                navigate('/admin/merchandise_and_campaign/cashback_campaigns')
              }
              okText="Yes, I am sure"
              cancelText="Go Back"
            >
              <Button type="primary" size="large">
                Cancel
              </Button>
            </PopConfirm>
          </Col>
        </Row>
      </Form>
    </>
  );
}
