import * as Yup from 'yup';

export const formRejectedSchema = Yup.object().shape({
  rejected_reason: Yup.string().required('Reject comment required'),
});

export const formGroupSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  members: Yup.array()
    .of(Yup.string())
    .min(1, 'Please select at least 1 members'),
  checkers: Yup.array()
    .of(Yup.string())
    .min(1, 'Please select at least 1 checkers'),
});

export const initiallForm = {
  date_requested: [],
  date_approved: [],
};

export const initialInfoseclForm = {
  search: '',
};

export type FormValueGroupDetail = {
  name: string;
  members: number[];
  checkers: number[];
};

export const initiallFormGroup = {
  name: '',
  members: [],
  checkers: [],
};
