import { Button, Drawer, DrawerProps, ModalFunctions, Message } from 'fave-ui';
import { Table } from 'antd';
import style from './style.module.css';
import {
  PaymentFileSuccessResponse,
  UobPaymentFile,
  useFetchDownloadPaymentFile,
  useFetchPaymentFile,
  useMutateApprovePaymentFile,
  useMutateRejectPaymentFile,
} from '../../services/merchantSettlement';
import { ColumnsType } from 'antd/lib/table';
import {
  downloadFileFromBase64Bytes,
  valueOrDash,
} from '../../utils/utilFunctions';
import { useMemo, useRef, useState } from 'react';
import useOnceCallback from '../../hooks/useOnceCallback';
import { useVT } from 'virtualizedtableforantd4';
import useDebouncedCallback from '../../hooks/useDebouncedCallback';
import useDynamicTableHeight from '../../hooks/useDynamicTableHeight';
import { ArrowLineDown, CheckCircle, X, XCircle } from 'phosphor-react';
import { useUserContext } from '../../contexts/UserContext';
import { elementIds } from './elementId';

export type PaymentFileObject =
  | {
      id: number;
      fileName: string;
      entryId: string;
    }
  | undefined;

type PaymentFileDrawerProps = Omit<DrawerProps, 'children'> & {
  onClose: () => void;
  paymentFileObject: PaymentFileObject;
};

export const PaymentFileDrawer = ({
  onClose,
  paymentFileObject,
  ...restProps
}: PaymentFileDrawerProps) => {
  const [tableColumns, setTableColumns] = useState<ColumnsType<UobPaymentFile>>(
    [],
  );
  const {
    data: paymentFileData,
    isFetching: isFetchPaymentFileLoading,
    fetchNextPage,
  } = useFetchPaymentFile({
    id: paymentFileObject?.id || 0,
  });
  const { refetch, isFetching: isFetchDownloadPaymentFileLoading } =
    useFetchDownloadPaymentFile({
      id: paymentFileObject?.id || 0,
    });

  const { mutateAsync: approvePaymentFile } = useMutateApprovePaymentFile();
  const { mutateAsync: rejectPaymentFile } = useMutateRejectPaymentFile();

  const tableRef = useRef<HTMLDivElement | null>(null);
  const { tableHeight } = useDynamicTableHeight(tableRef);

  const flatMapFetchPaymentFile = useMemo(
    () => paymentFileData?.pages.flatMap(data => data.original_file),
    [paymentFileData],
  );

  const approveFilePermission = useMemo(
    () => paymentFileData?.pages[0].can_approve,
    [paymentFileData],
  );

  const rejectFilePermission = useMemo(
    () => paymentFileData?.pages[0].can_reject,
    [paymentFileData],
  );

  const { getPageSettings } = useUserContext();
  const pageSettings = getPageSettings('merchant_settlement_dashboard');
  const showDownloadButton = !!pageSettings?.download;

  const handleDownloadPaymentFile = async () =>
    await refetch().then(async response => {
      if (response.data)
        await downloadFileFromBase64Bytes(
          response.data?.file,
          response.data.payment_file_name,
        );
    });

  // because the response is dynamic, and the columns are based on each key
  // FE needs to make sure tableColumns value are fixed and not changed when
  // original_file returns empty []
  // can happen once infinite scrolling is implemented, when reach end of list
  const hasPaymentFileData =
    flatMapFetchPaymentFile && flatMapFetchPaymentFile.length > 0;

  const hasNoTableColumns = tableColumns.length === 0;

  const allowSetTableColumns = !!(hasPaymentFileData && hasNoTableColumns);

  // to make sure tableScrollHeight is lower than tableHeight
  // else table will overflow, exceeding drawer height and drawer becomes scrollable
  const tableScrollHeight = tableHeight - 150;
  const tableBodyHeight =
    tableRef.current?.getElementsByClassName('ant-table-tbody')[0]
      .clientHeight || 0;

  // boolean to trigger load more
  const shouldImmediatelyLoadMore = useMemo(
    () => tableBodyHeight < tableHeight,
    [tableBodyHeight, tableBodyHeight],
  );

  // prevents user from calling api many times
  const handleDebouncedImmediateFetchNextPage = useDebouncedCallback(
    fetchNextPage,
    300,
  );

  // prevents user from calling api many times
  const handleDebouncedFetchNextPage = useDebouncedCallback(fetchNextPage, 100);

  // will continue to fetch API until table height fills up page
  // this is so that user with larger screens can continue to scroll to trigger load more
  if (shouldImmediatelyLoadMore) handleDebouncedImmediateFetchNextPage();

  const [virtualTable] = useVT(
    () => ({
      onScroll: ({ isEnd }) => {
        if (isEnd) handleDebouncedFetchNextPage();
      },
      scroll: { y: tableScrollHeight },
    }),
    [scroll, handleDebouncedFetchNextPage, tableScrollHeight],
  );

  useOnceCallback(() => {
    const paymentFile = flatMapFetchPaymentFile || [];
    const columnKeys = Object.keys(paymentFile[0]);

    // known columns that have long content
    const longerColumns: Partial<{ [key in keyof UobPaymentFile]: number }> = {
      email: 300,
      cust_name: 350,
      cust_reference: 200,
      payment_id: 200,
      amount: 150,
    };

    const columns = columnKeys.map(dataIndex => {
      const title = dataIndex.split('_').join(' ');
      // this makes each column wider to fit in the title in single line
      // this is needed because the response is dynamic

      const columnWidth =
        longerColumns[dataIndex as keyof typeof longerColumns] ??
        (title.length + 3) * 10;

      return {
        title,
        dataIndex,
        className: `capitalize`,
        width: columnWidth,
        render: (data: string) => valueOrDash(data),
      };
    });

    setTableColumns(columns);
  }, allowSetTableColumns);

  const handleApprovePaymentFile = () =>
    ModalFunctions.confirm({
      title: 'Approve confirmation',
      content: (
        <>
          <p className={'text-primary'}>{paymentFileObject?.fileName}</p>
          <p>
            You’re about to make an irreversible action. Confirm to approve this
            change request?
          </p>
        </>
      ),
      okText: (
        <span data-id={elementIds.btnConfirmApprovePaymentFile}>Confirm</span>
      ),
      cancelText: (
        <span data-id={elementIds.btnCancelApprovePaymentFile}>Cancel</span>
      ),
      centered: true,
      onOk: async () => {
        if (paymentFileObject?.id)
          await approvePaymentFile(
            { id: paymentFileObject.id },
            {
              onSuccess: res => {
                onClose();
                onSuccessApprovePayment(res.data);
              },
            },
          );
      },
    });

  const handleRejectPaymentFile = () =>
    ModalFunctions.confirm({
      title: 'Confirmation',
      content: (
        <>
          <p className={'text-primary'}>{paymentFileObject?.fileName}</p>
          <p>
            You’re about to make an irreversible action. Confirm to reject this
            pay file.
          </p>
        </>
      ),
      okText: (
        <span data-id={elementIds.btnConfirmRejectPaymentFile}>Confirm</span>
      ),
      cancelText: (
        <span data-id={elementIds.btnCancelRejectPaymentFile}>Cancel</span>
      ),
      centered: true,
      onOk: async () => {
        if (paymentFileObject?.id)
          await rejectPaymentFile(
            { id: paymentFileObject.id },
            {
              onSuccess: res => {
                onClose();
                onSuccessRejectPayment(res.data);
              },
            },
          );
      },
    });

  return (
    <Drawer
      {...restProps}
      onClose={onClose}
      className={style.paymentFileDrawer}
      title={'View Payment File'}
      extra={
        <Button type="text" onClick={onClose}>
          Close
        </Button>
      }
    >
      {/* to take up ant-drawer-body full height */}
      <div ref={tableRef} className={'h-full max-h-full'}>
        <div className={style.containerAboveTable}>
          <span className={style.paymentFileName}>
            {paymentFileObject?.fileName}
          </span>
          {showDownloadButton && (
            <Button
              type="primary"
              icon={<ArrowLineDown />}
              iconPlacement="start"
              onClick={handleDownloadPaymentFile}
              loading={isFetchDownloadPaymentFileLoading}
            >
              Download Payment File
            </Button>
          )}
        </div>
        <Table
          dataSource={flatMapFetchPaymentFile}
          columns={tableColumns}
          scroll={{ y: tableScrollHeight }}
          loading={isFetchPaymentFileLoading}
          components={virtualTable}
          pagination={false}
        />
        <div className={'flex justify-end space-x-2'}>
          <div className={'flex justify-end mt-6'}>
            {rejectFilePermission && (
              <button
                className={
                  'border-2 border-primary text-primary px-4 py-2 rounded-lg font-bold'
                }
                data-id={elementIds.btnRejectPaymentFile}
                onClick={handleRejectPaymentFile}
              >
                Reject
              </button>
            )}
          </div>
          <div className={'flex justify-end mt-6'}>
            {approveFilePermission && (
              <Button
                type={'primary'}
                size={'large'}
                data-id={elementIds.btnApprovePaymentFile}
                onClick={handleApprovePaymentFile}
              >
                Approve
              </Button>
            )}
          </div>
        </div>
      </div>
    </Drawer>
  );
};

const onSuccessApprovePayment = (response: PaymentFileSuccessResponse) => {
  const successMessageKey = 'approveSuccess';

  // allow user to dismiss success message
  const handleCloseSuccessMessage = () => Message.destroy(successMessageKey);

  return Message.success({
    content: (
      <div className={style.container}>
        <div className={style.messageAndIcon}>
          <div>
            <CheckCircle size={20} color={'rgb(21 128 61)'} />
          </div>
          <div className={style.message}>
            <p>{`${response.merchant_settlement_approval.approval_level} Approved`}</p>
            <span>
              {`${response.merchant_settlement.payment_file_reference_id} has been approved by ${response.merchant_settlement_approval.finance_user.name}.`}
            </span>
          </div>
        </div>
        <div>
          <X
            size={16}
            onClick={handleCloseSuccessMessage}
            color={'rgba(115, 115, 115, 1)'}
            className={style.closeIcon}
          />
        </div>
      </div>
    ),
    icon: <></>,
    key: successMessageKey,
    className: style.successMessage,
  });
};

const onSuccessRejectPayment = (response: PaymentFileSuccessResponse) => {
  const successMessageKey = 'rejectSuccess';

  // allow user to dismiss success message
  const handleCloseSuccessMessage = () => Message.destroy(successMessageKey);

  return Message.success({
    content: (
      <div className={style.container}>
        <div className={style.messageAndIcon}>
          <div>
            <XCircle size={20} color={'rgb(220 38 38)'} />
          </div>
          <div className={style.message}>
            <p>{`${response.merchant_settlement_approval.approval_level} Rejected`}</p>
            <span>
              {`${response.merchant_settlement.payment_file_reference_id} has been rejected by ${response.merchant_settlement_approval.finance_user.name}.`}
            </span>
          </div>
        </div>
        <div>
          <X
            size={16}
            data-id={elementIds.btnDismissSuccessfulRejectMessage}
            onClick={handleCloseSuccessMessage}
            color={'rgba(115, 115, 115, 1)'}
            className={style.closeIcon}
          />
        </div>
      </div>
    ),
    icon: <></>,
    key: successMessageKey,
    className: style.rejectMessage,
  });
};
