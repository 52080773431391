import { UploadFile } from 'fave-ui';
import { TenantCodeType } from '../contexts/TenantContext';
import { ContractStatus } from '../helpers/TypeConstant';

export type CompanyType = {
  merchant_type: MerchantType;
  id: number;
  bd_manager_email: string;
  created_at: string;
  contract_status: ContractStatus;
  name: string;
  outlets: OutletType[];
  products: string[];
  logo: string;
  has_draft_ipp: boolean;
  has_draft_payment_mode: boolean;
  ongoing_contract_migration: boolean;
};

export type MerchantType = 'fave' | 'fastpay';

export type OutletType = {
  id: number;
  name: string;
  has_fave_payment: boolean;
  fave_fees: number;
  status: OutletStatusType;
};

type TenantsType = {
  tag: string;
  label: string;
  code: TenantCodeType;
};

export enum UserRole {
  SuperAdmin = 'super_admin',
  engineering = 'engineering',
  // add as needed
}

export type UserType = {
  jwt: string;
  email: string;
  name: string;
  role: UserRole;
  project_hello_settings: boolean;
  tenants: TenantsType[]; // This is list of tenants that the user has permission to access, which will populate the dropdown menu
};

export type DealsType = {
  id: number;
  deal_name: string;
  status: StatusType;
  original_price: string;
  discounted_price: string;
  margin: number;
  deal_feature_start_date: null;
  deal_feature_end_date: null;
  sold_count: number;
  capacity: number;
  signature_date: null;
  bd_name: null;
  outlets: OutletType;
};

export type ECardsType = {
  id: number;
  status: string;
  payable_amount: string;
  bonus_credit_amount: string;
  commission_rate_percent: number;
  start_date: string;
  end_date: string;
  sold_count: number;
  capacity: number;
  signature_date: string;
  bd_name: string;
};

export type McApprovalDocumentsType = {
  id: number;
  file_url: string;
};

export type ApprovalDocumentsType = {
  approval_documents: (UploadFile & { file_url: string; id: number })[];
};

export type OutletScopeType = 'favepay' | 'deals';

export type StatusType = 'Live' | 'Inactive' | 'Scheduled';

export type EnabledStatusType = 'enabled' | 'disabled';

export type OutletStatusType = 'active' | 'inactive' | 'archived';

export type CampaignStatusType =
  | 'Active'
  | 'Inactive'
  | 'Scheduled'
  | 'Archived';

export type UpdateStatusType = 'activate' | 'deactivate' | 'archive';

export type ConditionType = '>' | '>=' | '<' | '<=';

export type ArcadePrizeType = 'lucky_draw' | 'promo_code';

export type CashbackValueType = 'absolute_amount' | 'percentage';

export type MySubmissionStatusType =
  | 'Pending signature'
  | 'Pending Ack.'
  | 'Contract Signed'
  | 'Pending Operations'
  | 'Completed'
  | 'Cancelled'
  | 'Signed';

export type BannerStatusType = 'active' | 'inactive';
