import { memo, useMemo, useState } from 'react';
import { TableSettingsType } from '../../../types/configTypes';
import { defaultTableConfig } from '../../../helpers/defaults';
import {
  createOnTableChangeHandler,
  dateOrDash,
  dateTimeOrDash,
  scrollTableConfig,
  valueOrDash,
} from '../../../utils/utilFunctions';
import { Button, Table } from 'fave-ui';
import { RenderColumn } from '../../../helpers/tableHelpers/RenderColumn';
import { ColumnsType } from 'antd/lib/table';
import { useTenantContext } from '../../../contexts/TenantContext';
import {
  MerchantSettlement,
  SettlementDetailsGroup,
  useFetchSettlementList,
} from '../../../services/merchantSettlement';
import { MerchantSettlementStatusTag } from '../../../components/common/StatusTag';
import style from '../style.module.css';
import FullCell from '../../../components/FullCell';
import { PaymentFileDrawer, PaymentFileObject } from '../uiHelper';

const scrollConfig = scrollTableConfig();

const MemoisedFullCell = memo(
  ({
    settlementData,
    label,
  }: {
    settlementData: SettlementDetailsGroup[];
    label: string;
  }) => {
    return (
      <FullCell>
        {settlementData.map(item =>
          item.details.map((detail, index) => {
            const statusLabel = label.toLowerCase().split(' ').join('_');

            return (
              <p key={index}>
                {valueOrDash(
                  detail[statusLabel as keyof typeof detail] as string,
                )}
              </p>
            );
          }),
        )}
      </FullCell>
    );
  },
);

MemoisedFullCell.displayName = 'MemoisedFullCell';

const ActionsNeededDashboardView = () => {
  const { isSG } = useTenantContext();
  const [paymentFileObject, setPaymentFileObject] =
    useState<PaymentFileObject>();
  const [tableConfig, setTableConfig] =
    useState<TableSettingsType>(defaultTableConfig);
  const { data: merchantSettlementData, isFetching } = useFetchSettlementList({
    ...tableConfig,
    page: tableConfig.current,
  });

  const tableChangeHandler = createOnTableChangeHandler(setTableConfig);

  const onClickViewPaymentFile = (
    id: number,
    fileName: string,
    entryId: string,
  ) => setPaymentFileObject({ id, fileName, entryId });

  const onCloseViewPaymentFile = () => setPaymentFileObject(undefined);

  const showPaymentFileDrawer = !!paymentFileObject;

  // dynamic columns, depends on API response
  const approvalLabels = useMemo(
    () =>
      merchantSettlementData?.approval_labels.map(label => ({
        title: label,
        dataIndex: 'details_grouped_by_generated_date',
        width: 250,
        render: (settlementData: SettlementDetailsGroup[]) => (
          <MemoisedFullCell settlementData={settlementData} label={label} />
        ),
      })) || [],
    [merchantSettlementData?.approval_labels],
  );

  const merchantSettlementColumns: ColumnsType<MerchantSettlement> = [
    {
      title: 'Entry ID',
      dataIndex: 'payment_file_reference_id',
      width: 200,
      render: data => <FullCell>{data}</FullCell>,
    },
    {
      title: 'Generated date',
      dataIndex: 'details_grouped_by_generated_date',
      width: 150,
      render: (data: SettlementDetailsGroup[]) => (
        <FullCell>
          {data.map((item, index) => (
            <p key={index}>{dateOrDash(item.created_at)}</p>
          ))}
        </FullCell>
      ),
    },
    {
      title: 'Cadence',
      dataIndex: 'details_grouped_by_generated_date',
      width: 100,
      render: (data: SettlementDetailsGroup[]) => (
        <FullCell className="items-baseline pt-[0.2rem]">
          {data.map(item =>
            RenderColumn.infoPopover({
              title: 'Cadences',
              content: item.payment_cadences.map(cadence => (
                <p key={cadence}>{cadence}</p>
              )),
            }),
          )}
        </FullCell>
      ),
    },
    {
      title: 'Time',
      dataIndex: 'details_grouped_by_generated_date',
      width: 150,
      render: (data: SettlementDetailsGroup[]) => (
        <FullCell>
          {data.map(item =>
            item.details.map((detail, index) => (
              <p key={index}>
                {dateTimeOrDash(detail.created_at, 'HH:mm:ss XX')}
              </p>
            )),
          )}
        </FullCell>
      ),
    },
    {
      title: 'Payment File',
      dataIndex: 'details_grouped_by_generated_date',
      width: 250,
      render: (data: SettlementDetailsGroup[], rowData) => {
        const originalPaymentFileEntryId = rowData.payment_file_reference_id;

        return (
          <FullCell>
            {data.map(item =>
              item.details.map((detail, index) => (
                <Button
                  size={'small'}
                  className={style.hyperlinkButton}
                  key={index}
                  onClick={() =>
                    onClickViewPaymentFile(
                      detail.id,
                      detail.payment_file_name,
                      originalPaymentFileEntryId,
                    )
                  }
                >
                  {detail.payment_file_name}
                </Button>
              )),
            )}
          </FullCell>
        );
      },
    },
    {
      title: 'Status',
      dataIndex: 'details_grouped_by_generated_date',
      width: 250,
      render: (data: SettlementDetailsGroup[]) => (
        <FullCell>
          {data.map(item =>
            item.details.map((status, index) => (
              <MerchantSettlementStatusTag
                key={index}
                status={status.status_key}
              >
                {status.status}
              </MerchantSettlementStatusTag>
            )),
          )}
        </FullCell>
      ),
    },
    ...approvalLabels,
    {
      title: `Control Sum (${isSG ? 'S$' : 'RM'})`,
      dataIndex: 'details_grouped_by_generated_date',
      width: 200,
      render: (data: SettlementDetailsGroup[]) => (
        <FullCell>
          {data.map(item =>
            item.details.map((detail, index) => (
              <p key={index}>{valueOrDash(detail.total_settlement_amount)}</p>
            )),
          )}
        </FullCell>
      ),
    },
    {
      title: 'Tran. Count',
      dataIndex: 'details_grouped_by_generated_date',
      width: 150,
      render: (data: SettlementDetailsGroup[]) => (
        <FullCell>
          {data.map(item =>
            item.details.map((detail, index) => (
              <p key={index}>
                {valueOrDash(detail.total_settlement_transactions)}
              </p>
            )),
          )}
        </FullCell>
      ),
    },
    {
      title: 'Rejection Notes',
      dataIndex: 'details_grouped_by_generated_date',
      width: 150,
      render: (data: SettlementDetailsGroup[]) => (
        <FullCell className="text-center">
          {data.map(item =>
            item.details.map((detail, index) => {
              const showViewButton = !!(
                detail.settlement_rejected_reason || detail.rejected_file
              );

              return (
                <p key={index}>
                  {showViewButton ? <Button type={'text'}>View</Button> : '-'}
                </p>
              );
            }),
          )}
        </FullCell>
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'id',
      fixed: 'right',
      width: 100,
      render: (id: number, rowData) => {
        const originalPaymentFileName =
          rowData.details_grouped_by_generated_date[0].details[0]
            .payment_file_name;

        const originalPaymentFileEntryId = rowData.payment_file_reference_id;

        return (
          <Button
            type={'text'}
            onClick={() =>
              onClickViewPaymentFile(
                id,
                originalPaymentFileName,
                originalPaymentFileEntryId,
              )
            }
          >
            View
          </Button>
        );
      },
    },
  ];

  return (
    <>
      <Table
        size="large"
        className={style.table}
        dataSource={merchantSettlementData?.merchant_settlements}
        rowKey={row => row.id}
        columns={merchantSettlementColumns}
        scroll={scrollConfig}
        onChange={tableChangeHandler}
        loading={isFetching}
        pagination={{
          total: merchantSettlementData?.count,
          position: ['bottomCenter'],
          pageSize: tableConfig.pageSize,
          current: tableConfig.page,
          showSizeChanger: false,
        }}
      />
      <PaymentFileDrawer
        visible={showPaymentFileDrawer}
        onClose={onCloseViewPaymentFile}
        paymentFileObject={paymentFileObject}
      />
    </>
  );
};

export default ActionsNeededDashboardView;
