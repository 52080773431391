import { Alert } from 'fave-ui';

type BannerErrorProps = {
  errorText: string;
};

const BannerError: React.FC<BannerErrorProps> = ({ errorText }) => (
  <>
    {errorText && (
      <div style={{ margin: '24px auto' }}>
        <Alert message={errorText} banner={false} type="error" />
      </div>
    )}
  </>
);

export default BannerError;
