import { TenantCodeType } from '../contexts/TenantContext';
import cookie from '../helpers/cookie';
import { UserType } from '../types/dataTypes';

const STAGING_NAME_KEY = 'stagingName';
const AUTH = 'auth';
const CURRENT_TENANT = 'currentTenant';

const cookieSet = {
  currentStagingName: {
    get: () => cookie.getOrDefault(STAGING_NAME_KEY, 'fnb'),
    set: (value: string) => cookie.set(STAGING_NAME_KEY, value),
  },
  auth: {
    get: (): UserType => cookie.get(AUTH),
    set: (value: UserType) => cookie.set(AUTH, JSON.stringify(value)),
    remove: () => cookie.remove(AUTH),
  },
  currentTenant: {
    get: (): TenantCodeType => cookie.get(CURRENT_TENANT),
    set: (value: string) => cookie.set(CURRENT_TENANT, value),
    remove: () => cookie.remove(CURRENT_TENANT),
  },
};

export default cookieSet;
