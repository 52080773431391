import { Form } from '../../../components/common/form';
import IPPApprovalModal from '../IPPApprovalModal';
import * as Yup from 'yup';
import {
  MutateIPPApprovalStatusFormType,
  useMutateIPPApprovalStatus,
} from '../../../services/MasterDashboard/masterDashboardListing';
import { FormikHelpers } from 'formik';
import { ippMerchantIDValidation } from '../../../services/IPP/useIPP';
import {
  handleIPPApprovalStatusUpdated,
  IPPFormProps,
} from '../helpers/masterDashboardHelpers';

const documentsValidation = {
  documents: Yup.mixed().required('Please upload at least 1 file.'),
};
const approveFormValidation = Yup.object().shape({
  ...ippMerchantIDValidation,
  ...documentsValidation,
});
const rejectFormValidation = Yup.object().shape({
  ...documentsValidation,
});

const IPPApprovalForm = ({
  merchantID,
  refetch,
  onCancel: onCloseModal,
  isApproveModal = true,
  ...restProps
}: IPPFormProps) => {
  const { mutate: updateIPPApprovalStatus } = useMutateIPPApprovalStatus();
  const parsedID = merchantID || 0;

  const validationSchema = isApproveModal
    ? approveFormValidation
    : rejectFormValidation;

  const onSubmitForm = (
    values: MutateIPPApprovalStatusFormType,
    {
      setSubmitting,
      resetForm,
    }: FormikHelpers<MutateIPPApprovalStatusFormType>,
  ) => {
    const formData = new FormData();

    formData.append(
      'ipp_approval_status',
      isApproveModal ? 'approved' : 'rejected',
    );

    if (isApproveModal)
      formData.append(
        'ipp_merchant_id',
        values.ipp_merchant_id as unknown as string,
      );

    values.documents?.forEach(
      file =>
        file.originFileObj &&
        formData.append(`documents[]`, file.originFileObj),
    );

    updateIPPApprovalStatus(
      {
        id: parsedID,
        formData,
      },
      {
        onSuccess: () => {
          if (onCloseModal) onCloseModal();
          handleIPPApprovalStatusUpdated(refetch, isApproveModal);
          resetForm();
        },
        onSettled: () => setSubmitting(false),
      },
    );
  };

  return (
    <Form
      initialValues={{
        ipp_merchant_id: undefined,
        documents: undefined,
      }}
      validationSchema={validationSchema}
      validateOnMount
      validateOnChange
      validateOnSchemaChange
      onSubmit={onSubmitForm}
    >
      <IPPApprovalModal
        merchantID={merchantID}
        onCancel={onCloseModal}
        isApproveModal={isApproveModal}
        {...restProps}
      />
    </Form>
  );
};

export default IPPApprovalForm;
