import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { ColumnsType } from 'antd/lib/table';
import { Message, ModalFunctions, Table, Tag } from 'fave-ui';
import { CaretDown } from 'phosphor-react';
import React, { ReactNode, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { PageHeaderWithSpace } from '../../components/common/PageHeaderWithSpace/PageHeaderWithSpace';
import { SearchBarExpandable } from '../../components/common/SearchBarExpandable/SearchBarExpandable';
import { IPPApprovalStatusTag } from '../../components/common/StatusTag';
import { TableHeaderSort } from '../../components/common/TableHeaderSort';
import { TextAreaModal } from '../../components/Modal/TextAreaModal';
import TableTotalData from '../../components/TableTotalData/TableTotalData';
import { useUserContext } from '../../contexts/UserContext';
import { defaultTableConfig } from '../../helpers/defaults';
import { RenderColumn } from '../../helpers/tableHelpers/RenderColumn';
import { toolRedirect } from '../../services/http';
import {
  MCContractSubmissionsType,
  useFetchMasterDashboardListing,
  useMutateIPPApprovalStatus,
  useMutateMasterDashboardItem,
} from '../../services/MasterDashboard/masterDashboardListing';
import { TableSettingsType } from '../../types/configTypes';
import {
  calcTableHeight,
  calcTableWidth,
  createOnTableChangeHandler,
  dateOrDash,
  formatFilter,
} from '../../utils/utilFunctions';
import { handleIPPApprovalStatusUpdated } from './helpers/masterDashboardHelpers';
import IPPApprovalForm from './IPPApprovalForm';
import style from './style.module.css';

const tableHeight = calcTableHeight();
const tableWidth = calcTableWidth();
const tableScroll = {
  x: tableWidth,
  y: tableHeight,
};

type MasterItemRejectIdType = number | undefined;

const tagTextValues: {
  [key in keyof MCContractSubmissionsType]?: string;
} = {
  favepay_submission: 'FastPay',
  payment_mode_submission: 'Payment Mode',
  favepay_later_submission: 'BNPL',
  ecard_submission: 'eCard',
  deal_submission: 'Deal',
  ipp_submission: 'IPP',
};

export const MasterDashboardView: React.FC = () => {
  const [tableConfig, setTableConfig] =
    useState<TableSettingsType>(defaultTableConfig);
  const [keyword, setKeyword] = useState('');
  const [masterItemRejectId, setMasterItemRejectId] =
    useState<MasterItemRejectIdType>(undefined);
  const [ippApprovalID, setIPPApprovalID] = useState<number | undefined>(
    undefined,
  );
  const [ippRejectID, setIPPRejectID] = useState<number | undefined>(undefined);
  const { getPageSettings } = useUserContext();

  const { mutate: updateIPPApprovalStatus } = useMutateIPPApprovalStatus();

  const pageSettings = getPageSettings('master_dashboard');
  const showIPPApprovalStatusColumn = !!pageSettings?.ipp_onboarding;

  const {
    data: masterDashboardData,
    isFetching,
    refetch,
  } = useFetchMasterDashboardListing({
    ...tableConfig,
    sort_by: Array.isArray(tableConfig.sort_by)
      ? tableConfig.sort_by.join('.')
      : tableConfig.sort_by,
    filter: `contract_type=master_submission|company.merchant_type=fastpay|status=signed|master_approved=false|company.name=${keyword}|${
      tableConfig.filter || ''
    }`,
    dataset: 'company',
  });
  const { mutate: mutateMasterItem } = useMutateMasterDashboardItem();

  const navigate = useNavigate();

  const tableChangeHandler = useMemo(
    () =>
      createOnTableChangeHandler(setTableConfig, filters =>
        formatFilter([
          {
            key: 'company.ipp_approval_status',
            value: filters.company,
          },
        ]),
      ),
    [setTableConfig],
  );

  const handleUpdateIPPApprovalStatusClick = (id: number, status: string) => {
    const formData = new FormData();

    formData.append('ipp_approval_status', status);

    updateIPPApprovalStatus(
      { id, formData },
      { onSuccess: () => handleIPPApprovalStatusUpdated(refetch) },
    );
  };

  const ippApprovalFilters = [
    {
      text: 'Approved',
      value: 'approved',
      onClick: (id: number) => setIPPApprovalID(id),
    },
    {
      text: 'Rejected',
      value: 'rejected',
      onClick: (id: number) => setIPPRejectID(id),
    },
    {
      text: 'In Progress',
      value: 'in_progress',
      onClick: (id: number) =>
        handleUpdateIPPApprovalStatusClick(id, 'in_progress'),
    },
    {
      text: 'Pending',
      value: 'pending',
      onClick: (id: number) =>
        handleUpdateIPPApprovalStatusClick(id, 'pending'),
    },
    {
      text: 'N/A',
      value: 'null',
    },
  ];

  const ippApprovalStatusDropdownItems = ippApprovalFilters.slice(0, 4);

  const contractsDropdownItems = ({
    company_id,
    mc_id,
  }: {
    company_id: number;
    mc_id: number;
  }): ItemType[] => [
    {
      key: '1',
      label: 'View master contract',
      onClick: () =>
        navigate(
          `/operation_dashboard/master_dashboard/company/${company_id}/mc_submission/${mc_id}`,
        ),
    },
    {
      key: '2',
      label: 'View other documents',
      onClick: () =>
        toolRedirect(`/admin/partners/${company_id}/master_contracts`),
    },
  ];

  const handleAcceptSubmission = (id: number) => {
    mutateMasterItem(
      { id, action: 'accept' },
      {
        onSuccess: () => {
          Message.success({ content: 'Submission Accepted' });
          refetch();
        },
        onError: () => {
          Message.error({ content: 'Something went wrong' });
        },
      },
    );
  };

  const handleRejectSubmission = (reason: string) => {
    masterItemRejectId &&
      mutateMasterItem(
        {
          id: masterItemRejectId,
          action: 'decline',
          params: { decline_reason: reason },
        },
        {
          onSuccess: () => {
            Message.success({ content: 'Submission Rejected' });
            setMasterItemRejectId(undefined);
            refetch();
          },
          onError: () => {
            Message.error({ content: 'Something went wrong' });
          },
        },
      );
  };

  const onClickAccept = (id: number) => {
    ModalFunctions.confirm({
      title: 'Approve Submission?',
      content:
        'Once Approve, submission cannot be undone. Be sure to check all information thorougly before approving.',
      okText: 'Approve',
      centered: true,
      onOk: () => handleAcceptSubmission(id),
    });
  };

  const onClickDecline = (id: number) => setMasterItemRejectId(id);

  const actionsDropdownItems = (id: number): ItemType[] => [
    {
      key: '1',
      label: 'Accept',
      onClick: () => onClickAccept(id),
    },
    {
      key: '2',
      label: 'Decline',
      onClick: () => onClickDecline(id),
    },
  ];

  const masterDashboardColumns: ColumnsType<MCContractSubmissionsType> = [
    {
      title: TableHeaderSort('Submission Date'),
      dataIndex: 'submission_time',
      sorter: true,
      width: 175,
      render: (date: string, { decline_reason }: MCContractSubmissionsType) =>
        RenderColumn.infoPopover({
          text: dateOrDash(date),
          content: decline_reason,
        }),
    },
    {
      title: TableHeaderSort('Partner Name'),
      dataIndex: ['company', 'name'],
      sorter: true,
      width: 200,
      render: RenderColumn.rowText,
    },
    {
      title: 'Merchant ID',
      dataIndex: ['company', 'id'],
      width: 125,
      render: RenderColumn.rowText,
    },
    {
      title: 'Product(s)',
      width: 200,
      render: (submission: MCContractSubmissionsType) => {
        const tags: ReactNode[] = [];

        Object.keys(submission).forEach(key => {
          const isTrue =
            submission[key as keyof MCContractSubmissionsType] === true;
          const tagText = tagTextValues[key as keyof MCContractSubmissionsType];

          if (isTrue && tagText)
            tags.push(
              <Tag key={tagText} className={style.productTag}>
                {tagText}
              </Tag>,
            );
        });

        return tags;
      },
    },
    {
      title: 'Contracts',
      width: 150,
      render: ({ company, id }: MCContractSubmissionsType) =>
        RenderColumn.dropdownMenu(
          contractsDropdownItems({ company_id: company.id, mc_id: id }),
          'View Contracts',
        ),
    },
    {
      title: 'Actions',
      width: 100,
      render: ({ id }: MCContractSubmissionsType) =>
        RenderColumn.dropdownMenu(actionsDropdownItems(id)),
      fixed: 'right',
    },
  ];

  if (showIPPApprovalStatusColumn)
    masterDashboardColumns.splice(5, 0, {
      title: 'IPP Approval Status',
      width: 190,
      filters: ippApprovalFilters,
      filterMode: 'tree',
      dataIndex: 'company',
      render: ({ ipp_approval_status }, { id }) => {
        const showIPPStatusDropdown = ipp_approval_status !== 'N/A';

        return (
          <div className={style.ippApprovalDropdown}>
            <IPPApprovalStatusTag status={ipp_approval_status} />
            {showIPPStatusDropdown &&
              RenderColumn.dropdownMenu(
                ippApprovalStatusDropdownItems.map(({ text, onClick }) => ({
                  onClick: onClick ? () => onClick(id) : undefined,
                  key: text,
                  icon: <IPPApprovalStatusTag status={text} />,
                })),
                <CaretDown className={style.caretDown} />,
                'bottomRight',
              )}
          </div>
        );
      },
    });

  const handleSearch = (value: string) => setKeyword(value);

  return (
    <div>
      <PageHeaderWithSpace
        title={'Master Dashboard'}
        subTitle={
          'Here is the list of merchants submission requiring your action'
        }
        additionalContent={<SearchBarExpandable onSearch={handleSearch} />}
      />
      <TableTotalData
        currentPageDataLength={
          masterDashboardData?.mc_contract_submissions.length
        }
        tableDataLength={masterDashboardData?.count}
        currentPage={tableConfig.page}
        tableLimit={tableConfig.limit}
      />
      <TextAreaModal
        title="Reason of Declination"
        visible={!!masterItemRejectId}
        placeholderText={'Please type in declination comment....'}
        okText={'Submit'}
        onConfirmClick={handleRejectSubmission}
        cancelButtonProps={{ style: { display: 'none' } }}
        onCancel={() => setMasterItemRejectId(undefined)}
      />

      {/* approve modal */}
      <IPPApprovalForm
        merchantID={ippApprovalID}
        onCancel={() => setIPPApprovalID(undefined)}
        refetch={refetch}
      />

      {/* reject modal */}
      <IPPApprovalForm
        merchantID={ippRejectID}
        onCancel={() => setIPPRejectID(undefined)}
        refetch={refetch}
        isApproveModal={false}
      />
      <Table
        dataSource={masterDashboardData?.mc_contract_submissions}
        columns={masterDashboardColumns}
        loading={isFetching}
        rowKey={row => row.id}
        scroll={tableScroll}
        onChange={tableChangeHandler}
        pagination={{
          total: masterDashboardData?.count,
          position: ['bottomCenter'],
          pageSize: tableConfig.pageSize,
          current: tableConfig.page,
          showSizeChanger: false,
        }}
      />
    </div>
  );
};
