import clsx from 'clsx';
import React from 'react';
import style from './style.module.css';

type RequiredFieldLabelProps = {
  text: string;
  className?: string;
};

const RequiredFieldLabel: React.FC<RequiredFieldLabelProps> = ({
  text,
  className,
}) => (
  <p className={clsx(style.requiredFieldLabel, className)}>
    <span className={style.asterisk}>* </span>
    <span className={style.text}>{text}</span>
  </p>
);

export default RequiredFieldLabel;
