import { PropsWithChildren, useContext } from 'react';
import { Row } from 'antd';

import style from '../LoginLayout/style.module.css';
import backgroundImage from '../../assests/images/LoginLanding.png';
import FaveIconSVG from '../../assests/icons/FaveIconSVG';
import { EnvironmentContext } from '../../contexts/EnvironmentContext';
import { StagingDropdown } from '../../components/StagingDropdown';
import BannerError from '../../components/common/form/BannerError';
import { createUiIdContext } from '../../contexts/ElementIdContext';

type LoginLayoutProps = {
  errorText: string;
};

const loginElementIDs = {
  btnLogin: 'btnLogin',
  inputEmail: 'inputEmail',
  inputPassword: 'inputPassword',
  selectStaging: 'selectStaging',
  optionStaging: 'optionStaging',
};

export const { ElementIdProvider, useElementIdContext } =
  createUiIdContext(loginElementIDs);

const LoginLayout = ({
  children,
  errorText,
}: PropsWithChildren<{}> & LoginLayoutProps) => {
  const { isProduction } = useContext(EnvironmentContext);

  return (
    <ElementIdProvider>
      <div
        className={style.landing}
        style={{ backgroundImage: `url('${backgroundImage}')` }}
      >
        <Row className={style.nav}>
          <FaveIconSVG className={style.faveLogo} />
          {!isProduction && <StagingDropdown />}
        </Row>
        <Row style={{ flex: 'auto', flexFlow: 'column' }}>
          <BannerError errorText={errorText} />
          <div className={style.login}>{children}</div>
        </Row>
      </div>
    </ElementIdProvider>
  );
};

export default LoginLayout;
