import { useMutation, useQuery, useQueryClient } from 'react-query';
import { TableConfigType } from '../../types/configTypes';
import http, { createFetchAPI } from '../http';
import { UploadFile } from 'fave-ui';

const endpoint = 'v1/peeky_banners';

export type PeekyBannerListResponseType = {
  peeky_banners: PeekyBannerItem[];
  count: number;
};

export type PeekyBannerItem = {
  id: number;
  title: string;
  deeplink: string;
  start_date: string;
  end_date: string;
  status: string;
  app_image_url: string;
  app_image?: UploadFile[];
  adtech_campaign_id: string;
};

const fetchPeekyBannerData = createFetchAPI<TableConfigType, PeekyBannerItem>();

const fetchPeekyBannerListing = createFetchAPI<
  TableConfigType,
  PeekyBannerListResponseType
>(endpoint);

export const useFetchPeekyBannerListing = (props: TableConfigType) =>
  useQuery<PeekyBannerListResponseType>(
    ['fetchPeekyBannerListing', props],
    fetchPeekyBannerListing,
  );

export const useFetchPeekyBannerData = ({ id }: { id?: number | string }) =>
  useQuery<PeekyBannerItem>(
    ['fetchPeekyBannerData', { url: `${endpoint}/${id}` }],
    fetchPeekyBannerData,
    { enabled: !!id },
  );

type MutateAddPeekyBannerPayload = { formData: FormData };

const addPeekyBannerMutation = (formData: FormData) =>
  http.post<MutateAddPeekyBannerPayload>(endpoint, formData);

export const useAddPeekyBannerMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (payload: MutateAddPeekyBannerPayload) =>
      addPeekyBannerMutation(payload.formData),
    {
      onSuccess: async () => {
        queryClient.invalidateQueries('fetchPeekyBannerListing');
        queryClient.invalidateQueries('fetchPeekyBannerData');
      },
    },
  );
};

type MutateEditPeekyBannerPayload = {
  id?: number;
  formData: FormData;
};

const editPeekyBannerMutation = ({
  id,
  formData,
}: MutateEditPeekyBannerPayload) =>
  http.put<MutateEditPeekyBannerPayload>(`${endpoint}/${id}`, formData);

export const useEditPeekyBannerMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (payload: MutateEditPeekyBannerPayload) =>
      editPeekyBannerMutation(payload),
    {
      onSuccess: async () => {
        queryClient.invalidateQueries('fetchPeekyBannerListing');
        queryClient.invalidateQueries('fetchPeekyBannerData');
      },
    },
  );
};

const deletePeekyBannerMutation = async (id: string | number) =>
  http.delete<PeekyBannerItem>(`${endpoint}/${id}`);

export const useDeletePeekyBannerMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(deletePeekyBannerMutation, {
    onSuccess: async () => {
      queryClient.invalidateQueries('fetchPeekyBannerListing');
      queryClient.invalidateQueries('fetchPeekyBannerData');
    },
  });
};
