import { useQueries } from 'react-query';
import { PayloadBaseType, TableSettingsType } from '../../types/configTypes';
import { createFetchAPI } from '../http';
import { CompanyFPLDetailsType } from '../merchants';

export type FetchBNPLModelsResponseType = {
  bnpl_fee_models: BnplFeeModelType[];
  count: number;
};

export type BnplFeeModelType = {
  id: number;
  signature_date: null;
  bd_name: null;
  status: string;
  model: string;
  start_date: string;
  end_date: string;
  cashback_reduction: null;
  fee: number;
  company_fpl_detail: CompanyFPLDetailsType;
};

const fetchBNPLFeeModels = createFetchAPI<
  PayloadBaseType,
  FetchBNPLModelsResponseType
>(`/v1/bnpl_fee_models`);

export const useFetchBNPLFeeModels = (
  tableConfigs: (TableSettingsType<BnplFeeModelType> & CompanyFPLDetailsType)[],
) =>
  useQueries(
    tableConfigs.map(data => {
      return {
        queryKey: [data.id, { ...data, company_fpl_detail_id: data.id }],
        queryFn: fetchBNPLFeeModels,
      };
    }),
  );
