import { parseISO } from 'date-fns';
import { useInfiniteQuery, UseInfiniteQueryOptions } from 'react-query';
import { createFetchAPI } from './http';

export const changeHistoryCacheKey = 'fetchChangeHistory';

export type ChangeHistoryParams = {
  queryKey?: string;
  item_type: string;
  item_id?: number;
  item_association?: string;
  paper_trail_event?: string;
};

export type ChangeHistoryItem = {
  id: string;
  created_at: Date;
  whodunnit: string;
  changed_field: string;
  old_value: string;
  new_value: string;
};

type ChangeHistoryResponse = {
  list: ChangeHistoryItem[];
  page: number;
};

const handleParseResponse = (
  response: ChangeHistoryResponse,
  params: ChangeHistoryParams,
  pageParam: number,
) => {
  const list = (response as unknown as ChangeHistoryItem[]).map(item => {
    const createdAt = parseISO(item.created_at as unknown as string);

    return {
      ...item,
      created_at: createdAt,
      id: `${params.item_id}_${item.changed_field}_${
        item.new_value
      }_${createdAt.getTime()}`,
    };
  });

  return { list, page: pageParam };
};

const fetchChangeHistory = createFetchAPI<
  ChangeHistoryParams,
  ChangeHistoryResponse
>('v1/versions', { onParseResponse: handleParseResponse });

export const useFetchChangeHistory = (
  { queryKey, ...restParams }: ChangeHistoryParams,
  options?: UseInfiniteQueryOptions<ChangeHistoryResponse>,
) =>
  useInfiniteQuery<ChangeHistoryResponse>(
    [queryKey || changeHistoryCacheKey, { ...restParams }],
    fetchChangeHistory,

    {
      ...options,
      getNextPageParam: response =>
        response.list.length ? response.page + 1 : false,
    },
  );
