import { AxiosResponse } from 'axios';
import { TenantCodeType } from '../contexts/TenantContext';
import cookieSet from '../helpers/cookieSet';
import { UserType } from '../types/dataTypes';
import http from './http';

const clientID = process.env.REACT_APP_G_AUTH_ALIENT_ID;

export type GoogleAuthResponse = {
  authuser: string;
  code: string;
  hd: string;
  prompt: string;
  scope: string;
};

type ErrorCallback = (error: string) => void;
type SuccessCallback = (data: UserType) => void;

const endpoint = '/v1/auth';

const createLoginSuccessHandler =
  (onSuccess: SuccessCallback) =>
  ({ data: user }: AxiosResponse<UserType, unknown>) =>
    onSuccess(user);

const createLoginErrorHandler = (onError: ErrorCallback) => (error: any) =>
  onError(error.response.data.error);

const googleAuthorize = (
  onSuccess: SuccessCallback,
  onError: ErrorCallback,
) => {
  const { google } = window;

  const client = google.accounts.oauth2.initCodeClient({
    client_id: clientID as string,
    scope: 'email',
    ux_mode: 'popup',
    callback: response => {
      const { code } = response as GoogleAuthResponse;

      http
        .post(endpoint, { google_code: code })
        .then(createLoginSuccessHandler(onSuccess))
        .catch(createLoginErrorHandler(onError));
    },
  });

  client.requestCode();
};

export const manualAuthorize = (
  email: string,
  password: string,
  onSuccess: SuccessCallback,
  onError: ErrorCallback,
) =>
  http
    .post(endpoint, {
      username: email,
      password: password,
    })
    .then(createLoginSuccessHandler(onSuccess))
    .catch(createLoginErrorHandler(onError));

export const manualLogout = () => http.delete(endpoint);

export const validateTenant = (tenantCode: TenantCodeType) =>
  !!cookieSet.auth.get()?.tenants.find(tenant => tenant.code === tenantCode);

export default googleAuthorize;
