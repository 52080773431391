import style from './style.module.css';
import { useParams } from 'react-router';
import { Col, Row } from 'antd';
import Form from '../../../../components/common/form/Form';
import { Select } from '../../../../components/common/form/Select';
import { Input } from '../../../../components/common/form/Input';
import { PageHeaderWithBackButton } from '../../../../components/common/PageHeaderWithBackButton';
import { useFetchGameListingData } from '../../../../services/FaveArcade/useFetchGamesListing';
import { Empty, Image, Table } from 'fave-ui';
import { RenderColumn } from '../../../../helpers/tableHelpers/RenderColumn';

export default function GameDetailsView() {
  const { id } = useParams();
  const { data: campaignData } = useFetchGameListingData({ id: id });
  const rewards = campaignData?.rewards;
  const initialValues = {
    campaign_name: campaignData?.campaign_name,
    game_type: campaignData?.game_type,
    subtitle: campaignData?.subtitle,
    description: campaignData?.description,
    daily_play_limit: campaignData?.daily_play_limit,
    ticket_to_play: campaignData?.ticket_to_play,
  };

  const settingColumns = [
    {
      title: 'Tokens',
      dataIndex: 'no_of_token',
      width: 90,
      render: RenderColumn.rowText,
    },
    {
      title: '% Chance',
      dataIndex: 'reward_percentage',
      width: 90,
      render: RenderColumn.rowText,
    },
  ];

  return (
    <>
      <PageHeaderWithBackButton title={`View Campaign`} />
      <Form
        initialValues={initialValues}
        validateOnMount
        validateOnChange
        validateOnSchemaChange
      >
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Input
              name="campaign_name"
              label="* Campaign Name"
              placeholder=""
              bordered
              disabled
              style={{ marginBottom: 0 }}
            />
          </Col>
          <Col span={24}>
            <Select name="game_type" label="* Game" placeholder="" disabled />
          </Col>
          <Col span={24}>
            <Input
              name="subtitle"
              label="Game Subtitle"
              placeholder=""
              bordered
              disabled
              style={{ marginBottom: 0 }}
            />
          </Col>
          <Col span={24}>
            <Input
              type="textArea"
              name="description"
              label="* Game Description"
              placeholder=""
              bordered
              disabled
              style={{ marginBottom: 0 }}
            />
          </Col>
          <Col span={12}>
            <p className={style.label}>* Background Image</p>
            <Image
              src={campaignData?.background_image_url}
              alt="Background Image"
            />
          </Col>
          <Col span={12}>
            <p className={style.label}>* Thumbnail Image</p>
            <Image
              src={campaignData?.thumbnail_image_url}
              alt="Thumbnail Image"
            />
          </Col>
          <Col span={24}>
            <Input
              type="number"
              name="daily_play_limit"
              label="Daily Play Limit"
              placeholder=""
              bordered
              disabled
              style={{ marginBottom: 0 }}
            />
          </Col>
          <Col span={24}>
            <Input
              type="number"
              name="ticket_to_play"
              label="Tickets to Play"
              placeholder=""
              bordered
              disabled
              style={{ marginBottom: 0 }}
            />
          </Col>
          <Col span={12}>
            <p className={style.label}>* Reward Distribution</p>
            <Table
              size="small"
              columns={settingColumns}
              dataSource={rewards}
              pagination={{
                hideOnSinglePage: true,
              }}
              locale={{
                emptyText: !rewards?.length && (
                  <Empty customDescription="Empty! no reward distribution." />
                ),
              }}
            />
          </Col>
        </Row>
      </Form>
    </>
  );
}
