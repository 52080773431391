import style from './style.module.css';
import { PageHeaderWithSpace } from '../../../components/common/PageHeaderWithSpace/PageHeaderWithSpace';
import { useParams } from 'react-router';
import { Card, Col, Statistic } from 'antd';
import { Row } from 'fave-ui';
import ChangeHistoryTable from '../../../components/ChangeHistoryTable';
import { useFetchCampaignListingData } from '../../../services/CampaignManagementTool/useFetchCampaignListing';
import { formatDateTime } from '../../../utils/utilFunctions';

const CampaignChangeLogView = () => {
  const { id } = useParams();
  const parsedID = id ? parseInt(id) : undefined;
  const { data: campaignData, isLoading: isCampaignLoading } =
    useFetchCampaignListingData({ id: id });

  return (
    <>
      <PageHeaderWithSpace
        title={'Change Log'}
        subTitle={
          'One-stop for MRP to track and manage current, past and future campaigns'
        }
      />

      <Card style={{ width: '100%' }}>
        <Row className={style.summaryWrapper}>
          <Col>
            <h2 className={style.h2}>{'Change Log Summary'}</h2>
          </Col>
          <Col className={style.statsCol}>
            <Row className={style.statsRow}>
              <Statistic
                loading={isCampaignLoading}
                title="ID"
                value={campaignData?.id}
                className={style.statItem}
              />

              <Statistic
                loading={isCampaignLoading}
                title="Campaign"
                value={campaignData?.name}
                className={style.statItem}
              />
            </Row>
          </Col>
          <Col>
            <Row className={style.statsRow}>
              <Statistic
                loading={isCampaignLoading}
                title="Date created"
                value={formatDateTime(campaignData?.created_at)}
                className={style.statItem}
              />
            </Row>
          </Col>
        </Row>
      </Card>

      <Row className={style.table}>
        {parsedID && (
          <ChangeHistoryTable
            item_type={'ProductCampaign'}
            item_id={parsedID}
          />
        )}
      </Row>
    </>
  );
};

export default CampaignChangeLogView;
