import { Avatar, AvatarProps } from 'fave-ui';
import React, { ReactNode } from 'react';
import style from './style.module.css';

type MerchantAvatarProps = AvatarProps & {
  logo: ReactNode;
};

export const MerchantAvatar: React.FC<MerchantAvatarProps> = ({
  logo,
  size = 24,
}) => <Avatar className={style.merchantAvatar} size={size} src={logo} />;
