import { Table } from 'antd';
import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ColumnsType } from 'antd/lib/table';
import { defaultTableConfig } from '../../../helpers/defaults';
import { TableSettingsType } from '../../../types/configTypes';
import { format, parseISO } from 'date-fns';
import { RenderColumn } from '../../../helpers/tableHelpers/RenderColumn';
import {
  createOnTableChangeHandler,
  formatFilter,
  scrollTableConfig,
  valueOrDash,
} from '../../../utils/utilFunctions';
import { MySubmissionStatusType } from '../../../types/dataTypes';
import {
  BDPaymentDetailsType,
  useFetchFavePayList,
} from '../../../services/MySubmissions';
import { actionItems, statusFilter } from '../helpers';
import { Funnel } from 'phosphor-react';
import { mySubmissionElementIDs } from '..';

const favepayScrollTableConfig = scrollTableConfig();

export const FavepayTab: React.FC = () => {
  const [searchParams] = useSearchParams();

  const [tableConfig, setTableConfig] =
    useState<TableSettingsType>(defaultTableConfig);

  const tableChangeHandler = createOnTableChangeHandler(
    setTableConfig,
    filters =>
      formatFilter([
        { key: 'mc_my_submission_status', value: filters.status },
        { key: 'company.merchant_type', value: filters.merchant_type },
      ]),
  );

  const { data: favepaySubmissionData, isFetching } = useFetchFavePayList({
    page: tableConfig.current,
    filter: `partner=${searchParams.get('query') || ''}|${
      tableConfig.filter || ''
    }`,
    dataset: 'company,outlet',
  });

  const navigate = useNavigate();

  const favepaySubmissionColumns: ColumnsType<BDPaymentDetailsType> = [
    {
      title: 'Company',
      dataIndex: ['company', 'merchant_type'],
      key: 'merchant_type',
      width: 135,
      filters: [
        { text: 'Fave', value: 'fave' },
        { text: 'Fastpay', value: 'fastpay' },
      ],
      filterIcon: (
        <Funnel
          size={16}
          weight="light"
          data-id={mySubmissionElementIDs.favePayCompanyFilterIcon}
        />
      ),
      render: RenderColumn.merchantType,
    },
    {
      title: 'Submission Date',
      dataIndex: 'bd_submit_time',
      key: 'bd_submit_time',
      width: 150,
      render: (date: string) =>
        date ? format(parseISO(date), 'd MMM yyyy') : '-',
    },
    {
      title: 'Submitted By',
      dataIndex: 'bd_email',
      key: 'submitted_by',
      width: 200,
    },
    {
      title: 'Merchant Name',
      dataIndex: ['company', 'name'],
      key: 'merchant_name',
      width: 200,
    },
    {
      title: 'Outlet Name',
      dataIndex: ['outlet', 'name'],
      key: 'outlet_name',
      width: 200,
    },
    {
      title: 'Cashback (%)',
      dataIndex: 'cashback_rate',
      key: 'cashback',
      width: 170,
      render: data => valueOrDash(data, '%'),
    },
    {
      title: 'Fave Fee (%)',
      dataIndex: 'fee_percentage',
      key: 'fave_fee',
      width: 150,
      render: data => valueOrDash(data, '%'),
    },
    {
      title: 'Submission Status',
      dataIndex: 'status',
      width: 200,
      filterIcon: (
        <Funnel
          size={16}
          weight="light"
          data-id={mySubmissionElementIDs.favePaySubmissionStatusFilterIcon}
        />
      ),
      filters: statusFilter,
      filterMode: 'tree',
      render: (status: MySubmissionStatusType, data) =>
        RenderColumn.mySubmissionsStatusPopover(status, data, 'FavePay'),
    },
    {
      title: 'Actions',
      key: 'action',
      width: 135,
      render: (data: BDPaymentDetailsType) =>
        RenderColumn.dropdownMenu(
          actionItems(data, 'FavePay', navigate),
          '. . .',
          'bottomLeft',
          mySubmissionElementIDs.favePayActionBtn,
        ),
      fixed: 'right',
    },
  ];

  return (
    <Table
      size="large"
      columns={favepaySubmissionColumns}
      dataSource={favepaySubmissionData?.bd_payment_details}
      loading={isFetching}
      scroll={favepayScrollTableConfig}
      onChange={tableChangeHandler}
      pagination={{
        total: favepaySubmissionData?.count,
        position: ['bottomCenter'],
        pageSize: tableConfig.pageSize,
        current: tableConfig.page,
        showSizeChanger: false,
      }}
      rowKey={row => row.id}
    />
  );
};
