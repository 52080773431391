import { useMutation, useQuery, useQueryClient } from 'react-query';
import { TableConfigType } from '../../types/configTypes';
import {
  OutletStatusType,
  CampaignStatusType,
  UpdateStatusType,
  ConditionType,
} from '../../types/dataTypes';
import http, { createFetchAPI } from '../http';
import { changeHistoryCacheKey } from '../useFetchChangeHistory';
import { AxiosResponse, AxiosError } from 'axios';

const endpoint = '/v1/arcade/ticket_campaigns';
export const campaignListingCacheKey = 'fetchArcadeCampaignListing';

export type CampaignListingResponseType = {
  ticket_campaigns: CampaignListingItem[];
  count: number;
};

export type UpdateOrCreateCampaignListingError = { error: string };

export type CampaignListingItem = {
  id: number;
  campaign_name: string;
  campaign_type: string;
  no_of_ticket: number;
  status: OutletStatusType | CampaignStatusType;
  status_event: UpdateStatusType;
  start_date?: string;
  end_date?: string;
};

export type CampaignDataItem = {
  id: number;
  campaign_name: string;
  campaign_type: string;
  no_of_ticket: number;
  status: OutletStatusType | CampaignStatusType;
  status_event: UpdateStatusType;
  product_types: string[];
  payment_method_types: string[];
  amount_cents: number; // amount value in cents (RM 10.00)
  condition_type: ConditionType;
  page: number;
  currency?: string;
  created_at?: string;
};

export type CampaignOptionsItem = {
  campaign_types: string[];
  product_types: string[];
  payment_methods: string[];
  condition_types: ConditionType[];
  currency: string;
  page?: number;
};

const fetchCampaignListing = createFetchAPI<
  TableConfigType,
  CampaignListingResponseType
>(endpoint);

const fetchCampaignListingData = createFetchAPI<
  TableConfigType,
  CampaignDataItem
>();

const fetchCampaignOptionsData = createFetchAPI<
  TableConfigType,
  CampaignOptionsItem
>();

export const useFetchCampaignListing = (props: TableConfigType) =>
  useQuery<CampaignListingResponseType>(
    [campaignListingCacheKey, { ...props }],
    fetchCampaignListing,
    { staleTime: 300_000 }, // 5min
  );

export const useFetchCampaignListingData = ({ id }: { id?: number | string }) =>
  useQuery<CampaignDataItem>(
    ['fetchArcadeCampaignListingData', { url: `${endpoint}/${id}` }],
    fetchCampaignListingData,
    { enabled: !!id },
  );

export const useFetchCampaignOptions = () =>
  useQuery<CampaignOptionsItem>(
    ['fetchArcadeCampaignOptions', { url: `${endpoint}/options` }],
    fetchCampaignOptionsData,
    { staleTime: 300_000 },
  );

type AddCampaignPayload = {
  id?: number;
  campaign_name: string;
  campaign_type: string;
  product_types: string[];
  payment_method_types: string[];
  condition_type: string;
  amount_cents: number | undefined;
  no_of_ticket: number | undefined;
  start_date?: string;
  end_date?: string;
};

export const addNewCampaign = (payload: AddCampaignPayload) =>
  http.post<AddCampaignPayload>(endpoint, payload);

export function useAddCampaignMutation() {
  const queryClient = useQueryClient();

  return useMutation(
    async (payload: AddCampaignPayload) => addNewCampaign(payload),
    {
      onSuccess: async () => {
        // We need to either invalidate the cache for CampaignSummary and CampaignListing
        // or optimistically update the cached data.
        queryClient.invalidateQueries(campaignListingCacheKey);
      },
    },
  );
}

export type UpdateCampaignStatusPayload = {
  status_event: string;
  id: number | string;
  confirm?: boolean;
};

export type UpdateCampaignStatusError = {
  code: number;
  error: string;
  error_details: {
    conflict_campaigns: ConflictCampaign[];
  };
};

export type ConflictCampaign = {
  id: number | string;
  campaign_name: string;
};

export const updateCampaignStatus = (payload: UpdateCampaignStatusPayload) =>
  http.put<UpdateCampaignStatusPayload>(
    `${endpoint}/${payload.id}/update_status`,
    payload,
  );

export function useUpdateCampaignStatusMutation() {
  const queryClient = useQueryClient();

  return useMutation<
    AxiosResponse,
    AxiosError<UpdateCampaignStatusError>,
    UpdateCampaignStatusPayload
  >(
    async (payload: UpdateCampaignStatusPayload) =>
      updateCampaignStatus(payload),
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(campaignListingCacheKey);
        queryClient.invalidateQueries(changeHistoryCacheKey, {
          refetchInactive: true,
        });
      },
    },
  );
}

export type UpdateCampaignPayload = {
  campaign_name: string;
  campaign_type: string;
  no_of_ticket: number | undefined;
  id: number | string;
  confirm?: boolean;
};

export const updateCampaign = (payload: UpdateCampaignPayload) =>
  http.put<UpdateCampaignPayload>(`${endpoint}/${payload.id}/update`, payload);

export function useUpdateCampaignMutation() {
  const queryClient = useQueryClient();

  return useMutation(
    async (payload: UpdateCampaignPayload) => updateCampaign(payload),
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(campaignListingCacheKey);
        queryClient.invalidateQueries(changeHistoryCacheKey, {
          refetchInactive: true,
        });
      },
    },
  );
}
