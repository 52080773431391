import { PropsWithChildren } from 'react';
import style from './style.module.css';

const Banner = ({ children }: PropsWithChildren<{}>) => (
  <div className={style.container}>
    <small className={style.content}>{children}</small>
  </div>
);

export default Banner;
