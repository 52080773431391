import { ColumnsType } from 'antd/lib/table';
import { format, parseISO } from 'date-fns';
import { Table } from 'fave-ui';
import { Funnel } from 'phosphor-react';
import { useState } from 'react';

import { useSearchParams } from 'react-router-dom';
import { mySubmissionElementIDs } from '..';
import { TableHeaderSort } from '../../../components/common';
import { defaultTableConfig } from '../../../helpers/defaults';
import { RenderColumn } from '../../../helpers/tableHelpers/RenderColumn';
import {
  useFetchContractMigrationList,
  useMutateCancelContractMigration,
} from '../../../services/MySubmissions';
import { TableSettingsType } from '../../../types/configTypes';
import { MySubmissionStatusType } from '../../../types/dataTypes';
import {
  createOnTableChangeHandler,
  formatFilter,
  scrollTableConfig,
  valueOrDash,
} from '../../../utils/utilFunctions';
import {
  contractMigrationActionItems,
  contractMigrationStatusFilter,
} from '../helpers';

const scrollConfig = scrollTableConfig();

export const ContractMigrationTab: React.FC = () => {
  const [searchParams] = useSearchParams();
  const keyword = searchParams.get('query');

  const [tableConfig, setTableConfig] =
    useState<TableSettingsType>(defaultTableConfig);

  const tableChangeHandler = createOnTableChangeHandler(
    setTableConfig,
    filters =>
      formatFilter([{ key: 'mc_my_submission_status', value: filters.status }]),
  );

  const {
    data: contractMigrationData,
    isFetching,
    refetch,
  } = useFetchContractMigrationList({
    ...tableConfig,
    page: tableConfig.current,
    filter: `contract_migration_submission=true|partner=${keyword || ''}|${
      tableConfig.filter || ''
    }`,
    dataset: 'company',
  });

  const { mutateAsync: mutateCancelContractMigration } =
    useMutateCancelContractMigration();

  const ecardsTabColumns: ColumnsType<any> = [
    {
      title: TableHeaderSort(
        'Submission Date',
        mySubmissionElementIDs.contractMigrationSortSubmissionDateBtn,
      ),
      dataIndex: 'submission_time',
      sorter: true,
      width: 200,
      render: (date: string) =>
        date ? format(parseISO(date), 'd MMM yyyy') : '-',
    },
    {
      title: 'Merchant Name',
      dataIndex: ['company', 'name'],
      width: 200,
      render: RenderColumn.rowText,
    },
    {
      title: 'Outlets',
      dataIndex: ['company', 'outlets_count'],
      width: 200,
      render: data => valueOrDash(data, data > 1 ? ' outlets' : ' outlet'),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: 200,
      filterIcon: (
        <Funnel
          size={16}
          weight="light"
          data-id={
            mySubmissionElementIDs.contractMigrationSubmissionStatusFilterIcon
          }
        />
      ),
      filters: contractMigrationStatusFilter,
      filterMode: 'tree',
      render: (status: MySubmissionStatusType, data) =>
        RenderColumn.mySubmissionsStatusPopover(
          status,
          data,
          'Contract Migration',
        ),
    },
    {
      title: 'Actions',
      key: 'action',
      width: 120,
      render: data => {
        const actionItems = contractMigrationActionItems(
          data,
          mutateCancelContractMigration,
          refetch,
        );
        const hasActions = actionItems.length > 0;
        console.log('hasActions', actionItems);

        return hasActions
          ? RenderColumn.dropdownMenu(
              actionItems,
              '. . .',
              'bottomLeft',
              mySubmissionElementIDs.contractMigrationActionBtn,
            )
          : '';
      },
      fixed: 'right',
    },
  ];

  return (
    <Table
      dataSource={contractMigrationData?.mc_contract_submissions}
      columns={ecardsTabColumns}
      scroll={scrollConfig}
      onChange={tableChangeHandler}
      loading={isFetching}
      pagination={{
        total: contractMigrationData?.count,
        position: ['bottomCenter'],
        pageSize: tableConfig.pageSize,
        current: tableConfig.page,
        showSizeChanger: false,
        hideOnSinglePage: true,
      }}
      rowKey={row => row.id}
    />
  );
};
