import { Empty, Row, TabPane, Tabs } from 'fave-ui';
import React from 'react';
import { useParams } from 'react-router';
import { PageHeaderWithBackButton } from '../../components/common/PageHeaderWithBackButton';
import useCleverTap from '../../hooks/useCleverTap';
import {
  CLEVERTAP_ADDITIONAL,
  CLEVERTAP_SCREEN_NAME,
  CLEVERTAP_TAPPED_ON,
} from '../../helpers/ClevertapKeys';
import { useFetchMerchant } from '../../services/merchants';
import { BNPLTab } from './BNPLTab/BNPLTab';
import { DealsTab } from './DealsTab/DealsTab';
import { ECardsTab } from './ECardsTab/ECardsTab';
import { FavepayTab } from './FavepayTab/FavepayTab';
import style from './style.module.css';
import useOnceCallback from '../../hooks/useOnceCallback';
import { useFetchBDUsersList } from '../../services/ProductDashboard/bdUsersList';

export type ProductTabsProps = {
  company_id: number;
};

export const ProductDashboardView: React.FC = () => {
  const { id } = useParams();
  const parsedID = id ? parseInt(id) : 0;
  const { data: fetchMerchantData } = useFetchMerchant({
    company_id: parsedID,
    dataset: 'company_fpl_details',
  });
  const { data: bdUsersListData } = useFetchBDUsersList({
    company_id: parsedID,
  });
  const cleverTap = useCleverTap();

  useOnceCallback(() =>
    cleverTap.screenDisplayed.push({
      screen_name: CLEVERTAP_SCREEN_NAME.PRODUCT_DASHBOARD,
      platform: CLEVERTAP_ADDITIONAL.MERCHANT_CONNECT,
    }),
  );

  const productTabs = [
    {
      tab: 'FavePay',
      key: '1',
      children: (
        <FavepayTab
          company_id={parsedID}
          fixedFee={fetchMerchantData?.fixed_fee}
        />
      ),
    },
    {
      tab: 'Deals',
      key: '2',
      children: <DealsTab />,
    },
    {
      tab: 'eCards',
      key: '3',
      children: <ECardsTab company_id={parsedID} />,
    },
    {
      tab: 'BNPL',
      key: '4',
      children: (
        <BNPLTab
          company_id={parsedID}
          bnplData={fetchMerchantData}
          bdUsersListData={bdUsersListData}
        />
      ),
    },
  ];

  const onTapClickHandler = (key: string) =>
    cleverTap.tapped.push({
      screen_name: CLEVERTAP_SCREEN_NAME.PRODUCT_DASHBOARD,
      tapped_on:
        CLEVERTAP_TAPPED_ON.BUTTON_PRODUCT_TAP[
          key as keyof typeof CLEVERTAP_TAPPED_ON.BUTTON_PRODUCT_TAP
        ],
      platform: CLEVERTAP_ADDITIONAL.MERCHANT_CONNECT,
    });

  return (
    <div className={style.productDashboardView}>
      <Row>
        <PageHeaderWithBackButton
          title={fetchMerchantData?.name}
          subTitle={'The merchant product dashboard, all in one place.'}
        />
      </Row>
      {parsedID ? (
        <Tabs defaultActiveKey="1" onTabClick={key => onTapClickHandler(key)}>
          {productTabs.map(item => (
            <TabPane {...item} className={style.tabPane} />
          ))}
        </Tabs>
      ) : (
        <Empty emptyType={'cant-find-anything'} className={style.emptyHeight} />
      )}
    </div>
  );
};
