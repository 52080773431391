import { Spin } from 'antd';
import { useParams } from 'react-router';
import CardSection from '../../../../components/CardSection';
import { PageHeaderWithBackButton } from '../../../../components/common';
import useSetBreadCrumbParams from '../../../../hooks/useSetBreadCrumbParams';
import { useFetchFavePayDetails } from '../../../../services/MySubmissions';
import {
  customBooleanValue,
  dateOrDash,
  valueOrDash,
} from '../../../../utils/utilFunctions';

const FavePaySubmissionsDetailsView = () => {
  const { id } = useParams();
  const favepayID = String(id);

  const { data: favepaySubmissionsData, isFetching } = useFetchFavePayDetails({
    id: favepayID,
  });

  const isNotEmpty = favepaySubmissionsData !== undefined;

  useSetBreadCrumbParams(
    [
      { label: '', key: 'favepay' },
      {
        label: `${String(
          favepaySubmissionsData?.company?.name,
        )} (FavePay Detail)`,
        key: String(id),
      },
      { label: '', key: 'details' },
    ],
    isNotEmpty,
  );

  const favePayDescriptions = [
    {
      key: '1',
      label: 'Cashback %',
      children: valueOrDash(favepaySubmissionsData?.cashback_rate, '%'),
    },
    {
      key: '2',
      label: 'Fave Commission %',
      children: valueOrDash(favepaySubmissionsData?.fee_percentage, '%'),
    },
    {
      key: '3',
      label: 'Fave Commission Effective Date',
      children: dateOrDash(favepaySubmissionsData?.fave_fee_effective_date),
    },
    {
      key: '4',
      label: 'Onboarding Status',
      children: valueOrDash(favepaySubmissionsData?.onboarding_type),
    },
    {
      key: '5',
      label: 'Activate FavePay upon approval?',
      children: customBooleanValue(favepaySubmissionsData?.has_fave_payment),
    },
    {
      key: '6',
      label: 'FavePay Live date',
      children: dateOrDash(
        favepaySubmissionsData?.scheduled_favepay_enable_date,
      ),
    },
    {
      key: '7',
      label: 'Undiscounted Description',
      children: valueOrDash(favepaySubmissionsData?.undiscounted_description),
    },
    {
      key: '8',
      label: 'Remarks to Operations',
      children: valueOrDash(favepaySubmissionsData?.remarks),
    },
  ];

  const aliPayDescriptions = [
    {
      key: '1',
      label: 'Merchant accepts AliPay?',
      children: customBooleanValue(favepaySubmissionsData?.alipay_opt_in),
    },
    {
      key: '2',
      label: 'AliPay Category',
      children: valueOrDash(favepaySubmissionsData?.alipay_category?.name),
    },
    {
      key: '3',
      label: 'AliPay Discount %',
      children: valueOrDash(favepaySubmissionsData?.alipay_discount_percentage),
    },
    {
      key: '4',
      label: 'AliPay Fee %',
      children: valueOrDash(favepaySubmissionsData?.alipay_fee_percentage),
    },
    {
      key: '5',
      label: 'AliPay Fee Effective Date',
      children: dateOrDash(favepaySubmissionsData?.alipay_fee_effective_date),
    },
    {
      key: '6',
      label: 'Operating Days & Hours',
      children: valueOrDash(favepaySubmissionsData?.business_hours),
    },
  ];

  const applyToOutletsDescriptions = [
    {
      key: '1',
      label: 'Apply to Outlet',
      children: valueOrDash(favepaySubmissionsData?.outlet?.name),
    },
  ];

  const favepaySections = [
    {
      key: '1',
      title: 'FavePay Detail',
      data: favePayDescriptions,
    },
    {
      key: '2',
      title: 'AliPay Detail',
      data: aliPayDescriptions,
    },
    {
      key: '3',
      title: 'Apply to Outlet',
      data: applyToOutletsDescriptions,
    },
  ];

  return (
    <div>
      <PageHeaderWithBackButton
        title={valueOrDash(favepaySubmissionsData?.company?.name)}
        subTitle={'FavePay Detail'}
      />
      <Spin spinning={isFetching} style={{ width: '100%' }}>
        {favepaySections.map(({ key, ...restItems }) => (
          <CardSection key={key} sectionNumber={key} {...restItems} />
        ))}
      </Spin>
    </div>
  );
};

export default FavePaySubmissionsDetailsView;
