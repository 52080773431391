import { useQuery } from 'react-query';
import { createFetchAPI } from './http';
import { CompanyType, StatusType } from '../types/dataTypes';
import { SortOrder } from 'antd/lib/table/interface';
import { PayloadBaseType, TableConfigType } from '../types/configTypes';

export type MerchantListType = {
  fave_save_merchants: CompanyType[];
  count: number;
  page: number;
};

export type FetchMerchantSortType = {
  sort_order?: SortOrder;
};

export type FetchMerchantType = FetchMerchantSortType & {
  currentPage?: number;
  limit?: number;
  filter?: string;
  company_id?: number;
  dataset?: 'company_fpl_details' | 'outlets' | undefined;
};

export type FixedFeeType = {
  percentage: number;
  effective_date: string;
  disabled_date: string;
  status: StatusType;
  signature_date: string;
  signed_by: string;
  updated_at: string;
  updated_by: string;
};

export type CompanyFPLDetailsType = {
  id: number;
  payment_method: string;
  status: StatusType;
};

export type FetchMerchantResponseType = {
  id: number;
  name: string;
  fixed_fee?: FixedFeeType;
  freemium_status: StatusType;
  freemium_remaining_transactions: number;
  freemium_threshold: number;
  company_fpl_details: CompanyFPLDetailsType[];
};

export type FetchFlipperResponseType = {
  value: boolean;
};

export type FetchFlipperParams = {
  key: string;
};

const endpointCompanies = '/v1/companies';
const endpointMyMerchants = 'v1/fave_save_merchants';
const endpointFlipper = 'v1/flippers';

type FetchMerchantFinanceAccountsResponseType = {
  finance_accounts: FinanceAccountType[];
  total_count: number;
};

export type FinanceAccountType = {
  id: number;
  bank_account_name: string;
  bank_account_number: string;
  outlets: OutletsType[];
};

type OutletsType = {
  id: number;
  name: string;
};

const fetchMerchants = createFetchAPI<null, MerchantListType>(
  endpointMyMerchants,
);

export const useFetchMerchants = ({
  currentPage,
  ...restProps
}: FetchMerchantType) =>
  useQuery<MerchantListType>(
    [
      'merchantList',
      {
        page: currentPage,
        ...restProps,
      },
    ],
    fetchMerchants,
  );

const fetchMerchantFinanceAccounts = createFetchAPI<
  { props: TableConfigType; merchantID: string },
  FetchMerchantFinanceAccountsResponseType
>();

export const useFetchMerchantFinanceAccounts = ({
  tableConfigs,
  merchantID,
  dataset,
}: {
  tableConfigs: TableConfigType;
  merchantID: number;
  dataset?: string;
}) =>
  useQuery<FetchMerchantFinanceAccountsResponseType>(
    [
      'fetchMerchantFinanceAccounts',
      {
        ...tableConfigs,
        url: `${endpointCompanies}/${merchantID}/finance_accounts`,
        dataset,
      },
    ],
    fetchMerchantFinanceAccounts,
  );

const fetchMerchant = createFetchAPI<
  PayloadBaseType,
  FetchMerchantResponseType
>();

export const useFetchMerchant = (props: PayloadBaseType) =>
  useQuery<FetchMerchantResponseType>(
    [
      `fetchMerchant`,
      { ...props, url: `${endpointCompanies}/${props.company_id}` },
    ],
    fetchMerchant,
  );

const fetchFlipper = createFetchAPI<
  FetchFlipperParams,
  FetchFlipperResponseType
>(endpointFlipper);

export const useFetchFlipper = (props: FetchFlipperParams) =>
  useQuery<FetchFlipperResponseType>(
    [`fetchFlipper`, { ...props }],
    fetchFlipper,
  );
