import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';
import { PaginatedResponse, TableSettingsType } from '../types/configTypes';
import http, { createFetchAPI } from './http';
import { AxiosResponse } from 'axios';

const endpoint = 'v1/merchant_settlements';
const queryKeys = {
  fetchSettlementList: 'fetchSettlementList',
  fetchPaymentFile: 'fetchPaymentFile',
  fetchDownloadPaymentFile: 'fetchDownloadPaymentFile',
};

const fetchSettlementList = createFetchAPI<
  TableSettingsType,
  MerchantSettlementResponse
>(endpoint);

export const useFetchSettlementList = (params: TableSettingsType) =>
  useQuery<MerchantSettlementResponse>(
    [queryKeys.fetchSettlementList, params],
    fetchSettlementList,
  );

const fetchPaymentFile = createFetchAPI<
  FetchPaymentFileParams,
  PaymentFileResponse
>();

export const useFetchPaymentFile = (params: FetchPaymentFileParams) =>
  useInfiniteQuery<PaymentFileResponse>(
    [
      queryKeys.fetchPaymentFile,
      { url: `${endpoint}/${params.id}`, ...params },
    ],
    fetchPaymentFile,
    {
      enabled: !!params.id,
      getNextPageParam: response =>
        response.original_file.length ? response.page + 1 : undefined,
    },
  );

export const fetchDownloadPaymentFile = createFetchAPI<
  FetchDownloadPaymentFile,
  FetchDownloadPaymentFileResponse
>();

export const useFetchDownloadPaymentFile = ({ id }: FetchDownloadPaymentFile) =>
  useQuery(
    [queryKeys.fetchDownloadPaymentFile, { url: `${endpoint}/${id}/download` }],
    fetchDownloadPaymentFile,
    // set to false to prevent from automatically executed
    // will run when user click button
    { enabled: false },
  );

const mutateApprovePaymentFile = (payload: ApprovePaymentFilePayload) =>
  http.put<ApprovePaymentFilePayload, ApprovePaymentFileResponse>(
    `${endpoint}/${payload.id}/approve`,
    payload,
  );

const mutateRejectPaymentFile = (payload: RejectPaymentFilePayload) =>
  http.put<RejectPaymentFilePayload, RejectPaymentFileResponse>(
    `${endpoint}/${payload.id}/reject`,
    payload,
  );

export function useMutateApprovePaymentFile() {
  const queryClient = useQueryClient();

  return useMutation(mutateApprovePaymentFile, {
    onSuccess: async () => {
      queryClient.invalidateQueries(queryKeys.fetchPaymentFile);
      queryClient.invalidateQueries(queryKeys.fetchSettlementList);
    },
  });
}

export function useMutateRejectPaymentFile() {
  const queryClient = useQueryClient();

  return useMutation(mutateRejectPaymentFile, {
    onSuccess: async () => {
      queryClient.invalidateQueries(queryKeys.fetchPaymentFile);
      queryClient.invalidateQueries(queryKeys.fetchSettlementList);
    },
  });
}

export type RejectSettlementParams = Pick<MerchantSettlement, 'id'> & {
  settlement_rejected_reason: string;
};

type PaymentFileType = 'Initial File' | 'Recon File' | 'Rejected File';

type PaymentCadence = 'Daily' | 'Semi-weekly' | 'Weekly';

type RejectedFile = {
  id: number;
  payment_file_type: PaymentFileType;
  payment_file_name: string;
};

export type SettlementStatus =
  | 'Pending Review'
  | 'Reviewer Rejected'
  | 'Approver Rejected'
  | 'Approver Pending'
  | 'Pending Bank'
  | 'Settlement in Progress'
  | 'L2_ERROR'
  | 'L3_ERROR'
  | 'L4_ERROR'
  | 'L4_INCOMPLETE'
  | 'Bank Rejected'
  | 'Partial Bank Rejection'
  | 'Completed';

export type SettlementFileInfo = {
  id: number;
  payment_file_type: PaymentFileType;
  payment_file_name: string;
  settlement_rejected_reason?: string;
  created_at: Date;
  status: string;
  status_key: SettlementStatus;
  total_settlement_amount: string;
  total_settlement_transactions: string;
  rejected_file?: RejectedFile;
};

export type SettlementDetailsGroup = {
  created_at: Date;
  payment_cadences: PaymentCadence[];
  details: SettlementFileInfo[];
};

export type MerchantSettlement = {
  id: number;
  payment_file_reference_id: string;
  details_grouped_by_generated_date: SettlementDetailsGroup[];
};

type MerchantSettlementResponse = PaginatedResponse<
  'merchant_settlements',
  MerchantSettlement
> &
  ApprovalLabels;

// labels are now dynamic, depends on whatever the label is being named
// in the API response
type ApprovalLabels = { approval_labels: string[] };

type FetchPaymentFileParams = TableSettingsType & {
  id: number;
};

type PaymentFileResponse = {
  // original file is different between UOB and Citibank
  // will update the types once we know the structure
  original_file: UobPaymentFile[];
  count: number;
  total_count: number;
  total_pages: number;
  page: number;
  can_approve: boolean;
  can_reject: boolean;
};

// for SG tenant
export type UobPaymentFile = {
  payment_id: string;
  cust_reference: string;
  currency: string;
  amount: number;
  cust_name: string;
  cust_country: string;
  cust_account_number: string;
  cust_swift_code: string;
  purpose: string;
  email: string;
  finance_account: string;
  company_id: number;
  company_name: string;
  bank_branch: string;
  payment_batch_id: string;
  execution_date: Date;
  payer_name: string;
  payer_country: string;
  payer_org_id: string;
  payer_account_number: string;
  payer_currency: string;
  payer_swift_code: string;
  payer_bank_country: string;
  service_code: string;
  payment_method: string;
};

type FetchDownloadPaymentFile = {
  id: number;
};

type FetchDownloadPaymentFileResponse = {
  id: number;
  payment_file_name: string;
  file: string;
};

type ApprovePaymentFilePayload = {
  id: number;
};

type RejectPaymentFilePayload = {
  id: number;
};

type ApprovePaymentFileResponse = AxiosResponse<PaymentFileSuccessResponse>;

type RejectPaymentFileResponse = AxiosResponse<PaymentFileSuccessResponse>;

export type PaymentFileSuccessResponse = {
  merchant_settlement: Omit<
    MerchantSettlement,
    'details_grouped_by_generated_date'
  >;
  merchant_settlement_approval: MerchantSettlementApproval;
};

type MerchantSettlementApproval = {
  id: number;
  finance_user: FinanceUser;
  approval_level: string;
};

type FinanceUser = {
  id: number;
  name: string;
};
