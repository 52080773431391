import React from 'react';
import style from './style.module.css';

type TableTotalDataProps = {
  currentPageDataLength: number | undefined;
  tableDataLength?: number;
  currentPage: number | undefined;
  tableLimit?: number;
};

const calcTotalDataInCurrentPage = (
  currentPageDataLength: number | undefined,
  currentPage: number | undefined,
  tableLimit?: number,
) => {
  if (currentPageDataLength && currentPage && tableLimit) {
    if (currentPageDataLength === tableLimit) return currentPage * tableLimit;
    else return currentPageDataLength + (currentPage - 1) * tableLimit;
  }
  return 0;
};

const TotalSubmissions: React.FC<TableTotalDataProps> = ({
  // in case table is empty, no data
  currentPageDataLength = 0,
  tableDataLength,
  currentPage,
  tableLimit,
}) => {
  const currentPageFirstDataNumber =
    currentPageDataLength < 1
      ? 0
      : currentPage && tableLimit && (currentPage - 1) * tableLimit + 1;

  const currentPageDataTotal = calcTotalDataInCurrentPage(
    currentPageDataLength,
    currentPage,
    tableLimit,
  );
  return (
    <p>
      {currentPageFirstDataNumber} - {currentPageDataTotal} of{' '}
      <span className={style.boldText}>
        {tableDataLength}{' '}
        {tableDataLength && tableDataLength > 1 ? 'submissions' : 'submission'}
      </span>
    </p>
  );
};

export default TotalSubmissions;
