import {
  Notification,
  UploadFile,
  PopConfirm,
  Button,
  Row,
  Col,
} from 'fave-ui';
import { PageHeaderWithBackButton } from '../../../../components/common';
import { FormikHelpers } from 'formik';
import { appendFormData } from '../../../../utils/utilFunctions';
import {
  GamesDataItem,
  useFetchGameListingData,
  useUpdateGameCampaignMutation,
} from '../../../../services/FaveArcade/useFetchGamesListing';
import { useNavigate, useParams } from 'react-router';
import { Input } from '../../../../components/common/form/Input';
import Form from '../../../../components/common/form/Form';
import SubmitButton from '../../../../components/common/form/SubmitButton';
import ImageUpload from '../../../../components/common/form/ImageUpload';
import * as Yup from 'yup';

type GameCampaignFormFields = Omit<
  GamesDataItem,
  | 'id'
  | 'game_type'
  | 'status'
  | 'thumbnail_image_url'
  | 'background_image_url'
  | 'rewards'
  | 'created_at'
  | 'page'
> & {
  thumbnail_image: UploadFile[];
  background_image: UploadFile[];
};

const GameCampaignFormSchema = Yup.object().shape({
  campaign_name: Yup.string().required('Please give your campaign a name'),
  description: Yup.string().required('Please give your campaign a description'),
  daily_play_limit: Yup.number()
    .min(0)
    .required('Please enter the daily play limit'),
  ticket_to_play: Yup.number()
    .min(0)
    .required('Please enter enter the number of tickets to play'),
  thumbnail_image: Yup.array()
    .of(Yup.mixed().nullable().required('An image is required'))
    .min(1, 'Please upload an image'),
  background_image: Yup.array()
    .of(Yup.mixed().nullable().required('An image is required'))
    .min(1, 'Please upload an image'),
});

const successNotification = () =>
  Notification.success({
    message: 'Game Campaign Successfully Updated!',
    description: 'Your edit has been saved succesfully',
  });

export default function GameCampaignEditView() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data: campaignData } = useFetchGameListingData({ id: id });
  const defaultThumbnailFile: UploadFile = {
    uid: 'default-uid',
    name: 'default-file',
    url: campaignData?.thumbnail_image_url,
  };
  const defaultBackgroundFile: UploadFile = {
    uid: 'default-uid',
    name: 'default-file',
    url: campaignData?.background_image_url,
  };
  const initialValues = {
    ...campaignData,
    thumbnail_image: [defaultThumbnailFile],
    background_image: [defaultBackgroundFile],
  };

  const { mutateAsync: editGameCampaign } = useUpdateGameCampaignMutation();

  const handleSubmit = async (
    formValues: GameCampaignFormFields,
    { setSubmitting }: FormikHelpers<GameCampaignFormFields>,
  ) => {
    const payload = {
      ...formValues,
      thumbnail_image:
        formValues.thumbnail_image &&
        formValues.thumbnail_image.length > 0 &&
        formValues.thumbnail_image[0].originFileObj != undefined
          ? formValues.thumbnail_image[0].originFileObj
          : [],
      background_image:
        formValues.background_image &&
        formValues.background_image.length > 0 &&
        formValues.background_image[0].originFileObj != undefined
          ? formValues.background_image[0].originFileObj
          : [],
    };
    const formData = appendFormData(payload);
    try {
      await editGameCampaign(
        { id: id, formData: formData },
        {
          onSuccess: () => {
            successNotification();
          },
        },
      );
    } finally {
      setSubmitting(false);
      navigate('/fave_arcade/games_center');
    }
  };

  return (
    <>
      <PageHeaderWithBackButton title={`Edit Campagin`} />
      <Form
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={GameCampaignFormSchema}
        validateOnMount
        validateOnChange
        validateOnSchemaChange
      >
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Input
              name="campaign_name"
              label="Campaign Name"
              placeholder="Enter campaign name"
              allowClear
              bordered
              style={{ marginBottom: 0 }}
            />
          </Col>
          <Col span={24}>
            <Input
              name="subtitle"
              label="Game Subtitle (Optional)"
              placeholder="Enter game subtitle"
              allowClear
              bordered
              style={{ marginBottom: 0 }}
            />
          </Col>
          <Col span={24}>
            <Input
              type="textArea"
              name="description"
              label="Game Description"
              placeholder="Enter game description"
              bordered
              style={{ marginBottom: 0 }}
            />
          </Col>
          <Col span={12}>
            <ImageUpload
              name="background_image"
              label="Background Image"
              types={['image/jpeg', 'image/png']}
              imgSize={2}
              defaultDisplayImgList={[defaultBackgroundFile]}
            />
          </Col>
          <Col span={12}>
            <ImageUpload
              name="thumbnail_image"
              label="Thumbnail Image"
              types={['image/jpeg', 'image/png']}
              imgSize={2}
              defaultDisplayImgList={[defaultThumbnailFile]}
            />
          </Col>
          <Col span={24}>
            <Input
              type="number"
              name="daily_play_limit"
              label="Daily Play Limit"
              placeholder="Enter daily limit"
              allowClear
              bordered
              style={{ marginBottom: 0 }}
            />
          </Col>
          <Col span={24}>
            <Input
              type="number"
              name="ticket_to_play"
              label="Tickets to Play"
              placeholder="Enter number of tickets"
              allowClear
              bordered
              style={{ marginBottom: 0 }}
            />
          </Col>

          <Col span={24}>
            <SubmitButton disableIfNotValid disableIfNotDirty>
              {(_, isSubmitting) => (isSubmitting ? 'Submiting...' : 'Save')}
            </SubmitButton>
            <PopConfirm
              title="Are you sure you want to cancel? All progress will be lost for the current campaign."
              onConfirm={() => navigate('/fave_arcade/games_center')}
              okText="Yes, I am sure"
              cancelText="Go Back"
            >
              <Button type="primary" size="large">
                Cancel
              </Button>
            </PopConfirm>
          </Col>
        </Row>
      </Form>
    </>
  );
}
