import { ColumnsType } from 'antd/lib/table';
import { Table, Tag } from 'fave-ui';
import { useParams } from 'react-router';
import { PageHeaderWithBackButton } from '../../../../components/common';
import useSetBreadCrumbParams from '../../../../hooks/useSetBreadCrumbParams';
import {
  MCCCompanyIPPTenures,
  useFetchIPPDetails,
} from '../../../../services/MySubmissions';
import { EnabledStatusType } from '../../../../types/dataTypes';
import { valueOrDash } from '../../../../utils/utilFunctions';
import style from './style.module.css';

const bankStyles = (status: EnabledStatusType) =>
  status === 'enabled' ? style.enabledBank : style.disabledBank;

const InstallationPaymentPlanDetailsView = () => {
  const { id } = useParams();
  const ippID = String(id);

  const { data: ippDetailsData } = useFetchIPPDetails({
    id: ippID,
    dataset: 'company',
  });

  const isNotEmpty = ippDetailsData !== undefined;

  useSetBreadCrumbParams(
    [
      { label: '', key: 'ipp' },
      {
        label: `${String(ippDetailsData?.company?.name)} (IPP Detail)`,
        key: String(id),
      },
      { label: '', key: 'details' },
    ],
    isNotEmpty,
  );

  const tenureArray = ippDetailsData?.mc_company_ipp_tenures.filter(
    // filter tenures that are enabled only
    item => item.status === 'enabled',
  );

  const banksArray = ippDetailsData?.mc_company_ipp_issuers.map(item => item);

  const ippDetailsColumns: ColumnsType<MCCCompanyIPPTenures> = [
    {
      title: 'Tenure',
      dataIndex: 'tenure',
      width: 200,
      render: data => valueOrDash(data, ' mth'),
    },
    {
      title: 'Fee %',
      dataIndex: 'rate',
      width: 200,
      render: data => valueOrDash(data, ' %'),
    },
    {
      title: 'Banks',
      width: 200,
      render: () => (
        <div className={style.banks}>
          {banksArray?.map(item => (
            <Tag key={item.id} className={`m-2 ${bankStyles(item.status)}`}>
              {item.ipp_issuer.name}
            </Tag>
          ))}
        </div>
      ),
      onCell: (_, index) =>
        index === 0
          ? {
              rowSpan: tenureArray?.length,
            }
          : { rowSpan: 0 },
    },
  ];

  return (
    <>
      <PageHeaderWithBackButton
        title={valueOrDash(ippDetailsData?.company?.name)}
        subTitle={'IPP Detail'}
      />
      <Table
        columns={ippDetailsColumns}
        dataSource={tenureArray}
        rowKey={key => key.id}
        pagination={{ hideOnSinglePage: true }}
      />
    </>
  );
};

export default InstallationPaymentPlanDetailsView;
