import { ColumnsType } from 'antd/lib/table';
import { FilterIcon, Table } from 'fave-ui';
import React, { useEffect, useState } from 'react';
import { StatusTag } from '../../../components/common/StatusTag';
import { TableHeaderSort } from '../../../components/common/TableHeaderSort';
import { ProductSummaryCard } from '../../../components/ProductSummaryCard/ProductSummaryCard';
import { TextWithPopover } from '../../../components/TextWithPopover/TextWithPopover';
import { RenderColumn } from '../../../helpers/tableHelpers/RenderColumn';
import {
  CompanyFPLDetailsType,
  FetchMerchantResponseType,
} from '../../../services/merchants';
import { FetchBDUsersListResponseType } from '../../../services/ProductDashboard/bdUsersList';
import {
  BnplFeeModelType,
  useFetchBNPLFeeModels,
} from '../../../services/ProductDashboard/useFetchBNPLFeeModels';
import { useFetchCompanyOutlets } from '../../../services/useFetchCompanyOutlets';
import { TableSettingsType } from '../../../types/configTypes';
import { OutletStatusType, StatusType } from '../../../types/dataTypes';
import {
  calcTableHeight,
  calcTableWidth,
  dateOrDash,
  valueOrDash,
  formatFilter,
  createOnTableChangeHandler,
} from '../../../utils/utilFunctions';
import { ProductTabsProps } from '../ProductDashboardView';
import style from '../style.module.css';

type BNPLTabProps = ProductTabsProps & {
  bnplData?: FetchMerchantResponseType;
  bdUsersListData?: FetchBDUsersListResponseType[];
};

const tableHeight = calcTableHeight();
const tableWidth = calcTableWidth();

const tableConfigDefault = {
  page: 1,
  limit: 10,
  sort_order: undefined,
  sort_by: undefined,
  filter: undefined,
};

export const BNPLTab: React.FC<BNPLTabProps> = ({
  company_id,
  bnplData,
  bdUsersListData,
}) => {
  const [tableConfigs, setTableConfigs] = useState<
    (TableSettingsType<BnplFeeModelType> & CompanyFPLDetailsType)[]
  >([]);

  const { data: companyOutletsData } = useFetchCompanyOutlets({
    company_id,
    scope: 'favepay',
  });

  const bnplFeeModelsData = useFetchBNPLFeeModels(tableConfigs);

  useEffect(() => {
    if (bnplData?.company_fpl_details) {
      const newTableConfigs = bnplData.company_fpl_details.map(companyInfo => {
        return {
          ...companyInfo,
          ...tableConfigDefault,
          handler: createOnTableChangeHandler(
            setTableConfigs,
            filters =>
              formatFilter([
                { key: 'submitted_by_id', value: filters.bd_name },
                { key: 'bnpl_status', value: filters.status },
              ]),
            undefined,
            companyInfo.id,
          ),
        };
      });

      setTableConfigs(newTableConfigs);
    }
  }, [bnplData]);

  const hasFreemiumData = !!bnplData?.freemium_threshold;

  const outletsPopoverData = (status: OutletStatusType) =>
    companyOutletsData?.outlets
      .filter(item => item.status === status)
      .map(item => {
        const hasFees = item.fave_fees;
        return {
          ...item,
          name: (
            <span className={!hasFees ? style.noFeesText : ''}>
              {`${item.name} ${hasFees ? `(${item.fave_fees}%)` : '(N/A)'}`}
            </span>
          ),
        };
      });

  const activeOutlets = outletsPopoverData('active');
  const inactiveOutlets = outletsPopoverData('inactive');

  const fplFreemiumDescriptionItems = [
    {
      key: '1',
      label: 'Freemium transactions left',
      children: bnplData?.freemium_remaining_transactions,
    },
    {
      key: '2',
      label: 'Total freemium transactions',
      children: bnplData?.freemium_threshold,
    },
  ];

  const fixedFeeDescriptionItems = [
    {
      key: '1',
      label: 'Fee amount',
      children: valueOrDash(
        bnplData?.fixed_fee ? `${bnplData?.fixed_fee.percentage}%` : '-',
      ),
    },
    {
      key: '2',
      label: 'Effective date',
      children: dateOrDash(bnplData?.fixed_fee?.effective_date),
    },
    {
      key: '3',
      label: `${
        bnplData?.fixed_fee?.signature_date ? 'Date signed' : 'Updated on'
      }`,
      children: dateOrDash(
        bnplData?.fixed_fee?.signature_date || bnplData?.fixed_fee?.updated_at,
      ),
    },
    {
      key: '4',
      label: `${
        bnplData?.fixed_fee?.signature_date ? 'Signed by' : 'Updated by'
      }`,
      children: valueOrDash(
        bnplData?.fixed_fee?.signed_by || bnplData?.fixed_fee?.updated_by,
      ),
    },
  ];

  const outletsFaveFeeDescriptionItems = [
    {
      key: '1',
      children: (
        <TextWithPopover
          text={'Active outlets'}
          popoverTitle={`${valueOrDash(activeOutlets?.length)} outlets`}
          data={activeOutlets}
        />
      ),
    },
    {
      key: '2',
      children: (
        <TextWithPopover
          text={'Inactive outlets'}
          popoverTitle={`${valueOrDash(inactiveOutlets?.length)} outlets`}
          data={inactiveOutlets}
        />
      ),
    },
  ];

  const bnplColumns: ColumnsType<BnplFeeModelType> = [
    {
      dataIndex: 'signature_date',
      title: TableHeaderSort('Date Signed'),
      sorter: true,
      width: 135,
      render: date => dateOrDash(date),
    },
    {
      dataIndex: 'bd_name',
      title: 'Signed by',
      filters: bdUsersListData
        ? bdUsersListData.map(item => {
            return { text: item.bd_name, value: item.id };
          })
        : [],
      filterIcon: FilterIcon,
      filterMode: 'tree',
      filterSearch: true,
      width: 160,
      render: RenderColumn.rowText,
    },
    {
      dataIndex: 'status',
      title: 'Status',
      filters: [
        { text: `Live`, value: 'Live' },
        { text: `Inactive`, value: 'Inactive' },
      ],
      filterMode: 'tree',
      filterIcon: FilterIcon,
      width: 160,
      render: (data: StatusType) => <StatusTag status={data} />,
    },
    {
      dataIndex: 'model',
      title: 'Fee Model',
      width: 135,
      render: RenderColumn.rowText,
    },
    {
      title: 'Duration',
      width: 230,
      render: data => {
        return `${dateOrDash(data.start_date)} - ${
          data.end_date ? dateOrDash(data.end_date) : 'onwards'
        }`;
      },
    },
    {
      dataIndex: 'cashback_reduction',
      title: 'Cashback Offset',
      width: 150,
      render: data => (data ? `${valueOrDash(data)}%` : '-'),
    },
    {
      dataIndex: 'fee',
      title: 'FPL Fee',
      width: 135,
      render: data => (data ? `${valueOrDash(data)}%` : '-'),
    },
  ];

  return (
    <div className={style.bnplTab}>
      {hasFreemiumData && (
        <ProductSummaryCard
          title={'FPL Freemium'}
          status={bnplData?.freemium_status}
          descriptionItems={fplFreemiumDescriptionItems}
        />
      )}
      <ProductSummaryCard
        title={'Fixed Fee'}
        status={bnplData?.fixed_fee?.status}
        descriptionItems={fixedFeeDescriptionItems}
      />
      <ProductSummaryCard
        title={'Outlets & Fave Fee'}
        descriptionItems={outletsFaveFeeDescriptionItems}
      />
      <p className={style.tablesTitle}>BNPL Lists</p>
      {bnplFeeModelsData?.map((item, index) => (
        <Table
          key={index}
          title={() => (
            <div>
              <strong style={{ marginRight: '0.5rem', fontSize: '1rem' }}>
                {tableConfigs[index].payment_method}
              </strong>
              <StatusTag status={tableConfigs[index].status} />
            </div>
          )}
          size={'large'}
          rowKey={row => row.id}
          dataSource={item.data?.bnpl_fee_models}
          columns={bnplColumns}
          loading={item.isFetching}
          scroll={{
            x: tableWidth,
            y: tableHeight,
          }}
          onChange={tableConfigs[index].handler}
          className={style.marginBottom}
        />
      ))}
    </div>
  );
};
