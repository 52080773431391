import { useState } from 'react';
import { useNavigate } from 'react-router';
import { Button } from 'fave-ui';

import LoginLayout from '../LoginLayout';
import OTPForm from './OTPForm';
import PasswordResetForm from './PasswordResetForm';
import style from '../ForgotPasswordFormView/style.module.css';

export type PasswordRecoveryType = {
  email?: string;
  password?: string;
  otp?: string;
};

const ForgotPasswordFormView = () => {
  const [email, setEmail] = useState('');
  const [newPasswordForm, setNewPasswordForm] = useState(false);
  const [errorText, setErrorText] = useState('');
  const navigate = useNavigate();

  return (
    <LoginLayout errorText={errorText}>
      <div className={style.forgotTitle}>
        <span>Don't worry, it happens to the best of us ;)</span>
        <span>We just need your email address.</span>
      </div>
      {newPasswordForm ? (
        <>
          <PasswordResetForm email={email} setErrorText={setErrorText} />
          <Button
            type="text"
            size="large"
            onClick={() => {
              setNewPasswordForm(false);
              setEmail('');
            }}
          >
            Back to sent OTP
          </Button>
        </>
      ) : (
        <>
          <OTPForm
            email={email}
            setEmail={setEmail}
            setErrorText={setErrorText}
            setNewPasswordForm={setNewPasswordForm}
          />
          <Button type="text" size="large" onClick={() => navigate('/login')}>
            Back to sign in
          </Button>
        </>
      )}
    </LoginLayout>
  );
};

export default ForgotPasswordFormView;
