import { useRef, useCallback } from 'react';

const useDebouncedCallback = (func: (args: any) => void, wait = 400) => {
  const timeout = useRef<NodeJS.Timeout>();

  return useCallback(
    (...args) => {
      const later = () => {
        if (timeout.current) clearTimeout(timeout.current);
        func(args);
      };

      if (timeout.current) clearTimeout(timeout.current);
      timeout.current = setTimeout(later, wait);
    },
    [func, wait],
  );
};

export default useDebouncedCallback;
