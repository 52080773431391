import React from 'react';
import { Modal as FaveModal, ModalProps } from 'fave-ui';
import { Form } from '../common/form';
import { FormikContextType } from 'formik';
import { Spin } from '../common';

type FormModalProps = ModalProps & {
  formRef?: React.MutableRefObject<FormikContextType<{}> | undefined>;
  initialValuesForm?: { [key: string]: unknown };
  validationFormSchema?: unknown;
  visible: boolean;
};

export const FormModal = <T extends {}>({
  formRef,
  initialValuesForm = {},
  validationFormSchema = null,
  visible = false,
  confirmLoading = false,
  children,
  ...restProps
}: FormModalProps) => {
  return (
    <FaveModal
      {...restProps}
      confirmLoading={confirmLoading}
      centered
      visible={visible}
    >
      <Form
        formRef={formRef}
        initialValues={initialValuesForm}
        onSubmit={(values: T) => values}
        validationSchema={validationFormSchema}
        validateOnMount
        validateOnChange
        validateOnSchemaChange
      >
        <Spin spinning={confirmLoading}>{children}</Spin>
      </Form>
    </FaveModal>
  );
};
