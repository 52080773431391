import { useContext, useState } from 'react';
import { useNavigate } from 'react-router';
import { Button } from 'fave-ui';
import * as Yup from 'yup';

import style from '../LoginFormView/style.module.css';
import GoogleIconSVG from '../../assests/icons/googleIconSVG';
import Form from '../../components/common/form/Form';
import SubmitButton from '../../components/common/form/SubmitButton';
import { Input } from '../../components/common/form/Input';
import googleAuthorize, { manualAuthorize } from '../../services/auth';
import LoginLayout, { useElementIdContext } from '../LoginLayout';
import { UserContext } from '../../contexts/UserContext';
import { useSearchParams } from 'react-router-dom';
import { UserType } from '../../types/dataTypes';
import { FormikHelpers } from 'formik';

type LoginFormField = {
  email: string;
  password: string;
  isLoading?: boolean;
  isError?: boolean;
};

const form: LoginFormField = {
  email: '',
  password: '',
};

const loginFormSchema = Yup.object().shape({
  email: Yup.string()
    .email('Please enter a valid email address')
    .required('Required'),
  password: Yup.string().required('Required'),
});

const LoginFormView = () => {
  const navigate = useNavigate();
  const { login } = useContext(UserContext);
  const [errorText, setErrorText] = useState('');
  const [queryString] = useSearchParams();

  const handleLoginSuccess = (res: UserType) => {
    login(res);
    navigate(queryString.get('from') || '/');
  };

  const handleManualLogin = (
    value: LoginFormField,
    { setSubmitting }: FormikHelpers<LoginFormField>,
  ) =>
    manualAuthorize(value.email, value.password, handleLoginSuccess, e => {
      setErrorText(e);
      setSubmitting(false);
    });

  const { btnLogin, inputEmail, inputPassword } = useElementIdContext();

  return (
    <LoginLayout errorText={errorText}>
      <span className={style.loginTitle}>Welcome back, Fave-r!</span>
      <Button
        className={style.googleBtn}
        type="default"
        size="large"
        iconPlacement="start"
        icon={<GoogleIconSVG />}
        onClick={() =>
          googleAuthorize(handleLoginSuccess, e => setErrorText(e))
        }
      >
        Sign in with Google
      </Button>
      <span className={style.dividerText}>or</span>
      <Form
        initialValues={form}
        onSubmit={handleManualLogin}
        validationSchema={loginFormSchema}
        validateOnMount
        validateOnChange
        validateOnSchemaChange
      >
        <Input
          name="email"
          label="Email address"
          placeholder="email@myfave.com"
          allowClear
          bordered
          data-id={inputEmail}
        />
        <Input
          name="password"
          label="Password"
          placeholder="Password"
          type={'password'}
          allowClear
          bordered
          data-id={inputPassword}
        />
        <div className={style.actionButton}>
          <SubmitButton disableIfNotValid data-id={btnLogin}>
            {(_, isSubmitting) =>
              isSubmitting ? 'Signing in to Fave' : 'Sign in to Fave'
            }
          </SubmitButton>
          <Button
            type="text"
            size="large"
            onClick={() => navigate(`/forgot_password`)}
          >
            Forgot password?
          </Button>
        </div>
      </Form>
    </LoginLayout>
  );
};

export default LoginFormView;
