export enum COUNTRIES {
  id = 'Indonesia',
  sg = 'Singapore',
  my = 'Malaysia',
  'new-delhi' = 'India',
}

export enum ContractStatus {
  NotSent = 'Not sent',
  Signed = 'Signed',
  Pending = 'Pending',
  NA = 'N/A',
  Cancelled = 'Cancelled',
}

export enum SortOrderEnum {
  ascend = 'asc',
  descend = 'desc',
}
