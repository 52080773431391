import { useState } from 'react';
import { useParams } from 'react-router';
import LinkedOutletsDrawer from './LinkedOutletsDrawer';
import OutletSummary from './OutletSummary';
import OutletLinker from './OutletLinker/OutletLinker';
import { createUiIdContext } from '../../contexts/ElementIdContext';
import { useFetchDealInfo } from '../../services/OutletSettings';
import useSetBreadCrumbParams from '../../hooks/useSetBreadCrumbParams';
import { useUserContext } from '../../contexts/UserContext';
import LockPage from '../../components/LockPage';

const elementIDs = {
  btnViewLinked: 'btnViewLinked',
  divOutletList: 'divOutletList',
  divOutletForm: 'divOutletForm',
};

export const { ElementIdProvider, useElementIdContext } =
  createUiIdContext(elementIDs);

const OutletSettingsView = () => {
  const { getPageSettings } = useUserContext();
  const pageSettings = getPageSettings('partners');
  const enableViewOutletsList = !!pageSettings?.view_outlets_of_eagle_activity;
  const enableLinkOutlets = !!pageSettings?.link_outlets_to_eagle_activity;
  const enableUnlinkOutlets =
    !!pageSettings?.unlink_outlets_from_eagle_activity;

  const { id: partnerID, deal_id } = useParams();
  const { data: dealInfoData } = useFetchDealInfo(
    {
      dealID: parseInt(String(deal_id)),
      company_id: parseInt(String(partnerID)),
    },
    enableViewOutletsList,
  );
  const isNotEmpty = dealInfoData !== undefined;
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  useSetBreadCrumbParams(
    [
      {
        label: String(dealInfoData?.company.name),
        key: String(partnerID),
      },
    ],
    isNotEmpty,
  );

  const dealID = deal_id ? parseInt(deal_id) : 0;

  return enableViewOutletsList ? (
    <ElementIdProvider>
      <OutletSummary
        dealID={dealID}
        onLinkedOutletsClicked={() => setIsDrawerOpen(true)}
      />
      <LinkedOutletsDrawer
        isDrawerOpen={isDrawerOpen}
        setIsDrawerOpen={setIsDrawerOpen}
        dealID={dealID}
        enableViewOutletsList={enableViewOutletsList}
        enableUnlinkOutlets={enableUnlinkOutlets}
      />
      <OutletLinker
        dealID={dealID}
        enableViewOutletsList={enableViewOutletsList}
        enableLinkOutlets={enableLinkOutlets}
      />
    </ElementIdProvider>
  ) : (
    <LockPage />
  );
};

export default OutletSettingsView;
