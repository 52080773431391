import { Button } from 'fave-ui';
import * as Yup from 'yup';

import Form from '../../components/common/form/Form';
import SubmitButton from '../../components/common/form/SubmitButton';
import { Input } from '../../components/common/form/Input';
import http from '../../services/http';
import useCountDown from '../../hooks/useCountDown';
import { PasswordRecoveryType } from './index';
import style from '../ForgotPasswordFormView/style.module.css';
import { FormikHelpers } from 'formik';

type OTPFormProps = {
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  setErrorText: React.Dispatch<React.SetStateAction<string>>;
  setNewPasswordForm: React.Dispatch<React.SetStateAction<boolean>>;
};

const initialValues: PasswordRecoveryType = {
  email: '',
};

const formSchema = Yup.object().shape({
  email: Yup.string()
    .email('Please enter a valid email address')
    .required('Required'),
});

const OTPForm = ({
  email,
  setEmail,
  setErrorText,
  setNewPasswordForm,
}: OTPFormProps) => {
  const { secondsLeft, isRunning, trigger } = useCountDown(30);

  const handleSubmit = (
    values: PasswordRecoveryType,
    { setSubmitting }: FormikHelpers<PasswordRecoveryType>,
  ) =>
    http
      .post('/v1/password', {
        email: values.email,
      })
      .then(() => {
        trigger();
        setErrorText('');
        setEmail(values.email || '');
      })
      .catch((error: any) => {
        setErrorText(error.response.data.error);
        setEmail('');
      })
      .finally(() => setSubmitting(false));

  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={formSchema}
      validateOnMount
      validateOnChange
      validateOnSchemaChange
    >
      <Input
        name="email"
        label="Email address"
        placeholder="email@myfave.com"
        allowClear
        bordered
      />
      <div className={style.actionButton}>
        <SubmitButton disableIfNotValid isDisabled={isRunning}>
          {(_, isSubmitting) =>
            isSubmitting
              ? 'Sending OTP'
              : `
                  Send OTP
                  ${isRunning ? ` (${secondsLeft}s)` : ''}
                `
          }
        </SubmitButton>
        <Button
          onClick={() => setNewPasswordForm(true)}
          type="primary"
          size="large"
          disabled={!email}
        >
          Reset Password
        </Button>
      </div>
    </Form>
  );
};

export default OTPForm;
