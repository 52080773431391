import { useParams } from 'react-router';
import Form from '../../../../components/common/form/Form';
import { Select } from '../../../../components/common/form/Select';
import { Input } from '../../../../components/common/form/Input';
import { PageHeaderWithBackButton } from '../../../../components/common/PageHeaderWithBackButton';
import {
  CampaignDataItem,
  useFetchCampaignListingData,
  useFetchCampaignOptions,
  useUpdateCampaignMutation,
} from '../../../../services/FaveArcade/useFetchCampaignListing';
import * as Yup from 'yup';
import { FormikHelpers } from 'formik';
import { useNavigate } from 'react-router';
import { arrayToOptions } from '../../../../utils/utilFunctions';
import SubmitButton from '../../../../components/common/form/SubmitButton';
import { Button, PopConfirm, Notification, Col, Row } from 'fave-ui';

const successNotification = () =>
  Notification.success({
    message: 'Ticket campaign successfully updated!',
  });

type CampaignFormFields = CampaignDataItem;

const CampaignFormSchema = Yup.object().shape({
  campaign_name: Yup.string()
    .min(1)
    .required('Please give your campaign a name'),
  campaign_type: Yup.string().required(
    'Please select at least one ticket campaign',
  ),
  product_types: Yup.array().of(
    Yup.string().required('Please select at least one product'),
  ),
  payment_method_types: Yup.array().of(
    Yup.string().required('Please select at least one payment method'),
  ),
  condition_type: Yup.string().required('Please select one condition'),
  amount_cents: Yup.number()
    .min(1)
    .required('Please enter the amount of discount'),
  no_of_ticket: Yup.number()
    .min(1)
    .required('Please enter enter the number of tickets'),
});

export default function TicketsEditView() {
  const { id } = useParams();
  const { data: campaignData, refetch } = useFetchCampaignListingData({
    id: id,
  });
  const initialValues = {
    ...campaignData,
    amount_cents:
      campaignData?.amount_cents && campaignData?.amount_cents / 100,
  };
  const { mutateAsync: editCampaign } = useUpdateCampaignMutation();
  const { data: optionsData } = useFetchCampaignOptions();
  const navigate = useNavigate();
  const campaignTypes =
    optionsData && arrayToOptions(optionsData?.campaign_types, '_');

  const handleSubmit = async (
    values: CampaignFormFields,
    { setSubmitting }: FormikHelpers<CampaignFormFields>,
  ) => {
    const payload = {
      ...values,
      id: id as string,
    };
    await editCampaign(
      { ...payload },
      {
        onSuccess: () => {
          successNotification();
          refetch();
          setSubmitting(false);
          navigate('/fave_arcade/ticket_center');
        },
      },
    );
  };

  return (
    <>
      <PageHeaderWithBackButton title={`Edit Campaign`} />
      <Form
        initialValues={initialValues}
        validationSchema={CampaignFormSchema}
        onSubmit={handleSubmit}
        validateOnMount
        validateOnChange
        validateOnSchemaChange
      >
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Input
              name="campaign_name"
              label="Campaign Name"
              placeholder=""
              allowClear
              bordered
              style={{ marginBottom: 0 }}
            />
          </Col>
          <Col span={24}>
            <Select
              name="campaign_type"
              label="Ticket Campaign"
              placeholder=""
              options={campaignTypes}
            />
          </Col>
          <Col span={24}>
            <Select
              name="product_types"
              label="Product"
              placeholder=""
              mode="tags"
              disabled
            />
          </Col>
          <Col span={24}>
            <Select
              name="payment_method_types"
              label="Payment Method"
              placeholder=""
              mode="tags"
              disabled
            />
          </Col>
          <Col span={5}>
            <Select
              name="condition_type"
              label="Condition"
              placeholder=""
              mode="tags"
              disabled
            />
          </Col>
          <Col span={19}>
            <Input
              type="number"
              name="amount_cents"
              label={`Amount In ${campaignData?.currency}`}
              allowClear
              bordered
              disabled
            />
          </Col>
          <Col span={24}>
            <Input
              type="number"
              name="no_of_ticket"
              label="Issue Tickets"
              placeholder="Enter number of tickets"
              allowClear
              bordered
              style={{ marginBottom: 0 }}
            />
          </Col>
          <Col span={24}>
            <SubmitButton disableIfNotValid disableIfNotDirty>
              {(_, isSubmitting) => (isSubmitting ? 'Submiting...' : 'Save')}
            </SubmitButton>
            <PopConfirm
              title="Are you sure you want to cancel? All progress will be lost for the current campaign."
              onConfirm={() => navigate('/fave_arcade/ticket_center')}
              okText="Yes, I am sure"
              cancelText="Go Back"
            >
              <Button type="primary" size="large">
                Cancel
              </Button>
            </PopConfirm>
          </Col>
        </Row>
      </Form>
    </>
  );
}
