import { FormItem } from 'fave-ui';
import { useFormikContext } from 'formik';
import React from 'react';
import { Checkbox } from '../../../components/common/form';
import RequiredFieldLabel from '../../../components/common/RequiredFieldLabel';
import {
  CreateUpdateIPPFormType,
  FetchIPPResponse,
} from '../../../services/IPP/useIPP';
import { IPPBankListFormProps } from '../helpers/ippHelpers';
import style from './style.module.css';

const IPPBankListForm: React.FC<IPPBankListFormProps> = ({
  ippIssuerKey,
  ippTenureKey,
  showRequiredLabel = true,
  height,
  disableInputs = false,
}) => {
  const { values, errors } = useFormikContext<
    CreateUpdateIPPFormType & FetchIPPResponse
  >();

  const formKey = ippIssuerKey;
  const ippIssuerValues = values[ippIssuerKey];
  const ippTenureValues = values[ippTenureKey];

  const error =
    errors && errors[formKey] && typeof errors[formKey] === 'string'
      ? errors[formKey]
      : '';

  return (
    <div
      className={style.container}
      style={{ height, overflowY: height ? 'auto' : 'unset' }}
    >
      {showRequiredLabel && (
        <RequiredFieldLabel text={'Banks & Supported Tenure'} />
      )}
      {ippIssuerValues?.map((ippIssuer, index) => {
        const objectPath = `${ippIssuerKey}.${index}`;
        const checked = ippIssuer.status as boolean;

        return (
          <div className={style.bankRow} key={index}>
            <Checkbox
              name={'status'}
              checked={checked}
              valuePath={objectPath}
              disabled={disableInputs}
            />
            <div className={style.bankAndTenures}>
              <div className={style.bankName}>
                <span className={style.fontBold}>{ippIssuer.name}</span>
              </div>
              <div className={style.tenures}>
                {ippTenureValues.map((ippTenure, innerIndex) => (
                  <span
                    className={
                      !ippIssuer.tenures?.includes(ippTenure.tenure)
                        ? style.textNeutral300
                        : ''
                    }
                    key={innerIndex}
                  >
                    {ippTenure.tenure} mth
                  </span>
                ))}
              </div>
            </div>
          </div>
        );
      })}
      <FormItem
        className={style.formItem}
        validateStatus={error ? 'error' : 'success'}
        help={error}
      />
    </div>
  );
};

export default IPPBankListForm;
