import { useParams } from 'react-router';
import { useFetchDealsDetails } from '../../../../services/MySubmissions';
import {
  customBooleanValue,
  dateOrDash,
  valueOrDash,
} from '../../../../utils/utilFunctions';
import { PageHeaderWithBackButton } from '../../../../components/common';
import CardSection from '../../../../components/CardSection';
import { Spin } from 'antd';
import useSetBreadCrumbParams from '../../../../hooks/useSetBreadCrumbParams';
import style from '../style.module.css';
import { Paperclip } from 'phosphor-react';

const DealsSubmissionsDetailsView = () => {
  const { id } = useParams();
  const dealID = String(id);

  const { data: dealsDetailsData, isFetching } = useFetchDealsDetails({
    id: dealID,
  });

  const isNotEmpty = dealsDetailsData !== undefined;

  useSetBreadCrumbParams(
    [
      { label: '', key: 'deals' },
      {
        label: `${String(dealsDetailsData?.company?.name)} (Deals Detail)`,
        key: String(id),
      },
      { label: '', key: 'details' },
    ],
    isNotEmpty,
  );

  const labelStyle = { fontSize: '16px', fontWeight: 'bold', color: '#000000' };

  const dealDetailDescriptions = [
    {
      key: '1',
      label: 'DEAL DETAIL',
      labelStyle: labelStyle,
      children: <></>,
    },
    {
      key: '2',
      label: 'Campaign',
      children: (
        <div className={style.newLine}>
          {dealsDetailsData?.product_campaigns?.length !== 0
            ? dealsDetailsData?.product_campaigns?.map(item => (
                <div key={item.id}>{valueOrDash(item.name)}</div>
              ))
            : '-'}
        </div>
      ),
    },
    {
      key: '3',
      label: 'Business Category',
      children: valueOrDash(dealsDetailsData?.category),
    },
    {
      key: '4',
      label: 'Reporting Category',
      children: valueOrDash(dealsDetailsData?.reporting_category?.name),
    },
    {
      key: '5',
      label: 'Deal Name',
      children: valueOrDash(dealsDetailsData?.name),
    },
    {
      key: '6',
      label: 'Deal Description',
      children: valueOrDash(dealsDetailsData?.deal_description),
    },
    {
      key: '7',
      label: 'Sales Cap',
      children: valueOrDash(dealsDetailsData?.cap_amount),
    },
    {
      key: '8',
      label: 'PRICING',
      labelStyle: labelStyle,
      children: <></>,
    },
    {
      key: '9',
      label: 'Original Price',
      children: valueOrDash(dealsDetailsData?.original_price),
    },
    {
      key: '10',
      label: 'Discounted Price',
      children: valueOrDash(dealsDetailsData?.fave_selling_price),
    },
    {
      key: '11',
      label: 'Merchant Takeback (Support Fee)',
      children: valueOrDash(dealsDetailsData?.support_fee),
    },
    {
      key: '12',
      label: 'Fave Commission %',
      children: `${valueOrDash(dealsDetailsData?.fave_commision)}%`,
    },
  ];

  const additionalDetailDescriptions = [
    {
      key: '1',
      label: 'Start Date',
      children: dateOrDash(dealsDetailsData?.start_date),
    },
    {
      key: '2',
      label: 'End Date',
      children: dateOrDash(dealsDetailsData?.end_date),
    },
    {
      key: '3',
      label: 'Redemption Start Date',
      children: dateOrDash(dealsDetailsData?.redemption_start_date),
    },
    {
      key: '4',
      label: 'Redemption End Date',
      children: dateOrDash(dealsDetailsData?.redemption_end_date),
    },
    {
      key: '5',
      label: 'Deal Validity (days)',
      children: valueOrDash(dealsDetailsData?.validity_days),
    },
    {
      key: '6',
      label: 'Is this Deal using External Code',
      children: customBooleanValue(dealsDetailsData?.external_codes),
    },
    {
      key: '7',
      label: 'Redemption Method',
      children: valueOrDash(dealsDetailsData?.redemption_method),
    },
    {
      key: '8',
      label: 'Reservation Type',
      children: valueOrDash(dealsDetailsData?.reservation_type),
    },
    {
      key: '9',
      label: 'Availability (Optional)',
      children: valueOrDash(dealsDetailsData?.gender),
    },
    {
      key: '10',
      label: 'Listing Type',
      children: valueOrDash(dealsDetailsData?.listing_type),
    },
    {
      key: '11',
      label: 'Deal Auto Extension',
      children: customBooleanValue(dealsDetailsData?.auto_extend),
    },
  ];

  const termsConditionsPhotoshootDescriptions = [
    {
      key: '1',
      label: 'TERMS & CONDITION',
      labelStyle: labelStyle,
      children: <></>,
    },
    {
      key: '2',
      label: 'Package Detail',
      children: valueOrDash(dealsDetailsData?.description),
    },
    {
      key: '3',
      label: 'Fine Print',
      children: (
        <div
          dangerouslySetInnerHTML={{
            __html: valueOrDash(dealsDetailsData?.fine_print),
          }}
        />
      ),
    },
    {
      key: '4',
      label: 'Redemption Instructions',
      children: (
        <div
          dangerouslySetInnerHTML={{
            __html: valueOrDash(dealsDetailsData?.redemption_instruction),
          }}
        />
      ),
    },
    {
      key: '5',
      label: 'PHOTO & ATTACHMENT',
      labelStyle: labelStyle,
      children: <></>,
    },
    {
      key: '6',
      label: 'Photoshoot Required?',
      children: customBooleanValue(dealsDetailsData?.photoshoot_required),
    },
    {
      key: '7',
      label: 'New Uploads',
      children: (
        <div className={style.newLine}>
          {dealsDetailsData?.images?.length !== 0
            ? dealsDetailsData?.images?.map(item => (
                <div
                  key={item.file_filename}
                  onClick={() => window.open(item.url, '_blank')}
                >
                  {
                    <Paperclip
                      size={16}
                      color="#404040"
                      style={{ display: 'inline' }}
                    />
                  }{' '}
                  {item.file_filename}
                </div>
              ))
            : '-'}
        </div>
      ),
    },
    {
      key: '8',
      label: 'OTHERS',
      labelStyle: labelStyle,
      children: <></>,
    },
    {
      key: '9',
      label: 'Other Remarks',
      children: valueOrDash(dealsDetailsData?.notes),
    },
  ];

  const dealDetailsSections = [
    {
      key: '1',
      title: 'Deal Details & Pricing',
      data: dealDetailDescriptions,
    },
    {
      key: '2',
      title: 'Additional Detail',
      data: additionalDetailDescriptions,
    },
    {
      key: '3',
      title: 'Terms & Condition and Photoshoot',
      data: termsConditionsPhotoshootDescriptions,
    },
  ];

  return (
    <div>
      <PageHeaderWithBackButton
        title={valueOrDash(dealsDetailsData?.company?.name)}
        subTitle={'Deals Detail'}
      />
      <Spin spinning={isFetching} style={{ width: '100%' }}>
        {dealDetailsSections.map(({ key, ...restItems }) => (
          <CardSection key={key} sectionNumber={key} {...restItems} />
        ))}
      </Spin>
    </div>
  );
};

export default DealsSubmissionsDetailsView;
