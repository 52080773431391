import {
  Desktop,
  Gear,
  Handshake,
  Checks,
  Confetti,
  GameController,
  CurrencyCircleDollar,
} from 'phosphor-react';
import { SideNavRoutesType } from '../types/configTypes';

export const merchantConnectRoutes = [
  {
    label: 'My Merchants',
    key: '/my_merchant/merchants',
    breadcrumb: 'My Merchant',
  },
  {
    label: 'My Submissions',
    key: '/my_submissions',
    breadcrumb: 'My Submissions',
  },
  {
    label: 'My Drafts',
    key: '/admin/merchant_connect/my_drafts/merchants',
    breadcrumb: 'My Drafts',
    redirect: 'true',
  },
];

const refundReasonsRoutes = [
  {
    label: 'Refund Reasons',
    key: '/admin/refund_reasons',
    screenkey: 'refund_reasons',
    breadcrumb: 'Refund Reasons',
    redirect: 'true',
  },
  {
    label: 'Adjustment Reasons',
    key: '/admin/adjustment_reasons',
    screenkey: 'adjustment_reasons',
    breadcrumb: 'Adjustment Reasons',
    redirect: 'true',
  },
  {
    label: 'Credit Adjustment Reasons',
    key: '/admin/credit_adjustment_reasons',
    screenkey: 'credit_adjustment_reasons',
    breadcrumb: 'Credit Adjustment Reasons',
    redirect: 'true',
  },
  {
    label: 'Add Credit Reasons',
    key: '/admin/add_credit_reasons',
    screenkey: 'add_credit_reasons',
    breadcrumb: 'Add Credit Reasons',
    redirect: 'true',
  },
  {
    label: 'Ban User Reasons',
    key: '/admin/ban_user_reasons',
    screenkey: 'ban_user_reasons',
    breadcrumb: 'Ban User Reasons',
    redirect: 'true',
  },
];

const feesRoutes = [
  {
    label: 'Guarantee Fee',
    key: '/admin/guarantee_fee/edit',
    screenkey: 'guarantee_fee',
    breadcrumb: 'Guarantee Fee',
    redirect: 'true',
  },
];

export const settingsRoutes = [
  {
    label: 'Shopping Malls',
    key: '/admin/shopping_malls',
    screenkey: 'shopping_malls',
    breadcrumb: 'Shopping Malls',
    redirect: 'true',
  },
  {
    label: 'Bank Info',
    key: '/admin/bank_infos',
    screenkey: 'bank_info',
    breadcrumb: 'Bank Info',
    redirect: 'true',
  },
  {
    label: 'Tags',
    key: '/admin/tags',
    screenkey: 'tags',
    breadcrumb: 'Tags',
    redirect: 'true',
  },
  {
    label: 'Cities',
    key: '/admin/cities',
    screenkey: 'cities',
    breadcrumb: 'Cities',
    redirect: 'true',
  },
  {
    label: 'Categories',
    key: '/admin/parent_categories',
    screenkey: 'categories',
    breadcrumb: 'Categories',
    redirect: 'true',
  },
  {
    label: 'Towns',
    key: '/admin/towns',
    screenkey: 'towns',
    breadcrumb: 'Towns',
    redirect: 'true',
  },
  {
    label: 'Neighbourhoods',
    key: '/admin/neighbourhoods',
    screenkey: 'neighbourhoods',
    breadcrumb: 'Neighbourhoods',
    redirect: 'true',
  },
  {
    label: 'Card BIN Lists',
    key: '/admin/card_bin_lists',
    screenkey: 'card_bin_lists',
    breadcrumb: 'Card BIN Lists',
    redirect: 'true',
  },
  {
    label: 'Filters',
    key: '/admin/filters',
    screenkey: 'filters',
    breadcrumb: 'Filters',
    redirect: 'true',
  },
  {
    label: 'Filter Header',
    key: '/admin/filter_headers',
    screenkey: 'filter_header',
    breadcrumb: 'Filter Header',
    redirect: 'true',
  },
  {
    label: 'Reporting Categories',
    key: '/admin/reporting_categories',
    screenkey: 'reporting_categories',
    breadcrumb: 'Reporting Categories',
    redirect: 'true',
  },
  {
    label: 'Refund Reasons',
    key: '/refund',
    children: refundReasonsRoutes,
    breadcrumb: 'Refund Reasons',
  },
  {
    label: 'Refund Policies',
    key: '/admin/refund_policies',
    screenkey: 'refund_policies',
    breadcrumb: 'Refund Policies',
    redirect: 'true',
  },
  {
    label: 'Generate WhatsApp Link',
    key: '/admin/whatsapp',
    screenkey: 'generate_whatsapp_link',
    breadcrumb: 'Generate WhatsApp Link',
    redirect: 'true',
  },
  {
    label: 'Fees',
    key: '/fees',
    children: feesRoutes,
    breadcrumb: 'Fees',
  },
  {
    label: 'E Card Presets',
    key: '/admin/e_card_presets',
    screenkey: 'ecard_presets',
    breadcrumb: 'E Card Presets',
    redirect: 'true',
  },
  {
    label: 'QwikCilver Config',
    key: '/admin/qwik_e_cards',
    screenkey: 'qwikcilver_config',
    breadcrumb: 'QwikCilver Config',
    redirect: 'true',
  },
  {
    label: 'FavePay Later',
    key: '/admin/favepay_later/settings',
    screenkey: 'favepay_later',
    breadcrumb: 'FavePay Later',
    redirect: 'true',
  },
  {
    label: 'Sales Agents',
    key: '/admin/sales_agents',
    screenkey: 'sales_agent',
    breadcrumb: 'Sales Agents',
    redirect: 'true',
  },
  {
    label: 'Nature of Business',
    key: '/nature_of_business',
    screenkey: 'nature_of_business',
    breadcrumb: 'Nature of Business',
  },
];

const partnerPosSFTPRoutes = [
  {
    label: 'Download',
    key: '/admin/partner_pos_mapping',
    screenkey: 'partner_pos_settings_sftp_download',
    breadcrumb: 'Download',
    redirect: 'true',
  },
  {
    label: 'Upload',
    key: '/admin/partner_pos_mapping/outbox',
    screenkey: 'partner_pos_settings_sftp_upload',
    breadcrumb: 'Upload',
    redirect: 'true',
  },
];

export const operationDashboardRoutes = [
  {
    label: 'Master Dashboard',
    key: '/master_dashboard',
    screenkey: 'master_dashboard',
    breadcrumb: 'Master Dashboard',
  },
  {
    label: 'CP Approval',
    key: '/admin/cp_approval',
    screenkey: 'cp_approval',
    breadcrumb: 'CP Approval',
    redirect: 'true',
  },
  {
    label: 'QA Approval',
    key: '/admin/qa_approval',
    screenkey: 'qa_approval',
    breadcrumb: 'QA Approval',
    redirect: 'true',
  },
  {
    label: 'Deal Live',
    key: '/admin/deal_live_dashboard',
    screenkey: 'deal_live',
    breadcrumb: 'Deal Live',
    redirect: 'true',
  },
  {
    label: 'Deal Preview Overview',
    key: '/admin/deal_preview_overview',
    screenkey: 'deal_preview_overview',
    breadcrumb: 'Deal Preview Overview',
    redirect: 'true',
  },
  {
    label: 'FavePay Live',
    key: '/admin/favepay_live_dashboard',
    screenkey: 'favepay_live',
    breadcrumb: 'FavePay Live',
    redirect: 'true',
  },
  {
    label: 'Partner Taggings',
    key: '/admin/partner_taggings',
    screenkey: 'partner_taggings',
    breadcrumb: 'Partner Taggings',
    redirect: 'true',
  },
  {
    type: 'group',
    label: 'Partner POS Settings',
    key: '/partner_settings',
    children: [
      {
        label: 'Partner POS SFTP',
        key: '/partner_SFTP',
        children: partnerPosSFTPRoutes,
        breadcrumb: 'Partner POS SFTP',
      },
    ],
    breadcrumb: 'Partner POS Settings',
  },
  {
    label: 'Outlet Keys',
    key: '/admin/omni_outlets',
    screenkey: 'partner_pos_settings_outlet_keys',
    breadcrumb: 'Outlet Keys',
    redirect: 'true',
  },
];

export const campaignRoutes = [
  {
    label: 'Campaign Management Tool',
    key: '/campaign_management_tool',
    screenkey: 'campaign_management_tool',
    breadcrumb: 'Campaign Management Tool',
  },
  {
    label: 'Banners',
    key: '/admin/home/banners',
    breadcrumb: 'Banners',
    redirect: 'true',
  },
  {
    label: 'Peeky Banners',
    key: '/peeky_banners',
    screenkey: 'peeky_banners',
    breadcrumb: 'Peeky Banners',
  },
  {
    label: 'Cashback Campaigns',
    key: '/cashback_campaigns',
    screenkey: 'cashback_campaigns',
    breadcrumb: 'Cashback Campaigns',
  },
];

export const arcadeRoutes = [
  {
    label: 'Ticket Center',
    key: '/ticket_center',
    breadcrumb: 'Ticket Center',
  },
  {
    label: 'Games Center',
    key: '/games_center',
    breadcrumb: 'Games Center',
  },
  {
    label: 'Prize Catalog',
    key: '/prize_catalog',
    breadcrumb: 'Prize Catalog',
  },
];

export const sideNavRoutes = (): SideNavRoutesType[] => [
  // this is needed to ensure all pages with /admin/partners in their URL are
  // accessible by authorised users
  {
    key: '/admin/partners',
    screenkey: 'partners',
    label: '',
    style: { display: 'none' },
  },
  {
    label: 'Maker Checker Dashboard',
    icon: Checks,
    key: '/admin/maker_checker_dashboard',
    breadcrumb: 'Maker Checker Dashboard',
    screenkey: 'maker',
  },
  {
    label: 'Merchant Settlement Dashboard',
    icon: CurrencyCircleDollar,
    key: '/admin/merchant_settlement_dashboard',
    breadcrumb: 'Merchant Settlement Dashboard',
    screenkey: 'merchant_settlement_dashboard',
  },
  {
    key: '/admin/merchant_connect',
    screenkey: 'merchant_connect',
    icon: Handshake,
    label: 'Merchant Connect',
    children: merchantConnectRoutes,
    breadcrumb: 'Merchant Connect',
  },
  {
    key: '/operation_dashboard',
    icon: Desktop,
    label: 'Operation Dashboard',
    children: operationDashboardRoutes,
    breadcrumb: 'Operation Dashboard',
  },
  {
    key: '/settings',
    icon: Gear,
    label: 'Settings',
    children: settingsRoutes,
    breadcrumb: 'Settings',
  },
  {
    key: '/admin/merchandise_and_campaign',
    screenkey: 'merchandise_campaigns',
    icon: Confetti,
    label: 'Merchandise/Campaigns',
    children: campaignRoutes,
    breadcrumb: 'Merchandise & Campaigns',
  },
  {
    key: '/fave_arcade',
    icon: GameController,
    screenkey: 'arcade_ticket_campaigns',
    label: 'Fave Arcade',
    children: arcadeRoutes,
    breadcrumb: 'Fave Arcade',
  },
];
