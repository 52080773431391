import { useMutation, useQuery } from 'react-query';
import {
  DatasetTypes,
  PaginatedTableConfigType,
  TableConfigType,
} from '../../types/configTypes';
import {
  EnabledStatusType,
  MySubmissionStatusType,
} from '../../types/dataTypes';
import http, { createFetchAPI } from '../http';

type MerchantType = 'Fave' | 'Fastpay';
type OnboardingType =
  | 'Bd Onboarded Partner'
  | 'Face to Face Onboarding Required'
  | 'Prep Call Required';

type OutletType = {
  id: number;
  name: string;
};

type GenericCompanyType = {
  id: number;
  name: string;
};

type FavePayListCompanyType = GenericCompanyType & {
  merchant_type: MerchantType;
};

type FavePayDetailsCompanyType = GenericCompanyType & {
  description: string;
};

type AlipayCategoryType = {
  id: number;
  name: string;
};

type CompanyType = {
  id: number;
  name: string;
  description: string;
};

type ProductCampaignType = {
  id: number;
  name: string;
};

type ReportingCategoryType = {
  id: number;
  name: string;
};

type ImagesType = {
  file_filename: string;
  url: string;
};

type FavePayDetailsPayload = { id: string };

type FavePayDetailsResponse = {
  id: number;
  company: FavePayDetailsCompanyType;
  merchant_type: MerchantType;
  cashback_rate: number;
  fee_percentage: number;
  onboarding_type: OnboardingType;
  scheduled_favepay_enable_date: Date;
  fave_fee_effective_date: Date;
  has_fave_payment: boolean;
  undiscounted_description: string;
  special_terms: string;
  bd_submit_time: Date;
  alipay_category: AlipayCategoryType;
  alipay_opt_in: boolean;
  alipay_discount_percentage: number;
  alipay_fee_percentage: number;
  alipay_fee_effective_date: Date;
  business_hours: string;
  fixed_fee_enabled: boolean;
  fixed_fee_percentage: number;
  fixed_fee_effective_date: Date;
  outlet: OutletType;
  remarks: string;
};

type DealsDetailsPayload = { id: string };

type DealsDetailsResponseType = {
  id: number;
  name: string;
  category: string;
  deal_description: string;
  cap_amount: number;
  original_price: string;
  fave_selling_price: string;
  support_fee: string;
  fave_commision: number;
  start_date: Date;
  end_date: Date;
  redemption_start_date: Date;
  redemption_end_date: Date;
  validity_days: string;
  external_codes: boolean;
  reservation_type: string;
  gender: string;
  listing_type: string;
  auto_extend: boolean;
  photoshoot_required: boolean;
  description: string;
  fine_print: string;
  redemption_method: string;
  redemption_instruction: string;
  notes: string;
  company: CompanyType;
  product_campaigns: ProductCampaignType[];
  reporting_category: ReportingCategoryType;
  images: ImagesType[];
};

export type ProductType =
  | 'FavePay'
  | 'Deal'
  | 'eCard'
  | 'IPP'
  | 'Contract Migration';

type ECardDetailsPayload = { id: string; dataset: DatasetTypes };

type ECardDetailsResponseType = {
  id: number;
  start_date: Date;
  end_date: Date;
  validity: number;
  auto_extend: boolean;
  bke_locked: boolean;
  bke_locked_period: string;
  user_purchase_limit: number;
  max_cap: number;
  payable_amount: string;
  bonus_credit_amount: string;
  commission_rate_percent: number;
  payout_by: string;
  e_card_action: ECardActionType;
  account: AccountType;
  company: CompanyType;
  fine_print: string;
};

const favepayDetailsEndpoint = (id: string) => `v1/bd_payment_details/${id}`;
const detailsEndpoint = (id: string) => `v1/bd_deals/${id}`;
const eCardsDetailsEndpoint = (id: string) => `v1/ecards/${id}`;

const fetchFavePayDetails = createFetchAPI<
  FavePayDetailsPayload,
  FavePayDetailsResponse
>();

const fetchDealsDetailsList = createFetchAPI<
  DealsDetailsPayload,
  DealsDetailsResponseType
>();

const fetchECardDetailsList = createFetchAPI<
  ECardDetailsPayload,
  ECardDetailsResponseType
>();

// will be used for both my submissions and my drafts
export const useFetchFavePayDetails = ({ id }: FavePayDetailsPayload) =>
  useQuery<FavePayDetailsResponse>(
    ['fetchFavePaySubmissions', { url: favepayDetailsEndpoint(id) }],
    fetchFavePayDetails,
  );

export type FetchDealsListResponse = {
  bd_deals: BDDealsType[];
  count: number;
};

export type BDDealsType = {
  id: number;
  bd_submit_time: Date;
  bd_email: string;
  name: string;
  status: MySubmissionStatusType;
  cp_action_time: Date;
  qa_action_time: Date;
  ps_action_time: Date;
  editorial_action_time: Date;
  designer_action_time: Date;
  original_price: string;
  fave_selling_price: string;
  fave_commision: number;
  addendum_link: string;
  contract_url: string;
  can_manage_submission: boolean;
  has_action: boolean;
  company: GenericCompanyType;
};

const fetchDealsList = createFetchAPI<
  { props: PaginatedTableConfigType },
  FetchDealsListResponse
>('v1/bd_deals/my_submissions');

export const useFetchDealsList = (props: PaginatedTableConfigType) =>
  useQuery<FetchDealsListResponse>(
    ['fetchDealsList', { ...props }],
    fetchDealsList,
  );

type FetchFavePayListResponse = {
  bd_payment_details: BDPaymentDetailsType[];
  count: number;
};

export type BDPaymentDetailsType = {
  id: number;
  bd_email: string;
  status: MySubmissionStatusType;
  quality_assurance_action_time: Date;
  partner_support_action_time: Date;
  compliance_action_time: Date;
  content_action_time: Date;
  designer_action_time: Date;
  addendum_link: string;
  contract_url: string;
  can_manage_submission: boolean;
  has_action: boolean;
  company: FavePayListCompanyType;
  outlet: OutletType;
  cashback_rate: number;
  fee_percentage: number;
  bd_submit_time: string;
};

const fetchFavePayList = createFetchAPI<
  TableConfigType,
  FetchFavePayListResponse
>('/v1/bd_payment_details/my_submissions');

export const useFetchFavePayList = (props: PaginatedTableConfigType) =>
  useQuery<FetchFavePayListResponse>(
    ['fetchFavePayList', { ...props }],
    fetchFavePayList,
  );

export type PostDuplicateDealPayload = number[];

const mutateDuplicateDeal = (ids: PostDuplicateDealPayload) =>
  http.post<PostDuplicateDealPayload>('v1/bd_deals/duplicate', {
    bd_deal_ids: ids,
  });

export const useMutateDuplicateDeal = () => useMutation(mutateDuplicateDeal);
export const useFetchDealsDetails = ({ id }: DealsDetailsPayload) =>
  useQuery<DealsDetailsResponseType>(
    ['fetchDealsDetails', { url: detailsEndpoint(id) }],
    fetchDealsDetailsList,
  );

type ECardType = {
  id: number;
  payable_amount: string;
  bonus_credit_amount: string;
};

type SubmissionType = 'Internal' | 'Favebiz';

export type ECardsActionsType = {
  id: number;
  submission_time: Date;
  bd_email: string;
  submission_type: SubmissionType;
  status: MySubmissionStatusType;
  compliance_action_time: Date;
  editorial_action_time: Date;
  designer_action_time: Date;
  addendum_link: string;
  contract_url: string;
  can_manage_submission: boolean;
  company: GenericCompanyType;
  e_card: ECardType;
};

export type ECardActionType = {
  id: number;
  support_fee: string;
  fave_fee_tax: string;
  remarks: string;
};

export type AccountType = {
  id: number;
  bank_account_name: string;
  bank_account_number: string;
  bank_info: BankInfoType;
};

export type BankInfoType = {
  id: number;
  name: string;
};

type ECardsListingResponse = {
  e_card_actions: ECardsActionsType[];
  count: number;
};

const fetchEcardsList = createFetchAPI<
  PaginatedTableConfigType,
  ECardsListingResponse
>('/v1/e_card_actions/my_submissions');

export const useFetchEcardsList = (props: PaginatedTableConfigType) =>
  useQuery<ECardsListingResponse>(
    ['fetchEcardsList', { ...props }],
    fetchEcardsList,
  );

export type PostECardDuplicatePayload = number[];

const mutateDuplicateECard = (ids: PostECardDuplicatePayload) =>
  http.post<PostECardDuplicatePayload>('v1/ecards/duplicate', {
    e_card_ids: ids,
  });

export const useMutateDuplicateECard = () => useMutation(mutateDuplicateECard);

type MCCompanyIPPSettingType = {
  id: number;
  onboarding_status: MySubmissionStatusType;
  quality_assurance_action_time: Date;
  partner_support_action_time: Date;
  designer_action_time: Date;
};

export type MCContractSubmissionsType = {
  id: number;
  submission_time: Date;
  bd_email: string;
  addendum_link: string;
  contract_url: string;
  can_manage_submission: true;
  company: GenericCompanyType;
  mc_company_ipp_setting: MCCompanyIPPSettingType;
};

type IPPListingResponse = {
  mc_contract_submissions: MCContractSubmissionsType[];
  count: number;
};

const fetchIPPList = createFetchAPI<
  PaginatedTableConfigType,
  IPPListingResponse
>('v1/mc_contract_submissions/my_submissions');

export const useFetchIPPList = (props: PaginatedTableConfigType) =>
  useQuery<IPPListingResponse>(['fetchIPPList', { ...props }], fetchIPPList);

export const useFetchECardsDetails = ({ id, dataset }: ECardDetailsPayload) =>
  useQuery<ECardDetailsResponseType>(
    ['fetchECardsDetails', { url: eCardsDetailsEndpoint(id), dataset }],
    fetchECardDetailsList,
  );

type IPPDetailsPayload = { id: string; dataset: DatasetTypes };

type IPPIssuerType = {
  id: number;
  name: string;
  tenures: number[];
  status: EnabledStatusType;
};

type MCCompanyIPPIssuersType = {
  id: number;
  status: EnabledStatusType;
  ipp_issuer: IPPIssuerType;
};

export type MCCCompanyIPPTenures = {
  id: number;
  status: EnabledStatusType;
  rate: number;
  tenure: number;
};

type FetchIPPDetailsResponse = {
  id: number;
  mc_company_ipp_issuers: MCCompanyIPPIssuersType[];
  mc_company_ipp_tenures: MCCCompanyIPPTenures[];
  company: GenericCompanyType;
};

const fetchIPPDetails = createFetchAPI<
  IPPDetailsPayload,
  FetchIPPDetailsResponse
>();

export const useFetchIPPDetails = ({ id, dataset }: IPPDetailsPayload) =>
  useQuery<FetchIPPDetailsResponse>(
    ['fetchIPPDetails', { url: `v1/mc_company_ipp_settings/${id}`, dataset }],
    fetchIPPDetails,
  );

type CompanyMigrationOutletsType = {
  id: number;
  name: string;
};

type ContractMigrationCompanyType = GenericCompanyType & {
  outlets: CompanyMigrationOutletsType;
  outlets_count: number;
};

type ContractMigrationSubmissionsStatusType = Extract<
  MySubmissionStatusType,
  'Pending signature' | 'Cancelled' | 'Signed'
>;

export type ContractMigrationMCContractSubmissionsType = {
  id: number;
  submission_time: Date;
  contract_url: string;
  can_manage_submission: boolean;
  status: ContractMigrationSubmissionsStatusType;
  company: ContractMigrationCompanyType[];
};

type ContractMigrationListingResponse = {
  mc_contract_submissions: ContractMigrationMCContractSubmissionsType[];
  count: number;
};

const fetchContractMigrationList = createFetchAPI<
  PaginatedTableConfigType,
  ContractMigrationListingResponse
>('/v1/mc_contract_submissions/my_submissions');

export const useFetchContractMigrationList = (
  props: PaginatedTableConfigType,
) =>
  useQuery<ContractMigrationListingResponse>(
    ['fetchContractMigrationList', { ...props }],
    fetchContractMigrationList,
  );

type MutateCancelContractMigrationPayload = number;

const mutateCancelContractMigration = (
  id: MutateCancelContractMigrationPayload,
) =>
  http.post<MutateCancelContractMigrationPayload>(
    `v1/mc_contract_submissions/${id}/cancel_contract_migration`,
  );

export const useMutateCancelContractMigration = () =>
  useMutation(mutateCancelContractMigration);
