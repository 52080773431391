import style from './style.module.css';
import { PageHeaderWithBackButton } from '../../../../components/common/PageHeaderWithBackButton';
import { useParams } from 'react-router';
import { Card, Col, Statistic } from 'antd';
import { Row } from 'fave-ui';
import ChangeHistoryTable from '../../../../components/ChangeHistoryTable';
import { useFetchGameListingData } from '../../../../services/FaveArcade/useFetchGamesListing';
import { capitalizeFirstLetterEachWord } from '../../../../utils/utilFunctions';

const GameChangelogView = () => {
  const { id } = useParams();
  const parsedID = id ? parseInt(id) : undefined;

  const { data: campaignData, isLoading: isCampaignLoading } =
    useFetchGameListingData({ id: id });

  const summaryData = campaignData && {
    ...campaignData,
    game_type: capitalizeFirstLetterEachWord(campaignData.game_type),
  };

  return (
    <>
      <PageHeaderWithBackButton title={`Fave Arcade Game Details`} />
      <Card className={style.card}>
        <Row>
          <h2 className={style.h2}>{'Details Summary'}</h2>
        </Row>
        <Row className={style.summaryWrapper}>
          <Col>
            <Row className={style.statsRow}>
              <Statistic
                loading={isCampaignLoading}
                title="ID"
                value={summaryData?.id}
                className={style.statItem}
              />
            </Row>
          </Col>
          <Col className={style.statsCol}>
            <Row className={style.statsRow}>
              <Statistic
                loading={isCampaignLoading}
                title="Game Type"
                value={summaryData?.game_type}
                className={style.statItem}
              />
            </Row>
          </Col>
          <Col className={style.statsCol}>
            <Row className={style.statsRow}>
              <Statistic
                loading={isCampaignLoading}
                title="Ticket to Play"
                value={summaryData?.ticket_to_play}
                className={style.statItem}
              />
            </Row>
          </Col>
          <Col className={style.statsCol}>
            <Row className={style.statsRow}>
              <Statistic
                loading={isCampaignLoading}
                title="Daily Play Limit"
                value={summaryData?.daily_play_limit}
                className={style.statItem}
              />
            </Row>
          </Col>
        </Row>
      </Card>
      <Row className={style.table}>
        {parsedID && (
          <ChangeHistoryTable
            item_type={'Arcade::GameCampaign'}
            item_id={parsedID}
          />
        )}
      </Row>
    </>
  );
};

export default GameChangelogView;
