import { PropsWithChildren } from 'react';
import style from './style.module.css';
import clsx from 'clsx';

// to be used with table that has height: 100%
// refer MerchantSettlementaView/style.module.css
const FullCell = ({
  children,
  className,
}: PropsWithChildren<{ className?: string }>) => (
  <div className={clsx(style.fullCell, className)}>{children}</div>
);

export default FullCell;
