import React, { useCallback, useState } from 'react';
import {
  ColumnsType,
  FilterValue,
  SorterResult,
  TablePaginationConfig,
} from 'antd/lib/table/interface';
import { DotsThree } from 'phosphor-react';
import {
  Button,
  Dropdown,
  Empty,
  FilterIcon,
  Input,
  Menu,
  Table,
} from 'fave-ui';
import style from './../style.module.css';
import RangePicker from '../../../components/common/form/RangePicker';
import { SearchBarExpandable } from '../../../components/common';
import { calcTableWidth, formatDate } from '../../../utils/utilFunctions';
import {
  PaginationRequest,
  Request,
  RequestQueryParams,
  useExportRequest,
  useFetchCheckers,
  useFetchMembers,
  useFetchModels,
} from '../../../services/MakerChecker/useMakerChecker';
import { MKTab, TagColor } from '../helper/enum';
import { MakerCheckerStatusTag } from '../../../components/common/StatusTag';
import { calcTableHeight } from '../../../utils/utilFunctions';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { isCheckerTab } from '../helper';
import { useUserContext } from '../../../contexts/UserContext';
import { Checkbox } from 'antd';
import useDebouncedCallback from '../../../hooks/useDebouncedCallback';

type MakerCheckerTableView = {
  onDelete: (id: string) => void;
  onViewRequest: (request: Request) => void;
  requestPageProps?: PaginationRequest;
  filterObject: RequestQueryParams;
  setFilterObject: React.Dispatch<React.SetStateAction<RequestQueryParams>>;
  isLoading: boolean;
  activeTab: MKTab.Checker | MKTab.Maker;
};

const tableHeight = calcTableHeight();
const tableWidth = calcTableWidth();

const MakerCheckerTableView: React.FC<MakerCheckerTableView> = ({
  onDelete,
  onViewRequest,
  requestPageProps,
  filterObject,
  setFilterObject,
  isLoading,
  activeTab,
}) => {
  const { getPageSettings } = useUserContext();

  const isAdmin = !!getPageSettings('checkmates_admin');

  const [, setKeyword] = useState('');
  const [checkersNameSearch, setCheckersNameSearch] = useState<string>('');
  const [makersNameSearch, setMakersNameSearch] = useState<string>('');

  const { data: checkers } = useFetchCheckers({ name: checkersNameSearch });

  const { data: makers } = useFetchMembers({ name: makersNameSearch });

  const { data: models } = useFetchModels();

  const handleSearchChecker = useDebouncedCallback(
    ([e]) => setCheckersNameSearch(e.target.value),
    400,
  );

  const handleSearchMakers = useDebouncedCallback(
    ([e]) => setMakersNameSearch(e.target.value),
    400,
  );

  const handleExportExcelRequest = useCallback(() => {
    useExportRequest().then(response => {
      const type = response.headers['content-type'];
      const blob = new Blob([response.data], { type });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'checker_maker.xlsx';
      link.click();
    });
  }, []);

  const dataColumns: ColumnsType<Request> = [
    {
      title: 'Request ID',
      dataIndex: 'id',
      width: 120,
      render: (request_id: string) => <>R-{request_id}</>,
    },
    {
      title: 'Requested Date',
      dataIndex: ['creator', 'created_at'],
      width: 150,
      render: (created_at: string) => (
        <>{formatDate(created_at, 'dd-MM-yyyy')}</>
      ),
    },
    {
      title: 'Reviewed Date',
      dataIndex: ['reviewer', 'reviewed_at'],
      width: 150,
      render: (reviewed_at: string) => (
        <>{reviewed_at && formatDate(reviewed_at, 'dd-MM-yyyy')}</>
      ),
    },
    {
      title: 'Merchant',
      dataIndex: 'merchant_name',
      width: 150,
      render: (merchant_name: string) => <>{merchant_name}</>,
    },
    {
      title: 'Merchant ID',
      dataIndex: 'merchant_id',
      width: 150,
      render: (merchant_id: string) => <>{merchant_id}</>,
    },
    {
      title: 'Request Title',
      filterIcon: FilterIcon,
      filters: models?.models.map(model => ({
        value: model,
        text: model,
      })),
      dataIndex: 'model',
      width: 160,
      ellipsis: true,
      render: (model: string) => <>{model}</>,
    },
    {
      title: 'Page Link',
      dataIndex: 'url',
      width: isCheckerTab(activeTab) ? 100 : 150,
      render: (page_link: string) => (
        <a
          className="underline"
          href={page_link}
          target="_blank"
          rel="noreferrer"
        >
          {isCheckerTab(activeTab) ? 'View Page' : 'Go to original page'}
        </a>
      ),
    },
    {
      title: 'Maker',
      filterIcon: FilterIcon,
      filterDropdown: ({ confirm, setSelectedKeys, selectedKeys }) => (
        <div className={style.searchFilterDropdown}>
          <Input
            placeholder={'Search makers'}
            autoFocus={true}
            onChange={handleSearchMakers}
          />
          <div className={style.divider} />
          <Checkbox.Group
            value={selectedKeys}
            options={makers?.users.map(maker => ({
              label: maker.name,
              value: `${maker.id}-${maker.name}`,
            }))}
            onChange={(value: unknown[]) =>
              setSelectedKeys(value as React.Key[])
            }
          />
          <div className={style.buttonContainer}>
            <Button
              onClick={() => {
                setMakersNameSearch('');
                setSelectedKeys([]);
              }}
              type={'text'}
            >
              Reset
            </Button>
            <Button
              onClick={() => confirm({ closeDropdown: true })}
              type={'primary'}
            >
              OK
            </Button>
          </div>
        </div>
      ),
      dataIndex: ['creator', 'name'],
      filterSearch: true,
      width: 170,
      ellipsis: true,
      render: (maker: string) => <>{maker}</>,
    },
    {
      title: 'Checker',
      filterIcon: FilterIcon,
      filterDropdown: ({ confirm, setSelectedKeys, selectedKeys }) => (
        <div className={style.searchFilterDropdown}>
          <Input
            placeholder={'Search checkers'}
            autoFocus={true}
            onChange={handleSearchChecker}
          />
          <div className={style.divider} />
          <Checkbox.Group
            value={selectedKeys}
            options={checkers?.users.map(checker => ({
              label: checker.name,
              value: `${checker.id}-${checker.name}`,
            }))}
            onChange={(value: unknown[]) =>
              setSelectedKeys(value as React.Key[])
            }
          />
          <div className={style.buttonContainer}>
            <Button
              onClick={() => {
                setCheckersNameSearch('');
                setSelectedKeys([]);
              }}
              type={'text'}
            >
              Reset
            </Button>
            <Button
              onClick={() => confirm({ closeDropdown: true })}
              type={'primary'}
            >
              OK
            </Button>
          </div>
        </div>
      ),
      dataIndex: ['reviewer', 'name'],
      width: 170,
      ellipsis: true,
      render: (checker: string) => <>{checker}</>,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      filterIcon: FilterIcon,
      filters: [
        { value: `cancelled`, text: TagColor.cancelled },
        { value: `pending_review`, text: TagColor.pending_review },
        { value: `rejected`, text: TagColor.rejected },
        { value: `approved`, text: TagColor.approved },
        { value: `completed`, text: TagColor.completed },
      ],
      width: 120,
      render: (status: string) => (
        <MakerCheckerStatusTag
          status={TagColor[status as keyof typeof TagColor]}
        />
      ),
    },
    {
      title: 'Action',
      dataIndex: 'id',
      width: 80,
      fixed: 'right',
      render: (value, record) => {
        const actionItems: ItemType[] = [
          {
            key: 'view_detail',
            label: 'View Detail',
            onClick: () => onViewRequest(record),
          },
          {
            key: 'delete_request',
            label: 'Delete Request',
            onClick: () => onDelete(value),
          },
        ];
        return isCheckerTab(activeTab) ? (
          <div
            className="text-favepink font-semibold cursor-pointer"
            onClick={() => onViewRequest(record)}
          >
            View
          </div>
        ) : (
          <Dropdown overlay={<Menu items={actionItems} />} trigger={['hover']}>
            <DotsThree className="text-favepink cursor-pointer" size={32} />
          </Dropdown>
        );
      },
    },
  ];

  const handleSearch = (value: string) => {
    if (value) setKeyword(value);
  };

  const handleChangeRequestedDate = useCallback((date: Date[] | null) => {
    setFilterObject(filter => ({
      ...filter,
      created_start_datetime: date ? formatDate(date[0], 'yyyy-MM-dd') : '',
      created_end_datetime: date ? formatDate(date[1], 'yyyy-MM-dd') : '',
    }));
  }, []);

  const handleChangeApprovedDate = useCallback((date: Date[] | null) => {
    setFilterObject(filter => ({
      ...filter,
      approval_start_datetime: date ? formatDate(date[0], 'yyyy-MM-dd') : '',
      approval_end_datetime: date ? formatDate(date[1], 'yyyy-MM-dd') : '',
    }));
  }, []);

  const handleChangeTable = useCallback(
    (
      pagination: TablePaginationConfig,
      filter: Record<string, FilterValue | null>,
      sorter: SorterResult<Request> | SorterResult<Request>[],
    ) => {
      sorter;
      const requester_name = (filter['creator.name'] as string[] | null)?.map(
        value => value.split('-')[1],
      );
      const reviewer_name = (filter['reviewer.name'] as string[] | null)?.map(
        value => value.split('-')[1],
      );
      setFilterObject(oldFilter => ({
        ...oldFilter,
        page: pagination.current || 1,
        status: filter.status?.toString() || '',
        requester_name: requester_name?.toString() || '',
        reviewer_name: reviewer_name?.toString() || '',
        models: filter['model']?.toString() || '',
      }));
    },
    [],
  );

  return (
    <>
      <div className={style.headerTable}>
        <div className="flex items-end">
          <RangePicker
            className={style.dateRequested}
            name={'date_requested'}
            label={'Date Requested'}
            handleOnChange={handleChangeRequestedDate}
          />
          <RangePicker
            name={'date_approved'}
            label={'Reviewed date'}
            handleOnChange={handleChangeApprovedDate}
          />
          {isAdmin && (
            <div className="border-favepink border-[1px] rounded-lg ml-3 h-fit">
              <Button type="text" onClick={handleExportExcelRequest}>
                Download CSV
              </Button>
            </div>
          )}
        </div>
        <SearchBarExpandable onSearch={handleSearch} />
      </div>
      <Table
        size="large"
        rowKey={row => row.id}
        loading={isLoading}
        dataSource={requestPageProps?.requests || []}
        columns={dataColumns}
        className={style.table}
        scroll={{
          x: tableWidth,
        }}
        pagination={{
          total: requestPageProps?.total_count || 0,
          position: ['bottomCenter'],
          pageSize: filterObject.limit,
          current: filterObject.page,
          showSizeChanger: false,
          showQuickJumper: true,
        }}
        onChange={handleChangeTable}
        locale={{
          emptyText: !isLoading && (
            <Empty
              emptyType="cant-find-anything"
              style={{ height: tableHeight }}
            />
          ),
        }}
      />
    </>
  );
};

export default MakerCheckerTableView;
