import React, { PropsWithChildren, useContext } from 'react';
import { COUNTRIES } from '../helpers/TypeConstant';
import { UserType } from '../types/dataTypes';
import { TenantContext, TenantCodeType } from './TenantContext';

type CleverTapProps = {
  // fill as you migrate
};

export type CleverTapScreenDisplayedEvent = {
  screen_name: string;
  section_name?: string;
  props?: CleverTapProps;
};

export type CleverTapTappedOnEvent = CleverTapScreenDisplayedEvent & {
  tapped_on?: string;
};

export type CleverTapEventArgs = CleverTapTappedOnEvent & {
  name?: string;
};

type CleverTapAccount = {
  id: string | undefined;
};

type CleverTapProfile = {
  Site: {
    Identity: string;
    language?: string;
    country: string;
    tenant: string;
    role: string;
    // keep role_1, may be used in the future
    role_1?: string;
    partner_name?: string;
    partner_ids_1?: number[];
    enabled_favepay_1?: boolean;
    enabled_deals_1?: boolean;
    enabled_food_order?: string;
  };
};

type CleverTapNotification = {
  titleText: string;
  bodyText: string;
  okButtonText: string;
  rejectButtonText: string;
  okButtonColor: string;
  askAgainTimeInSeconds?: number /* defaults to 1 week if omitted */;
  serviceWorkerPath: string;
};

type CleverTapContextType = {
  cleverTapProfile: {
    push: (user: UserType, tenant?: TenantCodeType) => void;
    logout: () => void;
  };
  cleverTapEvent: {
    push: ({
      name,
      screen_name,
      tapped_on,
      section_name,
      props,
    }: CleverTapEventArgs) => void;
  };
};

const initialContext: CleverTapContextType = {
  cleverTapProfile: {
    push: () => {},
    logout: () => {},
  },
  cleverTapEvent: {
    push: () => {},
  },
};

/* clevertap needs to be in global window scope for script to work */
declare global {
  interface Window {
    clevertap: {
      event: (string | CleverTapEventArgs)[];
      profile: CleverTapProfile[];
      account: CleverTapAccount[];
      onUserLogin: [];
      notifications: CleverTapNotification[];
      privacy: [];
      logout: () => void;
    };
    cleverTapScriptTagAppended: boolean;
  }
}

const CleverTapContext =
  React.createContext<CleverTapContextType>(initialContext);

/* clevertap needs to be in global window scope for script to work */
window.clevertap = {
  event: [],
  profile: [],
  account: [],
  onUserLogin: [],
  notifications: [],
  privacy: [],
  logout: () => {},
};

// CleverTapContext.displayName = "CleverTapContext";

/* Migrated to typescript from CleverTapContext.js */
const CleverTapContextProvider = ({ children }: PropsWithChildren<{}>) => {
  const { clevertap } = window;
  const { selectedTenant } = useContext(TenantContext);

  clevertap.account.push({ id: process.env.REACT_APP_CLEVERTAP_ACCOUNT_ID });

  // using global window.clevertap with initial props above,
  // self-executing function below looks for and decorates window.clevertap
  // with props and functions for calling their API
  // https://developer.clevertap.com/docs/web-quickstart-guide
  (function () {
    if (window.cleverTapScriptTagAppended) return;
    const wzrk = document.createElement('script');
    wzrk.type = 'text/javascript';
    wzrk.async = true;
    wzrk.src =
      (document.location.protocol === 'https:'
        ? 'https://d2r1yp2w7bby2u.cloudfront.net'
        : 'http://static.clevertap.com') + '/js/a.js';

    const s = document.getElementsByTagName('script')[0];

    if (s.parentNode) {
      s.parentNode.insertBefore(wzrk, s);
      window.cleverTapScriptTagAppended = true;
    }
  })();

  return (
    <CleverTapContext.Provider
      value={{
        cleverTapProfile: {
          push: (user: UserType, tenant?: TenantCodeType) => {
            const hasUser = Object.keys(user || {}).length !== 0;
            if (!hasUser) return;

            const countryName = tenant
              ? COUNTRIES[tenant]
              : COUNTRIES[selectedTenant as keyof typeof COUNTRIES];

            const tenantCode = tenant || selectedTenant;
            const userRole = user.role;

            const profile = {
              Site: {
                Identity: `${user.email}`,
                country: countryName,
                tenant: tenantCode,
                role: userRole,
              },
            };

            clevertap.profile.push(profile);

            clevertap.notifications.push({
              titleText: 'Would you like to receive Push Notifications?',
              bodyText:
                'We promise to only send you relevant content and give you updates on your transactions',
              okButtonText: 'Sign me up!',
              rejectButtonText: 'No thanks',
              okButtonColor: '#de206a',
              // askAgainTimeInSeconds: 5 /* defaults to 1 week if omitted */,
              // need to ask if this is needed
              serviceWorkerPath: 'service-worker.js', // path to CRA's build service worker
            });
          },
          logout: () => clevertap.logout && clevertap.logout(),
        },
        cleverTapEvent: {
          push: ({
            name,
            screen_name,
            tapped_on,
            section_name,
            props,
          }: CleverTapEventArgs) => {
            if (!name) return;

            const event = {
              ...props,
              screen_name,
              tapped_on,
              section_name,
            };

            clevertap.event.push(name, event);
          },
        },
      }}
    >
      {children}
    </CleverTapContext.Provider>
  );
};

export { CleverTapContext, CleverTapContextProvider };
