import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import style from './style.module.css';
import NoAccessImg from '../../assests/images/no_access.png';

const TIMEOUT_IN_MS = 8000;

const LockPage: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => navigate('/'), TIMEOUT_IN_MS);
    return () => clearTimeout(timer);
  });

  return (
    <div className={style.container}>
      <div className={style.cardContainer}>
        <div>
          <img src={NoAccessImg} alt="user access error" />
        </div>
        <h2 className={style.errorHeader}>
          Oops, you do not have access to this page.
        </h2>
      </div>
    </div>
  );
};

export default LockPage;
