import { useState, useMemo } from 'react';
import { FileImage } from 'phosphor-react';
import type { UploadProps } from 'antd/es/upload';
import { useFormikContext } from 'formik';
import { FormItem, Upload, RcFile, Modal, UploadFile, Message } from 'fave-ui';
import { arrayToString } from '../../../utils/utilFunctions';
import { showRemoveFileModal } from '../../../helpers/formHelpers';

type ImageUploadProps<T> = UploadProps<T> & {
  name: keyof T;
  label?: string;
  imgSize?: number;
  types?: string[];
  imgNumber?: number;
  uploadURL?: string;
  defaultDisplayImgList?: UploadFile[];
};

const ImageUpload = <T extends {}>({
  name,
  label,
  types,
  imgSize,
  imgNumber,
  uploadURL,
  defaultDisplayImgList,
  ...restProps
}: ImageUploadProps<T>) => {
  const [isError, setIsError] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const { errors, setFieldValue } = useFormikContext<T>();

  const error = errors[name];

  const beforeUpload = (file: RcFile) => {
    const isTrue = types?.includes(file.type);
    if (!isTrue) {
      Message.error({
        content: `You can only upload ${arrayToString(
          types as string[],
          ' ',
        )} file!`,
      });
      setIsError(true);
      return;
    }

    const isUnlimited = imgSize ? file.size / 1024 / 1024 < imgSize : true;
    if (!isUnlimited) {
      Message.error({ content: `Image must be smaller than ${imgSize} MB!` });
      setIsError(true);
      return;
    }
    setIsError(false);

    return isTrue && isUnlimited;
  };

  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = (file: UploadFile) => {
    setPreviewImage(file.thumbUrl || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1),
    );
  };

  const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    if (isError) return;
    setFileList(newFileList);
    setFieldValue(name, newFileList);
  };

  const MemoisedImageUpload = useMemo(
    () => (
      <Upload
        action={uploadURL}
        name={name}
        listType="picture-card"
        fileList={fileList.length > 0 ? fileList : defaultDisplayImgList}
        onPreview={handlePreview}
        beforeUpload={beforeUpload}
        onChange={handleChange}
        onRemove={file => showRemoveFileModal({ file })}
        maxCount={imgNumber || 1}
        {...restProps}
      >
        <FileImage size={32} />
        <span>Upload</span>
      </Upload>
    ),
    [name, types, imgSize, imgNumber, fileList, handleChange],
  );

  return (
    <FormItem
      label={label}
      validateStatus={error !== undefined ? 'error' : 'success'}
      help={error}
    >
      {MemoisedImageUpload}
      <Modal footer={null} visible={previewOpen} onCancel={handleCancel}>
        <img alt={previewTitle} style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </FormItem>
  );
};

export default ImageUpload;
